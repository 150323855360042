<template>

  <div>
    <b-card sub-title="Wersja obowiązująca od 25.05.2023r.">
      <b-card-text>
        <h3><strong>Regulamin</strong></h3>
        <h5>&sect; 1 Definicje</h5>
        <ol>
          <li>Ilekroć w niniejszym regulaminie używa się poniższych zwrot&oacute;w, oznaczają one:</li>
        </ol>
        <p>a) Prowadząca – Sonia Radosz, prowadząca działalność gospodarczą pod firmą ChemMaster Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail: kontakt@chemmaster.pl, nr telefonu przedsiębiorstwa: 727447983</p>
        <p>b) Użytkownik – osoba korzystająca z serwisu będąca osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych</p>
        <p>c) serwis – witryna www.chemmaster.pl</p>
        <p>d) e-book – przygotowane w formie pliku komputerowego opracowania obejmujące teksty oraz grafiki</p>
        <p>e) kurs online – prowadzony przez Prowadzącą za pośrednictwem środków komunikacji elektronicznej umożliwiających transmisję obrazu i głosu cykl prezentacji, podczas których przekazuje ona wiedzę i umiejętności w zakresie oferowanego produktu</p>
        <p>f) treści cyfrowe – oferowane przez Prowadzącą do sprzedaży za pośrednictwem serwisu usługi i utwory, nie będące utrwalone na nośniku materialnym, do których użytkownik ma dostęp za pomocą konta użytkownika.</p>
        <p>g) zakup online – procedura zamawiania treści cyfrowych oferowanych przez Prowadzącą za pośrednictwem serwisu</p>
        <p>h) konto, konto użytkownika – indywidualne konto użytkownika umożliwiające dostęp do zakupionych materiałów.</p>
        <p>i) newsletter – wiadomości e-mail stanowiące cykliczne informacje o działalności Prowadzącej jak również mogące zawierać informację handlową, adresowane do użytkownika po uprzednim wyrażeniu przez niego odpowiedniej zgody, stanowiące usługę świadczoną drogą elektroniczną w myśl art. 2 ust. 4 ustawy o świadczeniu usług droga elektroniczną</p>


        <h5>&sect; 2 Postanowienia og&oacute;lne</h5>
        <ol>
          <li>Postanowienia niniejszego regulaminu normują zasady oraz warunki korzystania z serwisu oraz funkcjonalności na nim oferowanych, w tym zakupu online.</li>
          <li>Korzystając z serwisu, a w szczeg&oacute;lności zakładając konto, użytkownik zgadza się przestrzegać niniejszego regulaminu i jego postanowień.</li>
          <li>Użytkownik zobowiązuje się do korzystania z konta i zakupionych treści online wyłącznie osobiście, zabronione jest wsp&oacute;łdzielenie konta i zakupionych treści cyfrowych z osobą trzecią.</li>
          <li>Użytkownik jest jednakże uprawniony do przekazania dostępu do konta oraz zakupionej online treści cyfrowej jednemu zstępnemu. Użytkownik zobowiązuje się, pod rygorem odpowiedzialności za działania zstępnego jak za swoje, do zapoznania zstępnego z niniejszym regulaminem oraz nadzorem na zstępnym w zakresie zgodności korzystania z zakupionych online treści cyfrowych z postanowieniami niniejszego regulaminu.</li>
        </ol>
        <h5>&sect; 3 Ochrona własności intelektualnej</h5>
        <ol>
          <li>Wszystkie treści cyfrowe oferowane przez Prowadzącą stanowią jej własność i są przedmiotem ochrony prawa polskiego i międzynarodowego, w szczeg&oacute;lności przepis&oacute;w ustawy z 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</li>
          <li>Z momentem zakupu online treści cyfrowej, na użytkownika nie przechodzą żadne prawa autorskie, tak osobiste jak majątkowe.</li>
          <li>Bez zgody Prowadzącej zabronione jest rozpowszechniane treści cyfrowych uzyskanych za pomocą zakupu online.</li>
          <li>Użytkownik nie jest uprawniony w szczeg&oacute;lności do
            <ol type="a">
              <li>ingerencji w treść treści cyfrowej</li>
              <li>usuwania zabezpieczeń treści cyfrowej, jak np. znaki wodne</li>
              <li>zwielokrotniania treści cyfrowej w formie elektronicznej lub tradycyjnej</li>
              <li>udostępniania treści cyfrowej w internecie, w tym na platformach P2P jak sieci torrent lub inne</li>
              <li>wykorzystywania treści cyfrowej do cel&oacute;w komercyjnych, modyfikacji, zar&oacute;wno w całości jak i w części.</li>
            </ol>
          </li>
          <li>Rozpowszechnianie utworu bez uprawnienia stanowi przestępstwo opisane w art. 116 ustawy o prawie autorskim i prawach pokrewnych i każdy wykryty przypadek takiego działania zostanie zgłoszony do właściwego organu. Dotyczy to także innych przestępstw uregulowanych w powołanej ustawie, jak r&oacute;wnież innych przepisach, jeśli bezprawne rozpowszechnianie treści cyfrowych wyczerpywać będzie znamiona tych przestępstw.</li>
          <li>Użytkownik ma prawo korzystać z treści cyfrowej tylko na własny użytek. Postanowienie to działa bez uszczerbku na uprawnienie opisane w &sect; 2 ust. 4 regulaminu.</li>
          <li>Użytkownik ma prawo do wydruku treści cyfrowej nadającej się do tego, jak np. e-book. Wydrukowana treść cyfrowa jednakże pozostaje pod ochroną prawa własności intelektualnej, w szczeg&oacute;lności w zakresie zakazu kopiowania i rozpowszechniania, przekazywania osobom trzecim do korzystania.</li>
          <li>Użytkownik ma prawo korzystać, w zgodzie z postanowieniami powyższymi, z zakupionych online treści cyfrowych za pośrednictwem r&oacute;żnych urządzeń elektronicznych, o ile nie prowadzi to do zmiany w strukturze treści cyfrowej.</li>
          <li>W przypadku gdy oferowane treści cyfrowe stanowią opracowania tekst&oacute;w zewnętrznych, og&oacute;lnodostępnych (jak np. zbiory zadań Centralnych lub Obwodowych Komisji Egzaminacyjnych), przedmiotem praw autorskich Prowadzącej są dokonane przez nią analizy powyższych tekst&oacute;w w każdej postaci, a w szczeg&oacute;lności komentarzy, grafik, opracowań.</li>
        </ol>
        <h5>&sect; 4 Zakładanie konta</h5>
        <ol>
          <li>Użytkownik chcący korzystać z funkcjonalności serwisu powinien zarejestrować się w serwisie poprzez utworzenie konta.</li>
          <li>Użytkownik zakładający konto powinien być osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych.</li>
        </ol>
        <h5>&sect; 5 Procedura zakupu online treści cyfrowej</h5>
        <ol>
          <li>Prowadząca umożliwia w ramach serwisu zakup online treści cyfrowych, w szczeg&oacute;lności w postaci uczestnictwa w kursie online lub dostarczenia e-booka.</li>
          <li>Informacje o właściwościach oferowanej treści cyfrowej dostępne są każdorazowo z poziomu serwisu.</li>
          <li>Zakup online możliwy jest wyłącznie dla użytkownika, kt&oacute;ry zarejestrował w serwisie swoje konto i kt&oacute;ry podczas procedury zakupu pozostaje zalogowany.</li>
          <li>Treści cyfrowe oferowane w serwisie mogą ulegać zmianie, modyfikacji, ich dostępność może być ograniczona czasowo lub ilościowo, wedle uznania Prowadzącej.</li>
          <li>Płatności za treści cyfrowe dokonywane są za pomocą platformy PayU. Regulaminy funkcjonowania tej platformy dostępne są dla użytkownika pod adresem https://poland.payu.com/dokumenty-prawne-do-pobrania/. Dokonując zakupu online użytkownik potwierdza także zapoznanie się z tymi regulaminami.</li>
          <li>Cena za oferowane treści cyfrowe wyrażona jest w złotych (PLN).</li>
          <li>Prowadząca uprawniona jest do oferowania kod&oacute;w rabatowych, kt&oacute;re pomniejszają ostateczną cenę zakupu treści cyfrowej.</li>
          <li>Spos&oacute;b, terminy i warunki korzystania z kod&oacute;w rabatowych określane są każdorazowo przez Prowadzącą w ramach informacji o tych kodach umieszczonych w serwisie. Pozyskany kod rabatowy przypisany jest na stałe do jednego konta i tylko dane konto może go aktywować.</li>
          <li>Po wyborze żądanej treści cyfrowej użytkownik powinien postępować zgodnie ze wskaz&oacute;wkami widocznymi na stronie internetowej, kt&oacute;ra przeprowadzi go przez proces zakupu.</li>
          <li>Ostateczna cena zakupu, po uwzględnieniu ewentualnych kod&oacute;w rabatowych, stanowi cenę wiążącą, kt&oacute;rą po zaakceptowaniu warunk&oacute;w sprzedaży i przystąpienia do płatności za pośrednictwem platformy PayU użytkownik zobowiązuje się zapłacić.</li>
          <li>Posiadany kod rabatowy należy podać w przeznaczonym do tego polu podczas procedury zakupu, w przeciwnym wypadku nie zostanie on naliczony, nawet jeżeli użytkownik jest uprawniony do jego wykorzystania. W szczeg&oacute;lności posiadane, a nie wykorzystane kody rabatowe nie podlegają zamianie na inne kody rabatowe czy wymianie na środki pieniężne.</li>
          <li>Po złożeniu dyspozycji zakupu i ukończeniu procesu płatności za pośrednictwem platformy PayU (tj. potwierdzenia przez PayU udanego przelewu ceny) użytkownik otrzyma na ujawniony przez siebie adres e-mail automatycznie wygenerowaną wiadomość z potwierdzeniem złożenia zam&oacute;wienia. Z chwilą wysłania tej wiadomości dochodzi do zawarcia umowy, na mocy kt&oacute;rej użytkownik uzyskuje prawo do korzystania z treści cyfrowej, na warunkach niniejszego regulaminu.</li>
          <li>Brak ukończenia procesu płatności za pośrednictwem PayU i zapłaty ceny zwalnia Prowadzącą z obowiązku realizacji zam&oacute;wienia, o czym użytkownik nie musi zostać poinformowany odrębną wiadomością.</li>
          <li>W każdym przypadku, gdy Prowadząca nie jest w stanie zrealizować dokonanego zam&oacute;wienia, użytkownik bez zbędnej zwłoki zostanie o tym poinformowany wiadomością e-mail. W takim przypadku najp&oacute;źniej w ciągu 14 dni od daty zakupu Prowadząca zwr&oacute;ci całą kwotę zapłaconą przez użytkownika, na rachunek z kt&oacute;rego dokonano transakcji pierwotnej.</li>
          <li>Przyczyny, dla kt&oacute;rych Prowadząca nie jest w stanie zrealizować zam&oacute;wienia nie podlegają ujawnieniu użytkownikowi.</li>
          <li>Prowadząca umożliwia wystawienie faktury do każdego zam&oacute;wienia. W celu uzyskania faktury użytkownik powinien w toku dokonywania zam&oacute;wienia zaznaczyć odpowiednią rubrykę i wprowadzić dane umożliwiające wystawienie faktury. Taka faktura w postaci elektronicznej jest następnie &ndash; po pomyślnym dokonaniu zapłaty &ndash; wysyłana e-mailem na wskazany adres użytkownika.</li>
          <li>Prowadząca jest podmiotem korzystającym ze zwolnienia od VAT na podstawie art. 43 ust. 1 ustawy o podatku od towar&oacute;w i usług, wobec czego ceny płacone za treści cyfrowe przez użytkownika ujawnione zostaną na fakturze z VAT zwolnionym.</li>
        </ol>
        <h5>&sect; 6 Spos&oacute;b dostępu do zakupionej treści cyfrowej i warunki techniczne</h5>
        <ol>
          <li>Po dokonaniu zakupu online treści cyfrowej, użytkownik poprzez dostęp do swojego konta może przenieść się do strony internetowej oznaczonej zakładką &bdquo;Moje kursy&rdquo;.</li>
          <li>W sekcji &bdquo;Moje kursy&rdquo; użytkownikowi udostępnione zostaną zakupione treści cyfrowe. Spos&oacute;b korzystania z treści cyfrowej uzależniony jest od charakteru zakupionej treści cyfrowej i nie odbiega od standardowo przyjętego w sieci internet sposobu korzystania z plik&oacute;w i program&oacute;w komputerowych.</li>
          <li>Dostęp do treści cyfrowych oferowanych w postaci plik&oacute;w tekstowych (jak np. e-booki) wymaga zainstalowanego oprogramowania pozwalającego na odczyt plik&oacute;w w postaci *.pdf, takich jak Adobe Acrobat Reader.</li>
          <li>Dostęp do lekcji online na żywo (live) wymaga skorzystania z aplikacji umożliwiających transmisję głosu i obrazu.</li>
          <li>Treści cyfrowe zabezpieczone mogą być znakiem wodnym lub innym powszechnie stosowanym sposobem zabezpieczania treści w sieci internet.</li>
          <li>Szczeg&oacute;łowe warunki korzystania z danej treści cyfrowej, jak np. harmonogram webinari&oacute;w (transmisji) w przypadku kurs&oacute;w online na żywo (live), godziny transmisji, określane są na podstronie ofertowej każdej treści cyfrowej. Informacje dotyczące takich zajęć pojawią się na koncie użytkownika do 24 godzin od rozpoczęcia webinaru.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik w zakładce &bdquo;Moje kursy&rdquo; uzyska link zezwalający na dołączenie do transmisji zgodnie z zaplanowanym harmonogramem.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik po upływie terminu zaplanowanej transmisji uzyska dostęp do nagrania tego materiału w formie offline, z kt&oacute;rego może korzystać jak z każdej innej treści cyfrowej. Okres dostępu do takiej treści cyfrowej może być ograniczony czasowo, o czym użytkownik zostanie poinformowany w zakładce &bdquo;Moje kursy&rdquo; lub &bdquo;Powiadomienia&rdquo;.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca nie ponosi odpowiedzialności za przerwy w transmisji czy spadki w jej jakości spowodowane przepustowością sieci po stronie użytkownika.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, gdy Prowadząca z jakichkolwiek powod&oacute;w, w tym technicznych, zdrowotnych czy prywatnych, nie ma możliwości przeprowadzenia zaplanowanej transmisji, transmisja ta zostanie przeprowadzona w innym dniu, o kt&oacute;rym użytkownik zostanie poinformowany. Decyzja o braku możliwości przeprowadzenia zaplanowanej transmisji jest uznaniowa i użytkownik nie jest uprawniony do jej kwestionowania, ani kreowania z tego tytułu roszczeń wobec Prowadzącej, co pozostaje bez uszczerbku dla poniższych postanowień. Wedle swojego uznania Prowadząca może także w miejsce organizowania zastępczego terminu zaległej transmisji, o kt&oacute;rej mowa powyżej, przygotować materiał filmowy offline i udostępnić go w postaci nagrania w zakładce &bdquo;Moje kursy&rdquo; konta użytkownika. Przeprowadzenie transmisji zastępczej lub udostępnienie materiału offline każdorazowo nastąpi najp&oacute;źniej do 31 kwietnia danego roku, lub roku następnego jeśli zdarzenie ma miejsce po 31 kwietnia danego roku &ndash; za każdym razem tak, aby użytkownik posiadał komplet materiał&oacute;w przygotowujących do nauki jeszcze przed terminem matury.</li>
          <li>Z powodu nieprzeprowadzenia transmisji zgodnie z harmonogramem użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej, chyba że nie wywiąże się ona z przygotowania transmisji zastępczej lub nie udostępni materiału offline, w spos&oacute;b i w terminie o kt&oacute;rym mowa powyżej.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca jest nadto uprawniona do zmiany godziny transmisji w obrębie tego samego dnia. Z tego tytułu użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej.</li>
          <li>W przypadku gdyby kt&oacute;rakolwiek treść cyfrowa zawierała błąd obliczeniowy, tekstowy, skutkujący wadliwością merytoryczną treści cyfrowej, Prowadząca zobowiązuje się bez zbędnej zwłoki do zaktualizowania treści cyfrowej, poinformowania użytkownika o dostrzeżonym błędzie i udostępnienia treści cyfrowej o prawidłowej treści.</li>
          <li>Treści cyfrowe zakupione od 31 sierpnia 2021 r. w postaci materiał&oacute;w dostępnych w zakładce &bdquo;Moje kursy&rdquo; wygasają po upływie roku od daty zakupu*. Oznacza to, że z upływem ww. terminu dostęp do tych treści wygasa. *Z zastrzeżeniem sytuacji, w kt&oacute;rych opisie oferowanego kursu wskazana jest inna data dostępu do zakupionego kursu.&nbsp;</li>
        </ol>
        <h5>§ 6a Newsletter i komunikacja sms</h5>
        <ol>
          <li>Użytkownik ma możliwość wyrażenia zgody na otrzymywanie dodatkowych informacji o kursach i działalności Prowadzącej w drodze zapisania się do newslettera i komunikacji sms. Prawidłowość świadczenia usługi możliwa jest wyłącznie w przypadku podania rzeczywistych danych kontaktowych.</li>
          <li>Użytkownik wyrażając zgodę przy zapisie potwierdza chęć otrzymywania informacji handlowej od Prowadzącej. Informacja handlowa zawiera informacje w przedmiocie bieżącej działalności Prowadzącej, oferowanych usług, produktów, promocji, przypomnień o nadchodzących wydarzeniach czy treści cyfrowych.</li>
          <li>Prowadząca uprawniona jest do udostępnienia użytkownikom zapisanym do Newslettera i komunikacji sms dodatkowych treści cyfrowych, dostępnych na koncie użytkownika. Dostęp do tych treści będzie darmowy dla każdego użytkownika zapisanego do usługi Newslettera i komunikacji sms.</li>
          <li>Wypisanie się z Newslettera i komunikacji sms możliwe jest w każdej chwili za pośrednictwem właściwej opcji w panelu w koncie użytkownika oraz po kliknięciu w link znajdujący się w stopce każdego przesyłanego Newslettera.</li>
          <li>Przetwarzanie danych użytkownika do celów Newslettera i komunikacji sms odbywa się zgodnie z informacjami opisanymi w Polityce Prywatności</li>
        </ol>
        <h5>&sect; 7 Dostarczanie treści cyfrowych przed upływem terminu do odstąpienia od umowy</h5>
        <ol>
          <li>Dokonując zakupu online, użytkownik wyraża zgodę na spełnienie świadczenia przed upływem terminu do odstąpienia od umowy.</li>
          <li>Z chwilą udzielenia zgody, o której mowa w ust. 1 powyżej, użytkownik traci prawo odstąpienia od umowy. Prowadząca bez zbędnej zwłoki prześle na adres e-mail ucznia podany do rejestracji w serwisie potwierdzenie zakupu online oraz utratę prawa odstąpienia od umowy.</li>
          <li>Akceptując regulamin, użytkownik potwierdza, że został poinformowany, że zgodnie z art. 38 pkt 13 ustawy o prawach konsumenta, w przypadku umowy o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, prawo do odstąpienia nie przysługuje jeśli spełnianie świadczenia nastąpiło na wyraźną zgodę konsumenta przed upływem terminu do odstąpienia umowy i po poinformowaniu o utracie prawa do odstąpienia od umowy.</li>
          <li>W ślad za powyższym, użytkownik, który dokonał zakupu online i zaakceptował rozpoczęcie świadczenia przed upływem terminu do odstąpienia od umowy, nie jest uprawniony do odstąpienia od zakupu e-booka lub kursu online. Po wykonaniu świadczenia usługi i uruchomieniu dostępu do kursu nie ma możliwości zamiany zakupionego kursu na inny spośród oferty.</li>
          <li>Powyższe zapisy regulaminu pozostają bez uszczerbku dla szczegółowych przepisów dotyczących zawierania umów o dostarczania treści cyfrowej określonych w szczególności w rozdziale 5b ustawy o prawach konsumenta.</li>
        </ol>
        <h5>&sect; 8 Warunki korzystania z serwisu ChemMaster</h5>
        <ol>
          <li>Użytkownik uprawniony jest do korzystania z treści cyfrowych maksymalnie na trzech urządzeniach elektronicznych.</li>
          <li>Prowadząca może wprowadzić narzędzia techniczne, które wymagać będą rejestracji urządzenia i jego danych identyfikacyjnych tak, aby zapewnić możliwość korzystania z treści serwisu na maksymalnie trzech urządzeniach.</li>
          <li>Prowadząca może wyrazić zgodę, na wyraźną prośbę Użytkownika, na korzystanie z serwisu na większej liczbie urządzeń. Decyzja ta ma charakter dyskrecjonalny. W każdym wypadku przekroczenie liczby dopuszczalnych urządzeń stanowi poważne naruszenie regulaminu.</li>
          <li>W przypadku wykrycia naruszeń regulaminu korzystania z serwisu przez Użytkownika, Prowadząca uprawniona jest do tymczasowej, 7-dniowej blokady konta użytkownika wraz ze skierowaniem wiadomości do Użytkownika na jego konto użytkownika lub adres e-mail podany przy rejestracji z wezwaniem do wyjaśnień wykrytych nieprawidłowości. Zależnie od swojego uznania, Prowadząca może albo wpierw skierować wiadomość z wezwaniem do wyjaśnienia, bądź jednocześnie konto tymczasowo zablokować, zwłaszcza w przypadku oczywistości dokonanego naruszenia.</li>
          <li>Tymczasowe, 7-dniowe zablokowanie konta użytkownika nie tworzy po stronie użytkownika roszczeń związanych z niemożliwością korzystania z usługi.</li>
          <li>W przypadku braku ustosunkowania się do wezwania do udzielenia wyjaśnień, blokada konta użytkownika może być przez Prowadzącą przedłużana aż do momentu ich udzielenia.</li>
          <li>W przypadku braku wyjaśnień przez okres 21 dni od daty złożenia wezwania Prowadząca uprawniona jest do wypowiedzenia umowy o świadczenie usług. Wypowiedzenie to skierowane zostaje drogą elektroniczną, na adres e-mail podany przez Użytkownika do kontaktu w toku rejestracji konta użytkownika.</li>
          <li>Prowadząca zastrzega sobie również prawo do wypowiedzenia umowy Użytkownikowi w każdym momencie, w zgodzie z ogólnymi przepisami prawa cywilnego, w tym zwłaszcza art. 746 § 2 kodeksu cywilnego.</li>
          <li>W przypadkach w których Prowadząca zobowiązana jest do zwrotu środków pieniężnych na rzecz Użytkownika, przelew dokonywany będzie na rachunek bankowy, z którego doszło do zakupu usługi.</li>
        </ol>
        <h5>&sect; 9 Dane osobowe i prywatność</h5>
        <p>Administratorem danych osobowych ucznia jest Prowadząca, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.&nbsp;Prowadząca przetwarza dane osobowe podane przez ucznia w procesie rejestracji konta w celu świadczenia usług drogą elektroniczną. Więcej informacji w tym zakresie znajduje się w Polityce Prywatności, dostępnej z poziomu serwisu.</p>
        <h5>&sect; 10 Reklamacje</h5>
        <ol>
          <li>Wszelkie reklamacje dotyczące korzystania z serwisu i oferowanych za jego pośrednictwem funkcjonalności należy przesyłać na adres Prowadzącej, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.</li>
          <li>Reklamacja powinna zawierać co najmniej:
            <ol type="a">
              <li>oznaczenie ucznia składającego reklamację, pozwalające na identyfikację;</li>
              <li>wskazanie funkcjonalności, z kt&oacute;rą wiąże się reklamacja;</li>
              <li>wskazanie i uzasadnienie zarzut&oacute;w będących podstawą reklamacji;</li>
              <li>wskazanie zakresu żądania ucznia składającego reklamację;</li>
            </ol>
          </li>
          <li>Jeśli reklamacja nie zawiera danych, o kt&oacute;rych mowa w ust. 2, Prowadząca, w terminie 7 dni od jej doręczenia, może wezwać Ucznia do uzupełnienia danych.</li>
          <li>Rozpatrzenie reklamacji nastąpi w ciągu 14 dni od dnia doręczenia reklamacji. W razie wezwania do uzupełnienia reklamacji, termin ten liczy się od dnia doręczenia reklamacji uzupełnionej.</li>
        </ol>

        <h5>&sect; 11 Usługa subskrypcyjna ChemMystery</h5>
        <ol>
          <li>Prowadząca umożliwia Użytkownikowi udział w usłudze subskrypcyjnej pod nazwą „ChemMystery” (dalej: „ChemMystery”)</li>
          <li>W ramach subskrypcji ChemMystery Użytkownik otrzymywał będzie co miesiąc dostęp do dodatkowych materiałów, treści cyfrowych, za pośrednictwem swojego Konta Użytkownika.</li>
          <li>Dodatkowo, w okresie trwania aktywnej subskrypcji ChemMystery Użytkownik uprawniony będzie do zadania w ciągu każdego miesiąca kalendarzowego – za pośrednictwem przygotowanego formularza – trzech indywidualnych pytań z zakresu nauki Chemii, na które odpowiadać będzie certyfikowany egzaminator dowolnej Okręgowej Komisji Egzaminacyjnej (dalej: „OKE”), wpisany do ewidencji egzaminatorów OKE.</li>
          <li>Do usługi ChemMystery znajdują zastosowanie pozostałe zapisy regulaminu, w tym w szczególności § 2, 3, 8 oraz § 10.</li>
          <li>Przystąpienie do ChemMystery tworzy automatycznie Konto Użytkownika, w razie gdy osoba dokonująca zakupu nie posiadała go uprzednio w serwisie.</li>
        </ol>
        <h5>&sect; 12 Zasady funkcjonowania usługi subskrypcyjnej ChemMystery. Dodatkowe materiały oraz Pytanie do Egzaminatora.</h5>
        <ol>
          <li>Zakupując dostęp do ChemMystery Użytkownik będzie otrzymywał dodatkowe, niezwiązane z pozostałymi treściami cyfrowymi oferowanymi przez Prowadzącą, materiały dostępne na Koncie Użytkownika w odrębnej zakładce ChemMystery. Materiały te mają na celu polepszenie przygotowania Użytkownika do uczestnictwa w egzaminie maturalnym i stanowią uzupełnienie wiedzy przekazywanej Użytkownikowi w ramach pozostałych treści cyfrowych.</li>
          <li>Każdego miesiąca na Koncie Użytkownika w ramach ChemMystery pojawią się co najmniej trzy treści cyfrowe o tematyce Chemii. Treści te mogą stanowić dodatkowe lekcje wideo, quiz, artykuł, test, autorskie opracowania lub materiały innego rodzaju wedle uznania Prowadzącej. Wybór charakteru każdego materiału należy do Prowadzącej i Użytkownik nie posiada roszczenia co do oczekiwania konkretnego rodzaju treści cyfrowej.</li>
          <li>W ramach ChemMystery Użytkownik, za pośrednictwem dedykowanego formularza dostępnego na jego Koncie Użytkownika, ma możliwość zadania w ciągu miesiąca kalendarzowego trzech „Pytań do Egzaminatora”, o których mowa w § 11 ust. 3 regulaminu.</li>
          <li>W ramach formularza Pytań do Egzaminatora, Użytkownik ma możliwość przedstawienia opisowego swojego problemu/pytania, jak również załączenia dodatkowo pliku graficznego lub tekstowego.</li>
          <li>Pytania do Egzaminatora mają na celu przygotowanie Użytkownika do egzaminu maturalnego i powinny odnosić się do tematyki związanej z nauką chemii, w szczególności zaś treści, przebiegu oraz organizacji samego egzaminu. W szczególności sugerowany – ale nie wyłączny – zakres pytań w ramach Pytań do Egzaminatora może przybrać postać pytań:
            <ol>
              <li>„czy moja odpowiedź zostałaby uznana na egzaminie?”</li>
              <li>„czy mój zapis jest prawidłowy?”</li>
              <li>„czy za takie obliczenia dostanę punkt?”</li>
              <li>„czy takie coś uznają jako poprawne?”</li>
              <li>„gdzie mam błąd w tym zadaniu?”</li>
              <li>„dlaczego nie wychodzi mi poprawna odpowiedź?”</li>
              <li>„jak rozwiązać to zadanie?”</li>
            </ol>
          </li>
          <li>Po złożeniu pytania za pośrednictwem formularza egzaminator, o którym mowa w § 11 ust. 3 regulaminu, przygotuje odpowiedź na pytanie w ciągu 72 godzin. Do czasu tego nie wlicza się dni wolnych od pracy oraz świąt. Odpowiedź na pytanie Użytkownik uzyska za pośrednictwem wiadomości na swoim Koncie Użytkownika.</li>
          <li>W przypadku okoliczności losowych uniemożliwiających udzielenie odpowiedzi w określonym powyżej przedziale czasowym, Użytkownik zostanie powiadomiony odrębną wiadomością, w której określony zostanie także przewidywany czas udzielenia odpowiedzi na zadane pytanie. W szczególnie uzasadnionych wypadkach Prowadząca uprawniona jest do poinformowania wszystkich Użytkowników o wydłużeniu czasu odpowiedzi na zadane pytania poprzez informację zamieszczoną na stronie głównej serwisu www.chemmaster.pl</li>
          <li>Maksymalny czas uzyskania odpowiedzi na zadane pytanie wynosi 30 dni, z zastrzeżeniem, że nie dotyczy to sytuacji, w których odpowiedź miałaby zostać udzielona po dacie egzaminu maturalnego z chemii w danym roku szkolnym. Wówczas ostatnim dniem, w którym udzielona zostanie odpowiedź jest dzień poprzedzający dzień egzaminu maturalnego z chemii, chyba że pytanie zostało złożone na mniej niż 72 godziny przed dniem egzaminu.</li>
          <li>Udzielona na zadane pytanie odpowiedź odzwierciedla najlepszą wiedzę egzaminatora. Prowadząca ani egzaminator nie ponoszą odpowiedzialności za szkody lub krzywdę, jakiej doznać może Użytkownik w wyniku powoływania się na uzyskaną odpowiedź względem podmiotów trzecich.</li>
          <li>W przypadku, w którym Użytkownik powziął uzasadnioną wątpliwość co do wiarygodności lub rzetelności uzyskanej odpowiedzi, uprawniony jest do zwrócenia się z reklamacją odpowiednio do zapisów § 10 regulaminu. Reklamacja odpowiedzi uzyskanej od egzaminatora powinna być w formie e-mail na adres kontakt@chemmaster.pl i zawierać opis sytuacji w sposób umożliwiający rzetelne i kompleksowe ustosunkowanie się Prowadzącej. W przypadku uznania reklamacji za zasadną Użytkownik uzyska prawo do dodatkowego Pytania do Egzaminatora, ponad podstawowy limit trzech Pytań do Egzaminatora w miesiącu.</li>
          <li>W przypadku, w którym zadane pytanie wykraczałoby poza tematykę nauki chemii, zawierałoby treści wulgarne, obraźliwe, niestosowne lub z innych przyczyn nie nadawałoby się do udzielenia odpowiedzi, Prowadząca lub egzaminator uprawnieni są do odrzucenia takiego pytania bez udzielania odpowiedzi. Użytkownik zachowuje wówczas prawo do zadania kolejnego pytania, tak jakby odrzuconego pytania nie zadano.</li>
        </ol>
        <h5>&sect; 13 Warunki przystąpienia do usługi subskrypcyjnej ChemMystery</h5>
        <ol>
          <li>Usługa ChemMystery powiązana jest z obowiązującym rokiem szkolnym i może być aktywna na Koncie Użytkownika od września danego roku, do maja roku następnego.</li>
          <li>ChemMystery oferowane jest w dwóch postaciach – subskrypcji 3-miesięcznej oraz subskrypcji 9-miesięcznej</li>
          <li>Subskrypcja 9-miesięczna obejmuje dostęp do ChemMystery na przestrzeni całego roku szkolnego, zgodnie z ust. 1 powyżej</li>
          <li>Subskrypcja 3-miesięczna obejmuje dostęp do ChemMystery w jednym z trzech okresów cząstkowych, tj. wrzesień – listopad, grudzień – luty lub marzec – maj.</li>
          <li>Przystąpienie do ChemMystery w trakcie trwania roku szkolnego powoduje uzyskanie dostępu do treści cyfrowych zamieszczonych w tej usłudze zgodnie z postanowieniami § 12 regulaminu, odpowiednio do okresu trwania zakupionej subskrypcji. Treści te będą dostępne na Koncie Użytkownika do końca trwania zakupionego okresu subskrypcji.</li>
          <li>Przystąpienie do ChemMystery w trakcie trwania roku szkolnego do którejkolwiek z wersji subskrypcji nie uprawnia Użytkownika do skorzystania z „Pytań do Egzaminatora” za przeszłe miesiące w okresie subskrypcji.</li>
          <li>W zgodzie z powyższym zapisem, przystąpienie do subskrypcji dowolnego dnia uprawnia do zadania trzech Pytań do Egzaminatora w odniesieniu do miesiąca, w którym dokonano zakupu subskrypcji.</li>
          <li>Pula trzech pytań dotyczy każdorazowo jednego miesiąca kalendarzowego. Niewykorzystanie możliwości zadania trzech pytań nie powoduje zwiększenia puli pytań w miesiącach następnych.</li>
          <li>Cena za każdą z subskrypcji ogłaszana jest przez Prowadzącą każdorazowo na podstronie ofertowej. Do procedury zakupu zastosowanie znajduje odpowiednio § 5 regulaminu.</li>
        </ol>
        <h5>&sect; 14 Postanowienia końcowe</h5>
        <ol>
          <li>Wszelkie nieuregulowane w niniejszym regulaminie kwestie rządzą się przepisami prawa polskiego, a zwłaszcza kodeksu cywilnego, oraz ustaw &ndash; o prawach konsumenta oraz o prawie autorskim i prawach pokrewnych.</li>
          <li>Regulamin w niniejszej wersji obowiązuje od 31 sierpnia 2021 r.</li>
          <li>Prowadząca zastrzega sobie prawo do zmiany regulaminu z ważnych przyczyn. W szczeg&oacute;lności zmiana może nastąpić w razie konieczności dostosowania regulaminu do wymog&oacute;w znowelizowanego prawa, technicznych względ&oacute;w funkcjonowania serwisu lub modyfikacji zakresu funkcjonowania serwisu.</li>
          <li>Zmiana regulaminu wchodzi w życie w stosunku do użytkownika w terminie wskazanym przez Prowadzącą w wiadomości skierowanej do użytkownika, przesłanej albo za pośrednictwem wiadomości e-mail albo dostępnej po zalogowaniu się do konta użytkownika. Zakupy online dokonane przed wejściem w życie zmiany regulaminu rządzą się zasadami określonymi w regulaminie w brzmieniu uprzednim.</li>
          <li>Prowadząca ma obowiązek dostarczania treści cyfrowych bez wad.</li>
          <li>Zapłata ceny za oferowane treści cyfrowe wyczerpuje zobowiązania użytkownika, dalsze korzystanie z zakupionej treści cyfrowej stanowi jego uprawnienie.</li>
          <li>W przypadku unieważnienia kt&oacute;regokolwiek postanowienia niniejszego regulaminu mocą orzeczenia sądu, pozostałe jego postanowienia pozostają w mocy i są obowiązujące.</li>
          <li>Treść regulaminu jest udostępniona w spos&oacute;b stały i nieodpłatnie w serwisie w taki spos&oacute;b, aby użytkownik w każdym czasie m&oacute;gł regulamin odtwarzać i zachowywać jego treść. Zabrania się kopiowania regulaminu i wykorzystywania go do cel&oacute;w prywatnych lub komercyjnych bez zgody Prowadzącej.</li>
        </ol>
      </b-card-text>
    </b-card>
    <b-card sub-title="Wersja obowiązująca od 01.01.2023r.">
      <b-card-text>
        <h3><strong>Regulamin</strong></h3>
        <h5>&sect; 1 Definicje</h5>
        <ol>
          <li>Ilekroć w niniejszym regulaminie używa się poniższych zwrot&oacute;w, oznaczają one:</li>
        </ol>
        <p>a) Prowadząca – Sonia Radosz, prowadząca działalność gospodarczą pod firmą ChemMaster Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail: kontakt@chemmaster.pl, nr telefonu przedsiębiorstwa: 727447983</p>
        <p>b) Użytkownik – osoba korzystająca z serwisu będąca osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych</p>
        <p>c) serwis – witryna www.chemmaster.pl</p>
        <p>d) e-book – przygotowane w formie pliku komputerowego opracowania obejmujące teksty oraz grafiki</p>
        <p>e) kurs online – prowadzony przez Prowadzącą za pośrednictwem środków komunikacji elektronicznej umożliwiających transmisję obrazu i głosu cykl prezentacji, podczas których przekazuje ona wiedzę i umiejętności w zakresie oferowanego produktu</p>
        <p>f) treści cyfrowe – oferowane przez Prowadzącą do sprzedaży za pośrednictwem serwisu usługi i utwory, nie będące utrwalone na nośniku materialnym, do których użytkownik ma dostęp za pomocą konta użytkownika.</p>
        <p>g) zakup online – procedura zamawiania treści cyfrowych oferowanych przez Prowadzącą za pośrednictwem serwisu</p>
        <p>h) konto, konto użytkownika – indywidualne konto użytkownika umożliwiające dostęp do zakupionych materiałów.</p>
        <p>i) newsletter – wiadomości e-mail stanowiące cykliczne informacje o działalności Prowadzącej jak również mogące zawierać informację handlową, adresowane do użytkownika po uprzednim wyrażeniu przez niego odpowiedniej zgody, stanowiące usługę świadczoną drogą elektroniczną w myśl art. 2 ust. 4 ustawy o świadczeniu usług droga elektroniczną</p>


        <h5>&sect; 2 Postanowienia og&oacute;lne</h5>
        <ol>
          <li>Postanowienia niniejszego regulaminu normują zasady oraz warunki korzystania z serwisu oraz funkcjonalności na nim oferowanych, w tym zakupu online.</li>
          <li>Korzystając z serwisu, a w szczeg&oacute;lności zakładając konto, użytkownik zgadza się przestrzegać niniejszego regulaminu i jego postanowień.</li>
          <li>Użytkownik zobowiązuje się do korzystania z konta i zakupionych treści online wyłącznie osobiście, zabronione jest wsp&oacute;łdzielenie konta i zakupionych treści cyfrowych z osobą trzecią.</li>
          <li>Użytkownik jest jednakże uprawniony do przekazania dostępu do konta oraz zakupionej online treści cyfrowej jednemu zstępnemu. Użytkownik zobowiązuje się, pod rygorem odpowiedzialności za działania zstępnego jak za swoje, do zapoznania zstępnego z niniejszym regulaminem oraz nadzorem na zstępnym w zakresie zgodności korzystania z zakupionych online treści cyfrowych z postanowieniami niniejszego regulaminu.</li>
        </ol>
        <h5>&sect; 3 Ochrona własności intelektualnej</h5>
        <ol>
          <li>Wszystkie treści cyfrowe oferowane przez Prowadzącą stanowią jej własność i są przedmiotem ochrony prawa polskiego i międzynarodowego, w szczeg&oacute;lności przepis&oacute;w ustawy z 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</li>
          <li>Z momentem zakupu online treści cyfrowej, na użytkownika nie przechodzą żadne prawa autorskie, tak osobiste jak majątkowe.</li>
          <li>Bez zgody Prowadzącej zabronione jest rozpowszechniane treści cyfrowych uzyskanych za pomocą zakupu online.</li>
          <li>Użytkownik nie jest uprawniony w szczeg&oacute;lności do
            <ol type="a">
              <li>ingerencji w treść treści cyfrowej</li>
              <li>usuwania zabezpieczeń treści cyfrowej, jak np. znaki wodne</li>
              <li>zwielokrotniania treści cyfrowej w formie elektronicznej lub tradycyjnej</li>
              <li>udostępniania treści cyfrowej w internecie, w tym na platformach P2P jak sieci torrent lub inne</li>
              <li>wykorzystywania treści cyfrowej do cel&oacute;w komercyjnych, modyfikacji, zar&oacute;wno w całości jak i w części.</li>
            </ol>
          </li>
          <li>Rozpowszechnianie utworu bez uprawnienia stanowi przestępstwo opisane w art. 116 ustawy o prawie autorskim i prawach pokrewnych i każdy wykryty przypadek takiego działania zostanie zgłoszony do właściwego organu. Dotyczy to także innych przestępstw uregulowanych w powołanej ustawie, jak r&oacute;wnież innych przepisach, jeśli bezprawne rozpowszechnianie treści cyfrowych wyczerpywać będzie znamiona tych przestępstw.</li>
          <li>Użytkownik ma prawo korzystać z treści cyfrowej tylko na własny użytek. Postanowienie to działa bez uszczerbku na uprawnienie opisane w &sect; 2 ust. 4 regulaminu.</li>
          <li>Użytkownik ma prawo do wydruku treści cyfrowej nadającej się do tego, jak np. e-book. Wydrukowana treść cyfrowa jednakże pozostaje pod ochroną prawa własności intelektualnej, w szczeg&oacute;lności w zakresie zakazu kopiowania i rozpowszechniania, przekazywania osobom trzecim do korzystania.</li>
          <li>Użytkownik ma prawo korzystać, w zgodzie z postanowieniami powyższymi, z zakupionych online treści cyfrowych za pośrednictwem r&oacute;żnych urządzeń elektronicznych, o ile nie prowadzi to do zmiany w strukturze treści cyfrowej.</li>
          <li>W przypadku gdy oferowane treści cyfrowe stanowią opracowania tekst&oacute;w zewnętrznych, og&oacute;lnodostępnych (jak np. zbiory zadań Centralnych lub Obwodowych Komisji Egzaminacyjnych), przedmiotem praw autorskich Prowadzącej są dokonane przez nią analizy powyższych tekst&oacute;w w każdej postaci, a w szczeg&oacute;lności komentarzy, grafik, opracowań.</li>
        </ol>
        <h5>&sect; 4 Zakładanie konta</h5>
        <ol>
          <li>Użytkownik chcący korzystać z funkcjonalności serwisu powinien zarejestrować się w serwisie poprzez utworzenie konta.</li>
          <li>Użytkownik zakładający konto powinien być osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych.</li>
        </ol>
        <h5>&sect; 5 Procedura zakupu online treści cyfrowej</h5>
        <ol>
          <li>Prowadząca umożliwia w ramach serwisu zakup online treści cyfrowych, w szczeg&oacute;lności w postaci uczestnictwa w kursie online lub dostarczenia e-booka.</li>
          <li>Informacje o właściwościach oferowanej treści cyfrowej dostępne są każdorazowo z poziomu serwisu.</li>
          <li>Zakup online możliwy jest wyłącznie dla użytkownika, kt&oacute;ry zarejestrował w serwisie swoje konto i kt&oacute;ry podczas procedury zakupu pozostaje zalogowany.</li>
          <li>Treści cyfrowe oferowane w serwisie mogą ulegać zmianie, modyfikacji, ich dostępność może być ograniczona czasowo lub ilościowo, wedle uznania Prowadzącej.</li>
          <li>Płatności za treści cyfrowe dokonywane są za pomocą platformy PayU. Regulaminy funkcjonowania tej platformy dostępne są dla użytkownika pod adresem https://poland.payu.com/dokumenty-prawne-do-pobrania/. Dokonując zakupu online użytkownik potwierdza także zapoznanie się z tymi regulaminami.</li>
          <li>Cena za oferowane treści cyfrowe wyrażona jest w złotych (PLN).</li>
          <li>Prowadząca uprawniona jest do oferowania kod&oacute;w rabatowych, kt&oacute;re pomniejszają ostateczną cenę zakupu treści cyfrowej.</li>
          <li>Spos&oacute;b, terminy i warunki korzystania z kod&oacute;w rabatowych określane są każdorazowo przez Prowadzącą w ramach informacji o tych kodach umieszczonych w serwisie. Pozyskany kod rabatowy przypisany jest na stałe do jednego konta i tylko dane konto może go aktywować.</li>
          <li>Po wyborze żądanej treści cyfrowej użytkownik powinien postępować zgodnie ze wskaz&oacute;wkami widocznymi na stronie internetowej, kt&oacute;ra przeprowadzi go przez proces zakupu.</li>
          <li>Ostateczna cena zakupu, po uwzględnieniu ewentualnych kod&oacute;w rabatowych, stanowi cenę wiążącą, kt&oacute;rą po zaakceptowaniu warunk&oacute;w sprzedaży i przystąpienia do płatności za pośrednictwem platformy PayU użytkownik zobowiązuje się zapłacić.</li>
          <li>Posiadany kod rabatowy należy podać w przeznaczonym do tego polu podczas procedury zakupu, w przeciwnym wypadku nie zostanie on naliczony, nawet jeżeli użytkownik jest uprawniony do jego wykorzystania. W szczeg&oacute;lności posiadane, a nie wykorzystane kody rabatowe nie podlegają zamianie na inne kody rabatowe czy wymianie na środki pieniężne.</li>
          <li>Po złożeniu dyspozycji zakupu i ukończeniu procesu płatności za pośrednictwem platformy PayU (tj. potwierdzenia przez PayU udanego przelewu ceny) użytkownik otrzyma na ujawniony przez siebie adres e-mail automatycznie wygenerowaną wiadomość z potwierdzeniem złożenia zam&oacute;wienia. Z chwilą wysłania tej wiadomości dochodzi do zawarcia umowy, na mocy kt&oacute;rej użytkownik uzyskuje prawo do korzystania z treści cyfrowej, na warunkach niniejszego regulaminu.</li>
          <li>Brak ukończenia procesu płatności za pośrednictwem PayU i zapłaty ceny zwalnia Prowadzącą z obowiązku realizacji zam&oacute;wienia, o czym użytkownik nie musi zostać poinformowany odrębną wiadomością.</li>
          <li>W każdym przypadku, gdy Prowadząca nie jest w stanie zrealizować dokonanego zam&oacute;wienia, użytkownik bez zbędnej zwłoki zostanie o tym poinformowany wiadomością e-mail. W takim przypadku najp&oacute;źniej w ciągu 14 dni od daty zakupu Prowadząca zwr&oacute;ci całą kwotę zapłaconą przez użytkownika, na rachunek z kt&oacute;rego dokonano transakcji pierwotnej.</li>
          <li>Przyczyny, dla kt&oacute;rych Prowadząca nie jest w stanie zrealizować zam&oacute;wienia nie podlegają ujawnieniu użytkownikowi.</li>
          <li>Prowadząca umożliwia wystawienie faktury do każdego zam&oacute;wienia. W celu uzyskania faktury użytkownik powinien w toku dokonywania zam&oacute;wienia zaznaczyć odpowiednią rubrykę i wprowadzić dane umożliwiające wystawienie faktury. Taka faktura w postaci elektronicznej jest następnie &ndash; po pomyślnym dokonaniu zapłaty &ndash; wysyłana e-mailem na wskazany adres użytkownika.</li>
          <li>Prowadząca jest podmiotem korzystającym ze zwolnienia od VAT na podstawie art. 43 ust. 1 ustawy o podatku od towar&oacute;w i usług, wobec czego ceny płacone za treści cyfrowe przez użytkownika ujawnione zostaną na fakturze z VAT zwolnionym.</li>
        </ol>
        <h5>&sect; 6 Spos&oacute;b dostępu do zakupionej treści cyfrowej i warunki techniczne</h5>
        <ol>
          <li>Po dokonaniu zakupu online treści cyfrowej, użytkownik poprzez dostęp do swojego konta może przenieść się do strony internetowej oznaczonej zakładką &bdquo;Moje kursy&rdquo;.</li>
          <li>W sekcji &bdquo;Moje kursy&rdquo; użytkownikowi udostępnione zostaną zakupione treści cyfrowe. Spos&oacute;b korzystania z treści cyfrowej uzależniony jest od charakteru zakupionej treści cyfrowej i nie odbiega od standardowo przyjętego w sieci internet sposobu korzystania z plik&oacute;w i program&oacute;w komputerowych.</li>
          <li>Dostęp do treści cyfrowych oferowanych w postaci plik&oacute;w tekstowych (jak np. e-booki) wymaga zainstalowanego oprogramowania pozwalającego na odczyt plik&oacute;w w postaci *.pdf, takich jak Adobe Acrobat Reader.</li>
          <li>Dostęp do lekcji online na żywo (live) wymaga skorzystania z aplikacji umożliwiających transmisję głosu i obrazu.</li>
          <li>Treści cyfrowe zabezpieczone mogą być znakiem wodnym lub innym powszechnie stosowanym sposobem zabezpieczania treści w sieci internet.</li>
          <li>Szczeg&oacute;łowe warunki korzystania z danej treści cyfrowej, jak np. harmonogram webinari&oacute;w (transmisji) w przypadku kurs&oacute;w online na żywo (live), godziny transmisji, określane są na podstronie ofertowej każdej treści cyfrowej. Informacje dotyczące takich zajęć pojawią się na koncie użytkownika do 24 godzin od rozpoczęcia webinaru.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik w zakładce &bdquo;Moje kursy&rdquo; uzyska link zezwalający na dołączenie do transmisji zgodnie z zaplanowanym harmonogramem.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik po upływie terminu zaplanowanej transmisji uzyska dostęp do nagrania tego materiału w formie offline, z kt&oacute;rego może korzystać jak z każdej innej treści cyfrowej. Okres dostępu do takiej treści cyfrowej może być ograniczony czasowo, o czym użytkownik zostanie poinformowany w zakładce &bdquo;Moje kursy&rdquo; lub &bdquo;Powiadomienia&rdquo;.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca nie ponosi odpowiedzialności za przerwy w transmisji czy spadki w jej jakości spowodowane przepustowością sieci po stronie użytkownika.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, gdy Prowadząca z jakichkolwiek powod&oacute;w, w tym technicznych, zdrowotnych czy prywatnych, nie ma możliwości przeprowadzenia zaplanowanej transmisji, transmisja ta zostanie przeprowadzona w innym dniu, o kt&oacute;rym użytkownik zostanie poinformowany. Decyzja o braku możliwości przeprowadzenia zaplanowanej transmisji jest uznaniowa i użytkownik nie jest uprawniony do jej kwestionowania, ani kreowania z tego tytułu roszczeń wobec Prowadzącej, co pozostaje bez uszczerbku dla poniższych postanowień. Wedle swojego uznania Prowadząca może także w miejsce organizowania zastępczego terminu zaległej transmisji, o kt&oacute;rej mowa powyżej, przygotować materiał filmowy offline i udostępnić go w postaci nagrania w zakładce &bdquo;Moje kursy&rdquo; konta użytkownika. Przeprowadzenie transmisji zastępczej lub udostępnienie materiału offline każdorazowo nastąpi najp&oacute;źniej do 31 kwietnia danego roku, lub roku następnego jeśli zdarzenie ma miejsce po 31 kwietnia danego roku &ndash; za każdym razem tak, aby użytkownik posiadał komplet materiał&oacute;w przygotowujących do nauki jeszcze przed terminem matury.</li>
          <li>Z powodu nieprzeprowadzenia transmisji zgodnie z harmonogramem użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej, chyba że nie wywiąże się ona z przygotowania transmisji zastępczej lub nie udostępni materiału offline, w spos&oacute;b i w terminie o kt&oacute;rym mowa powyżej.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca jest nadto uprawniona do zmiany godziny transmisji w obrębie tego samego dnia. Z tego tytułu użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej.</li>
          <li>W przypadku gdyby kt&oacute;rakolwiek treść cyfrowa zawierała błąd obliczeniowy, tekstowy, skutkujący wadliwością merytoryczną treści cyfrowej, Prowadząca zobowiązuje się bez zbędnej zwłoki do zaktualizowania treści cyfrowej, poinformowania użytkownika o dostrzeżonym błędzie i udostępnienia treści cyfrowej o prawidłowej treści.</li>
          <li>Treści cyfrowe zakupione od 31 sierpnia 2021 r. w postaci materiał&oacute;w dostępnych w zakładce &bdquo;Moje kursy&rdquo; wygasają po upływie roku od daty zakupu*. Oznacza to, że z upływem ww. terminu dostęp do tych treści wygasa. *Z zastrzeżeniem sytuacji, w kt&oacute;rych opisie oferowanego kursu wskazana jest inna data dostępu do zakupionego kursu.&nbsp;</li>
        </ol>
        <h5>&sect; 6a Newsletter</h5>
        <ol>
          <li>Użytkownik ma możliwość wyrażenia zgody na otrzymywanie Newslettera poprzez zapisane się na listę mailingową. Prawidłowość świadczenia usługi możliwa jest wyłącznie w przypadku podania rzeczywistego adresu e-mail.</li>
          <li>Użytkownik wyrażając zgodę na otrzymywanie Newslettera potwierdza chęć otrzymywania informacji handlowej od Prowadzącej. Informacja handlowa zawiera informacje w przedmiocie bieżącej działalności Prowadzącej, oferowanych usług, produktów, promocji czy treści cyfrowych.</li>
          <li>Prowadząca uprawniona jest do udostępnienia użytkownikom zapisanym do Newslettera dodatkowych treści cyfrowych, dostępnych na koncie użytkownika. Dostęp do tych treści będzie darmowy dla każdego użytkownika zapisanego do usługi Newslettera.</li>
          <li>Wypisanie się z Newslettera możliwe jest w każdej chwili za pośrednictwem właściwej opcji w panelu w koncie użytkownika oraz po kliknięciu w link znajdujący się w stopce każdego przesyłanego Newslettera.</li>
          <li>Przetwarzanie danych użytkownika do celów Newslettera odbywa się zgodnie z informacjami opisanymi w Polityce Prywatności</li>
        </ol>
        <h5>&sect; 7 Dostarczanie treści cyfrowych przed upływem terminu do odstąpienia od umowy</h5>
        <ol>
          <li>Dokonując zakupu online, użytkownik wyraża zgodę na spełnienie świadczenia przed upływem terminu do odstąpienia od umowy.</li>
          <li>Z chwilą udzielenia zgody, o której mowa w ust. 1 powyżej, użytkownik traci prawo odstąpienia od umowy. Prowadząca bez zbędnej zwłoki prześle na adres e-mail ucznia podany do rejestracji w serwisie potwierdzenie zakupu online oraz utratę prawa odstąpienia od umowy.</li>
          <li>Akceptując regulamin, użytkownik potwierdza, że został poinformowany, że zgodnie z art. 38 pkt 13 ustawy o prawach konsumenta, w przypadku umowy o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, prawo do odstąpienia nie przysługuje jeśli spełnianie świadczenia nastąpiło na wyraźną zgodę konsumenta przed upływem terminu do odstąpienia umowy i po poinformowaniu o utracie prawa do odstąpienia od umowy.</li>
          <li>W ślad za powyższym, użytkownik, który dokonał zakupu online i zaakceptował rozpoczęcie świadczenia przed upływem terminu do odstąpienia od umowy, nie jest uprawniony do odstąpienia od zakupu e-booka lub kursu online. Po wykonaniu świadczenia usługi i uruchomieniu dostępu do kursu nie ma możliwości zamiany zakupionego kursu na inny spośród oferty.</li>
          <li>Powyższe zapisy regulaminu pozostają bez uszczerbku dla szczegółowych przepisów dotyczących zawierania umów o dostarczania treści cyfrowej określonych w szczególności w rozdziale 5b ustawy o prawach konsumenta.</li>
        </ol>
        <h5>&sect; 8 [uchylono]</h5>
        <ol>
          <li>Dokonując zakupu online, uczniowi przysługuje prawo odstąpienia od umowy w terminie 14 dni bez podania przyczyny. Termin na odstąpienie od umowy wygasa po upływie 14 dni od daty zawarcia umowy.</li>
          <li>Aby skorzystać z prawa do odstąpienia od umowy użytkownik powinien poinformować Prowadzącą w drodze jednoznacznego oświadczenia, przesłanego drogą elektroniczną lub tradycyjną.</li>
          <li>Oświadczenie, o kt&oacute;rym mowa powyżej, może być złożone w formie wypełnione wzoru formularza, stanowiącego załącznik nr 2 do ustawy o prawach konsumenta.</li>
          <li>Wysłanie informacji dotyczącej wykonania przysługującego prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy skutkuje zachowaniem terminu.</li>
          <li>Skuteczne odstąpienie od umowy skutkować będzie zwrotem wszystkich otrzymanych płatności, nie p&oacute;źniej niż w ciągu 14 dni od dnia otrzymania informacji o wykonaniu prawa odstąpienia. Zwrot płatności nastąpi przy użyciu takiego samego sposobu płatności jak transakcja pierwotna. Z tytułu zwrotu użytkownik nie ponosi dodatkowych koszt&oacute;w.</li>
          <li>Akceptując regulamin, użytkownik potwierdza, że został poinformowany, że zgodnie z art. 38 pkt 13 ustawy o prawach konsumenta, w przypadku umowy o dostarczanie treści cyfrowych, kt&oacute;re nie są zapisane na nośniku materialnym, prawo do odstąpienia nie przysługuje jeśli spełnianie świadczenia nastąpiło na wyraźną zgodę konsumenta przed<br />upływem terminu do odstąpienia umowy i po poinformowaniu o utracie prawa do odstąpienia od umowy.</li>
          <li>W ślad za powyższym, użytkownik, kt&oacute;ry dokonał zakupu online i zaakceptował rozpoczęcie świadczenia przed upływem terminu do odstąpienia od umowy, nie jest uprawniony do odstąpienia od zakupu e-booka lub kursu online. Po wykonaniu świadczenia usługi i uruchomieniu dostępu do kursu nie ma możliwości zamiany zakupionego kursu na inny spośr&oacute;d oferty.</li>
        </ol>
        <h5>&sect; 9 Dane osobowe i prywatność</h5>
        <p>Administratorem danych osobowych ucznia jest Prowadząca, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.&nbsp;Prowadząca przetwarza dane osobowe podane przez ucznia w procesie rejestracji konta w celu świadczenia usług drogą elektroniczną. Więcej informacji w tym zakresie znajduje się w Polityce Prywatności, dostępnej z poziomu serwisu.</p>
        <h5>&sect; 10 Reklamacje</h5>
        <ol>
          <li>Wszelkie reklamacje dotyczące korzystania z serwisu i oferowanych za jego pośrednictwem funkcjonalności należy przesyłać na adres Prowadzącej, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.</li>
          <li>Reklamacja powinna zawierać co najmniej:
            <ol type="a">
              <li>oznaczenie ucznia składającego reklamację, pozwalające na identyfikację;</li>
              <li>wskazanie funkcjonalności, z kt&oacute;rą wiąże się reklamacja;</li>
              <li>wskazanie i uzasadnienie zarzut&oacute;w będących podstawą reklamacji;</li>
              <li>wskazanie zakresu żądania ucznia składającego reklamację;</li>
            </ol>
          </li>
          <li>Jeśli reklamacja nie zawiera danych, o kt&oacute;rych mowa w ust. 2, Prowadząca, w terminie 7 dni od jej doręczenia, może wezwać Ucznia do uzupełnienia danych.</li>
          <li>Rozpatrzenie reklamacji nastąpi w ciągu 14 dni od dnia doręczenia reklamacji. W razie wezwania do uzupełnienia reklamacji, termin ten liczy się od dnia doręczenia reklamacji uzupełnionej.</li>
        </ol>

        <h5>&sect; 11 Usługa subskrypcyjna ChemMystery</h5>
        <ol>
          <li>Prowadząca umożliwia Użytkownikowi udział w usłudze subskrypcyjnej pod nazwą „ChemMystery” (dalej: „ChemMystery”)</li>
          <li>W ramach subskrypcji ChemMystery Użytkownik otrzymywał będzie co miesiąc dostęp do dodatkowych materiałów, treści cyfrowych, za pośrednictwem swojego Konta Użytkownika.</li>
          <li>Dodatkowo, w okresie trwania aktywnej subskrypcji ChemMystery Użytkownik uprawniony będzie do zadania w ciągu każdego miesiąca kalendarzowego – za pośrednictwem przygotowanego formularza – trzech indywidualnych pytań z zakresu nauki Chemii, na które odpowiadać będzie certyfikowany egzaminator dowolnej Okręgowej Komisji Egzaminacyjnej (dalej: „OKE”), wpisany do ewidencji egzaminatorów OKE.</li>
          <li>Do usługi ChemMystery znajdują zastosowanie pozostałe zapisy regulaminu, w tym w szczególności § 2, 3, 8 oraz § 10.</li>
          <li>Przystąpienie do ChemMystery tworzy automatycznie Konto Użytkownika, w razie gdy osoba dokonująca zakupu nie posiadała go uprzednio w serwisie.</li>
        </ol>
        <h5>&sect; 12 Zasady funkcjonowania usługi subskrypcyjnej ChemMystery. Dodatkowe materiały oraz Pytanie do Egzaminatora.</h5>
        <ol>
          <li>Zakupując dostęp do ChemMystery Użytkownik będzie otrzymywał dodatkowe, niezwiązane z pozostałymi treściami cyfrowymi oferowanymi przez Prowadzącą, materiały dostępne na Koncie Użytkownika w odrębnej zakładce ChemMystery. Materiały te mają na celu polepszenie przygotowania Użytkownika do uczestnictwa w egzaminie maturalnym i stanowią uzupełnienie wiedzy przekazywanej Użytkownikowi w ramach pozostałych treści cyfrowych.</li>
          <li>Każdego miesiąca na Koncie Użytkownika w ramach ChemMystery pojawią się co najmniej trzy treści cyfrowe o tematyce Chemii. Treści te mogą stanowić dodatkowe lekcje wideo, quiz, artykuł, test, autorskie opracowania lub materiały innego rodzaju wedle uznania Prowadzącej. Wybór charakteru każdego materiału należy do Prowadzącej i Użytkownik nie posiada roszczenia co do oczekiwania konkretnego rodzaju treści cyfrowej.</li>
          <li>W ramach ChemMystery Użytkownik, za pośrednictwem dedykowanego formularza dostępnego na jego Koncie Użytkownika, ma możliwość zadania w ciągu miesiąca kalendarzowego trzech „Pytań do Egzaminatora”, o których mowa w § 11 ust. 3 regulaminu.</li>
          <li>W ramach formularza Pytań do Egzaminatora, Użytkownik ma możliwość przedstawienia opisowego swojego problemu/pytania, jak również załączenia dodatkowo pliku graficznego lub tekstowego.</li>
          <li>Pytania do Egzaminatora mają na celu przygotowanie Użytkownika do egzaminu maturalnego i powinny odnosić się do tematyki związanej z nauką chemii, w szczególności zaś treści, przebiegu oraz organizacji samego egzaminu. W szczególności sugerowany – ale nie wyłączny – zakres pytań w ramach Pytań do Egzaminatora może przybrać postać pytań:
            <ol>
              <li>„czy moja odpowiedź zostałaby uznana na egzaminie?”</li>
              <li>„czy mój zapis jest prawidłowy?”</li>
              <li>„czy za takie obliczenia dostanę punkt?”</li>
              <li>„czy takie coś uznają jako poprawne?”</li>
              <li>„gdzie mam błąd w tym zadaniu?”</li>
              <li>„dlaczego nie wychodzi mi poprawna odpowiedź?”</li>
              <li>„jak rozwiązać to zadanie?”</li>
            </ol>
          </li>
          <li>Po złożeniu pytania za pośrednictwem formularza egzaminator, o którym mowa w § 11 ust. 3 regulaminu, przygotuje odpowiedź na pytanie w ciągu 72 godzin. Do czasu tego nie wlicza się dni wolnych od pracy oraz świąt. Odpowiedź na pytanie Użytkownik uzyska za pośrednictwem wiadomości na swoim Koncie Użytkownika.</li>
          <li>W przypadku okoliczności losowych uniemożliwiających udzielenie odpowiedzi w określonym powyżej przedziale czasowym, Użytkownik zostanie powiadomiony odrębną wiadomością, w której określony zostanie także przewidywany czas udzielenia odpowiedzi na zadane pytanie. W szczególnie uzasadnionych wypadkach Prowadząca uprawniona jest do poinformowania wszystkich Użytkowników o wydłużeniu czasu odpowiedzi na zadane pytania poprzez informację zamieszczoną na stronie głównej serwisu www.chemmaster.pl</li>
          <li>Maksymalny czas uzyskania odpowiedzi na zadane pytanie wynosi 30 dni, z zastrzeżeniem, że nie dotyczy to sytuacji, w których odpowiedź miałaby zostać udzielona po dacie egzaminu maturalnego z chemii w danym roku szkolnym. Wówczas ostatnim dniem, w którym udzielona zostanie odpowiedź jest dzień poprzedzający dzień egzaminu maturalnego z chemii, chyba że pytanie zostało złożone na mniej niż 72 godziny przed dniem egzaminu.</li>
          <li>Udzielona na zadane pytanie odpowiedź odzwierciedla najlepszą wiedzę egzaminatora. Prowadząca ani egzaminator nie ponoszą odpowiedzialności za szkody lub krzywdę, jakiej doznać może Użytkownik w wyniku powoływania się na uzyskaną odpowiedź względem podmiotów trzecich.</li>
          <li>W przypadku, w którym Użytkownik powziął uzasadnioną wątpliwość co do wiarygodności lub rzetelności uzyskanej odpowiedzi, uprawniony jest do zwrócenia się z reklamacją odpowiednio do zapisów § 10 regulaminu. Reklamacja odpowiedzi uzyskanej od egzaminatora powinna być w formie e-mail na adres kontakt@chemmaster.pl i zawierać opis sytuacji w sposób umożliwiający rzetelne i kompleksowe ustosunkowanie się Prowadzącej. W przypadku uznania reklamacji za zasadną Użytkownik uzyska prawo do dodatkowego Pytania do Egzaminatora, ponad podstawowy limit trzech Pytań do Egzaminatora w miesiącu.</li>
          <li>W przypadku, w którym zadane pytanie wykraczałoby poza tematykę nauki chemii, zawierałoby treści wulgarne, obraźliwe, niestosowne lub z innych przyczyn nie nadawałoby się do udzielenia odpowiedzi, Prowadząca lub egzaminator uprawnieni są do odrzucenia takiego pytania bez udzielania odpowiedzi. Użytkownik zachowuje wówczas prawo do zadania kolejnego pytania, tak jakby odrzuconego pytania nie zadano.</li>
        </ol>
        <h5>&sect; 13 Warunki przystąpienia do usługi subskrypcyjnej ChemMystery</h5>
        <ol>
          <li>Usługa ChemMystery powiązana jest z obowiązującym rokiem szkolnym i może być aktywna na Koncie Użytkownika od września danego roku, do maja roku następnego.</li>
          <li>ChemMystery oferowane jest w dwóch postaciach – subskrypcji 3-miesięcznej oraz subskrypcji 9-miesięcznej</li>
          <li>Subskrypcja 9-miesięczna obejmuje dostęp do ChemMystery na przestrzeni całego roku szkolnego, zgodnie z ust. 1 powyżej</li>
          <li>Subskrypcja 3-miesięczna obejmuje dostęp do ChemMystery w jednym z trzech okresów cząstkowych, tj. wrzesień – listopad, grudzień – luty lub marzec – maj.</li>
          <li>Przystąpienie do ChemMystery w trakcie trwania roku szkolnego powoduje uzyskanie dostępu do treści cyfrowych zamieszczonych w tej usłudze zgodnie z postanowieniami § 12 regulaminu, odpowiednio do okresu trwania zakupionej subskrypcji. Treści te będą dostępne na Koncie Użytkownika do końca trwania zakupionego okresu subskrypcji.</li>
          <li>Przystąpienie do ChemMystery w trakcie trwania roku szkolnego do którejkolwiek z wersji subskrypcji nie uprawnia Użytkownika do skorzystania z „Pytań do Egzaminatora” za przeszłe miesiące w okresie subskrypcji.</li>
          <li>W zgodzie z powyższym zapisem, przystąpienie do subskrypcji dowolnego dnia uprawnia do zadania trzech Pytań do Egzaminatora w odniesieniu do miesiąca, w którym dokonano zakupu subskrypcji.</li>
          <li>Pula trzech pytań dotyczy każdorazowo jednego miesiąca kalendarzowego. Niewykorzystanie możliwości zadania trzech pytań nie powoduje zwiększenia puli pytań w miesiącach następnych.</li>
          <li>Cena za każdą z subskrypcji ogłaszana jest przez Prowadzącą każdorazowo na podstronie ofertowej. Do procedury zakupu zastosowanie znajduje odpowiednio § 5 regulaminu.</li>
        </ol>
        <h5>&sect; 14 Postanowienia końcowe</h5>
        <ol>
          <li>Wszelkie nieuregulowane w niniejszym regulaminie kwestie rządzą się przepisami prawa polskiego, a zwłaszcza kodeksu cywilnego, oraz ustaw &ndash; o prawach konsumenta oraz o prawie autorskim i prawach pokrewnych.</li>
          <li>Regulamin w niniejszej wersji obowiązuje od 31 sierpnia 2021 r.</li>
          <li>Prowadząca zastrzega sobie prawo do zmiany regulaminu z ważnych przyczyn. W szczeg&oacute;lności zmiana może nastąpić w razie konieczności dostosowania regulaminu do wymog&oacute;w znowelizowanego prawa, technicznych względ&oacute;w funkcjonowania serwisu lub modyfikacji zakresu funkcjonowania serwisu.</li>
          <li>Zmiana regulaminu wchodzi w życie w stosunku do użytkownika w terminie wskazanym przez Prowadzącą w wiadomości skierowanej do użytkownika, przesłanej albo za pośrednictwem wiadomości e-mail albo dostępnej po zalogowaniu się do konta użytkownika. Zakupy online dokonane przed wejściem w życie zmiany regulaminu rządzą się zasadami określonymi w regulaminie w brzmieniu uprzednim.</li>
          <li>Prowadząca ma obowiązek dostarczania treści cyfrowych bez wad.</li>
          <li>Zapłata ceny za oferowane treści cyfrowe wyczerpuje zobowiązania użytkownika, dalsze korzystanie z zakupionej treści cyfrowej stanowi jego uprawnienie.</li>
          <li>W przypadku unieważnienia kt&oacute;regokolwiek postanowienia niniejszego regulaminu mocą orzeczenia sądu, pozostałe jego postanowienia pozostają w mocy i są obowiązujące.</li>
          <li>Treść regulaminu jest udostępniona w spos&oacute;b stały i nieodpłatnie w serwisie w taki spos&oacute;b, aby użytkownik w każdym czasie m&oacute;gł regulamin odtwarzać i zachowywać jego treść. Zabrania się kopiowania regulaminu i wykorzystywania go do cel&oacute;w prywatnych lub komercyjnych bez zgody Prowadzącej.</li>
        </ol>
      </b-card-text>
    </b-card>
    <b-card sub-title="Wersja obowiązująca od 5.09.2022r.">
      <b-card-text>
        <h3><strong>Regulamin</strong></h3>
        <h5>&sect; 1 Definicje</h5>
        <ol>
          <li>Ilekroć w niniejszym regulaminie używa się poniższych zwrot&oacute;w, oznaczają one:</li>
        </ol>
        <p>a) Prowadząca &ndash; Sonia Radosz, prowadząca działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl</p>
        <p>b) Użytkownik &ndash; osoba korzystająca z serwisu będąca osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych</p>
        <p>c) serwis &ndash; witryna www.chemmaster.pl</p>
        <p>d) e-book &ndash; przygotowane w formie pliku komputerowego opracowania obejmujące teksty oraz grafiki</p>
        <p>e) kurs online &ndash; prowadzony przez Prowadzącą za pośrednictwem środk&oacute;w komunikacji elektronicznej umożliwiających transmisję obrazu i głosu cykl prezentacji, podczas kt&oacute;rych przekazuje ona wiedzę i umiejętności w zakresie oferowanego produktu</p>
        <p>f) treści cyfrowe &ndash; oferowane przez Prowadzącą do sprzedaży za pośrednictwem serwisu usługi i utwory, nie będące utrwalone na nośniku materialnym, do kt&oacute;rych użytkownik ma dostęp za pomocą konta użytkownika.</p>
        <p>g) zakup online &ndash; procedura zamawiania treści cyfrowych oferowanych przez Prowadzącą za pośrednictwem serwisu</p>
        <p>h) konto, konto użytkownika &ndash; indywidualne konto użytkownika umożliwiające dostęp do zakupionych materiał&oacute;w.</p>
        <p>i) newsletter – wiadomości e-mail stanowiące cykliczne informacje o działalności Prowadzącej jak również mogące zawierać informację handlową, adresowane do użytkownika po uprzednim wyrażeniu przez niego odpowiedniej zgody, stanowiące usługę świadczoną drogą elektroniczną w myśl art. 2 ust. 4 ustawy o świadczeniu usług droga elektroniczną</p>
        <h5>&sect; 2 Postanowienia og&oacute;lne</h5>
        <ol>
          <li>Postanowienia niniejszego regulaminu normują zasady oraz warunki korzystania z serwisu oraz funkcjonalności na nim oferowanych, w tym zakupu online.</li>
          <li>Korzystając z serwisu, a w szczeg&oacute;lności zakładając konto, użytkownik zgadza się przestrzegać niniejszego regulaminu i jego postanowień.</li>
          <li>Użytkownik zobowiązuje się do korzystania z konta i zakupionych treści online wyłącznie osobiście, zabronione jest wsp&oacute;łdzielenie konta i zakupionych treści cyfrowych z osobą trzecią.</li>
          <li>Użytkownik jest jednakże uprawniony do przekazania dostępu do konta oraz zakupionej online treści cyfrowej jednemu zstępnemu. Użytkownik zobowiązuje się, pod rygorem odpowiedzialności za działania zstępnego jak za swoje, do zapoznania zstępnego z niniejszym regulaminem oraz nadzorem na zstępnym w zakresie zgodności korzystania z zakupionych online treści cyfrowych z postanowieniami niniejszego regulaminu.</li>
        </ol>
        <h5>&sect; 3 Ochrona własności intelektualnej</h5>
        <ol>
          <li>Wszystkie treści cyfrowe oferowane przez Prowadzącą stanowią jej własność i są przedmiotem ochrony prawa polskiego i międzynarodowego, w szczeg&oacute;lności przepis&oacute;w ustawy z 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</li>
          <li>Z momentem zakupu online treści cyfrowej, na użytkownika nie przechodzą żadne prawa autorskie, tak osobiste jak majątkowe.</li>
          <li>Bez zgody Prowadzącej zabronione jest rozpowszechniane treści cyfrowych uzyskanych za pomocą zakupu online.</li>
          <li>Użytkownik nie jest uprawniony w szczeg&oacute;lności do
            <ol type="a">
              <li>ingerencji w treść treści cyfrowej</li>
              <li>usuwania zabezpieczeń treści cyfrowej, jak np. znaki wodne</li>
              <li>zwielokrotniania treści cyfrowej w formie elektronicznej lub tradycyjnej</li>
              <li>udostępniania treści cyfrowej w internecie, w tym na platformach P2P jak sieci torrent lub inne</li>
              <li>wykorzystywania treści cyfrowej do cel&oacute;w komercyjnych, modyfikacji, zar&oacute;wno w całości jak i w części.</li>
            </ol>
          </li>
          <li>Rozpowszechnianie utworu bez uprawnienia stanowi przestępstwo opisane w art. 116 ustawy o prawie autorskim i prawach pokrewnych i każdy wykryty przypadek takiego działania zostanie zgłoszony do właściwego organu. Dotyczy to także innych przestępstw uregulowanych w powołanej ustawie, jak r&oacute;wnież innych przepisach, jeśli bezprawne rozpowszechnianie treści cyfrowych wyczerpywać będzie znamiona tych przestępstw.</li>
          <li>Użytkownik ma prawo korzystać z treści cyfrowej tylko na własny użytek. Postanowienie to działa bez uszczerbku na uprawnienie opisane w &sect; 2 ust. 4 regulaminu.</li>
          <li>Użytkownik ma prawo do wydruku treści cyfrowej nadającej się do tego, jak np. e-book. Wydrukowana treść cyfrowa jednakże pozostaje pod ochroną prawa własności intelektualnej, w szczeg&oacute;lności w zakresie zakazu kopiowania i rozpowszechniania, przekazywania osobom trzecim do korzystania.</li>
          <li>Użytkownik ma prawo korzystać, w zgodzie z postanowieniami powyższymi, z zakupionych online treści cyfrowych za pośrednictwem r&oacute;żnych urządzeń elektronicznych, o ile nie prowadzi to do zmiany w strukturze treści cyfrowej.</li>
          <li>W przypadku gdy oferowane treści cyfrowe stanowią opracowania tekst&oacute;w zewnętrznych, og&oacute;lnodostępnych (jak np. zbiory zadań Centralnych lub Obwodowych Komisji Egzaminacyjnych), przedmiotem praw autorskich Prowadzącej są dokonane przez nią analizy powyższych tekst&oacute;w w każdej postaci, a w szczeg&oacute;lności komentarzy, grafik, opracowań.</li>
        </ol>
        <h5>&sect; 4 Zakładanie konta</h5>
        <ol>
          <li>Użytkownik chcący korzystać z funkcjonalności serwisu powinien zarejestrować się w serwisie poprzez utworzenie konta.</li>
          <li>Użytkownik zakładający konto powinien być osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych.</li>
        </ol>
        <h5>&sect; 5 Procedura zakupu online treści cyfrowej</h5>
        <ol>
          <li>Prowadząca umożliwia w ramach serwisu zakup online treści cyfrowych, w szczeg&oacute;lności w postaci uczestnictwa w kursie online lub dostarczenia e-booka.</li>
          <li>Informacje o właściwościach oferowanej treści cyfrowej dostępne są każdorazowo z poziomu serwisu.</li>
          <li>Zakup online możliwy jest wyłącznie dla użytkownika, kt&oacute;ry zarejestrował w serwisie swoje konto i kt&oacute;ry podczas procedury zakupu pozostaje zalogowany.</li>
          <li>Treści cyfrowe oferowane w serwisie mogą ulegać zmianie, modyfikacji, ich dostępność może być ograniczona czasowo lub ilościowo, wedle uznania Prowadzącej.</li>
          <li>Płatności za treści cyfrowe dokonywane są za pomocą platformy PayU. Regulaminy funkcjonowania tej platformy dostępne są dla użytkownika pod adresem https://poland.payu.com/dokumenty-prawne-do-pobrania/. Dokonując zakupu online użytkownik potwierdza także zapoznanie się z tymi regulaminami.</li>
          <li>Cena za oferowane treści cyfrowe wyrażona jest w złotych (PLN).</li>
          <li>Prowadząca uprawniona jest do oferowania kod&oacute;w rabatowych, kt&oacute;re pomniejszają ostateczną cenę zakupu treści cyfrowej.</li>
          <li>Spos&oacute;b, terminy i warunki korzystania z kod&oacute;w rabatowych określane są każdorazowo przez Prowadzącą w ramach informacji o tych kodach umieszczonych w serwisie. Pozyskany kod rabatowy przypisany jest na stałe do jednego konta i tylko dane konto może go aktywować.</li>
          <li>Po wyborze żądanej treści cyfrowej użytkownik powinien postępować zgodnie ze wskaz&oacute;wkami widocznymi na stronie internetowej, kt&oacute;ra przeprowadzi go przez proces zakupu.</li>
          <li>Ostateczna cena zakupu, po uwzględnieniu ewentualnych kod&oacute;w rabatowych, stanowi cenę wiążącą, kt&oacute;rą po zaakceptowaniu warunk&oacute;w sprzedaży i przystąpienia do płatności za pośrednictwem platformy PayU użytkownik zobowiązuje się zapłacić.</li>
          <li>Posiadany kod rabatowy należy podać w przeznaczonym do tego polu podczas procedury zakupu, w przeciwnym wypadku nie zostanie on naliczony, nawet jeżeli użytkownik jest uprawniony do jego wykorzystania. W szczeg&oacute;lności posiadane, a nie wykorzystane kody rabatowe nie podlegają zamianie na inne kody rabatowe czy wymianie na środki pieniężne.</li>
          <li>Po złożeniu dyspozycji zakupu i ukończeniu procesu płatności za pośrednictwem platformy PayU (tj. potwierdzenia przez PayU udanego przelewu ceny) użytkownik otrzyma na ujawniony przez siebie adres e-mail automatycznie wygenerowaną wiadomość z potwierdzeniem złożenia zam&oacute;wienia. Z chwilą wysłania tej wiadomości dochodzi do zawarcia umowy, na mocy kt&oacute;rej użytkownik uzyskuje prawo do korzystania z treści cyfrowej, na warunkach niniejszego regulaminu.</li>
          <li>Brak ukończenia procesu płatności za pośrednictwem PayU i zapłaty ceny zwalnia Prowadzącą z obowiązku realizacji zam&oacute;wienia, o czym użytkownik nie musi zostać poinformowany odrębną wiadomością.</li>
          <li>W każdym przypadku, gdy Prowadząca nie jest w stanie zrealizować dokonanego zam&oacute;wienia, użytkownik bez zbędnej zwłoki zostanie o tym poinformowany wiadomością e-mail. W takim przypadku najp&oacute;źniej w ciągu 14 dni od daty zakupu Prowadząca zwr&oacute;ci całą kwotę zapłaconą przez użytkownika, na rachunek z kt&oacute;rego dokonano transakcji pierwotnej.</li>
          <li>Przyczyny, dla kt&oacute;rych Prowadząca nie jest w stanie zrealizować zam&oacute;wienia nie podlegają ujawnieniu użytkownikowi.</li>
          <li>Prowadząca umożliwia wystawienie faktury do każdego zam&oacute;wienia. W celu uzyskania faktury użytkownik powinien w toku dokonywania zam&oacute;wienia zaznaczyć odpowiednią rubrykę i wprowadzić dane umożliwiające wystawienie faktury. Taka faktura w postaci elektronicznej jest następnie &ndash; po pomyślnym dokonaniu zapłaty &ndash; wysyłana e-mailem na wskazany adres użytkownika.</li>
          <li>Prowadząca jest podmiotem korzystającym ze zwolnienia od VAT na podstawie art. 43 ust. 1 ustawy o podatku od towar&oacute;w i usług, wobec czego ceny płacone za treści cyfrowe przez użytkownika ujawnione zostaną na fakturze z VAT zwolnionym.</li>
        </ol>
        <h5>&sect; 6 Spos&oacute;b dostępu do zakupionej treści cyfrowej i warunki techniczne</h5>
        <ol>
          <li>Po dokonaniu zakupu online treści cyfrowej, użytkownik poprzez dostęp do swojego konta może przenieść się do strony internetowej oznaczonej zakładką &bdquo;Moje kursy&rdquo;.</li>
          <li>W sekcji &bdquo;Moje kursy&rdquo; użytkownikowi udostępnione zostaną zakupione treści cyfrowe. Spos&oacute;b korzystania z treści cyfrowej uzależniony jest od charakteru zakupionej treści cyfrowej i nie odbiega od standardowo przyjętego w sieci internet sposobu korzystania z plik&oacute;w i program&oacute;w komputerowych.</li>
          <li>Dostęp do treści cyfrowych oferowanych w postaci plik&oacute;w tekstowych (jak np. e-booki) wymaga zainstalowanego oprogramowania pozwalającego na odczyt plik&oacute;w w postaci *.pdf, takich jak Adobe Acrobat Reader.</li>
          <li>Dostęp do lekcji online na żywo (live) wymaga skorzystania z aplikacji umożliwiających transmisję głosu i obrazu.</li>
          <li>Treści cyfrowe zabezpieczone mogą być znakiem wodnym lub innym powszechnie stosowanym sposobem zabezpieczania treści w sieci internet.</li>
          <li>Szczeg&oacute;łowe warunki korzystania z danej treści cyfrowej, jak np. harmonogram webinari&oacute;w (transmisji) w przypadku kurs&oacute;w online na żywo (live), godziny transmisji, określane są na podstronie ofertowej każdej treści cyfrowej. Informacje dotyczące takich zajęć pojawią się na koncie użytkownika do 24 godzin od rozpoczęcia webinaru.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik w zakładce &bdquo;Moje kursy&rdquo; uzyska link zezwalający na dołączenie do transmisji zgodnie z zaplanowanym harmonogramem.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik po upływie terminu zaplanowanej transmisji uzyska dostęp do nagrania tego materiału w formie offline, z kt&oacute;rego może korzystać jak z każdej innej treści cyfrowej. Okres dostępu do takiej treści cyfrowej może być ograniczony czasowo, o czym użytkownik zostanie poinformowany w zakładce &bdquo;Moje kursy&rdquo; lub &bdquo;Powiadomienia&rdquo;.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca nie ponosi odpowiedzialności za przerwy w transmisji czy spadki w jej jakości spowodowane przepustowością sieci po stronie użytkownika.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, gdy Prowadząca z jakichkolwiek powod&oacute;w, w tym technicznych, zdrowotnych czy prywatnych, nie ma możliwości przeprowadzenia zaplanowanej transmisji, transmisja ta zostanie przeprowadzona w innym dniu, o kt&oacute;rym użytkownik zostanie poinformowany. Decyzja o braku możliwości przeprowadzenia zaplanowanej transmisji jest uznaniowa i użytkownik nie jest uprawniony do jej kwestionowania, ani kreowania z tego tytułu roszczeń wobec Prowadzącej, co pozostaje bez uszczerbku dla poniższych postanowień. Wedle swojego uznania Prowadząca może także w miejsce organizowania zastępczego terminu zaległej transmisji, o kt&oacute;rej mowa powyżej, przygotować materiał filmowy offline i udostępnić go w postaci nagrania w zakładce &bdquo;Moje kursy&rdquo; konta użytkownika. Przeprowadzenie transmisji zastępczej lub udostępnienie materiału offline każdorazowo nastąpi najp&oacute;źniej do 31 kwietnia danego roku, lub roku następnego jeśli zdarzenie ma miejsce po 31 kwietnia danego roku &ndash; za każdym razem tak, aby użytkownik posiadał komplet materiał&oacute;w przygotowujących do nauki jeszcze przed terminem matury.</li>
          <li>Z powodu nieprzeprowadzenia transmisji zgodnie z harmonogramem użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej, chyba że nie wywiąże się ona z przygotowania transmisji zastępczej lub nie udostępni materiału offline, w spos&oacute;b i w terminie o kt&oacute;rym mowa powyżej.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca jest nadto uprawniona do zmiany godziny transmisji w obrębie tego samego dnia. Z tego tytułu użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej.</li>
          <li>W przypadku gdyby kt&oacute;rakolwiek treść cyfrowa zawierała błąd obliczeniowy, tekstowy, skutkujący wadliwością merytoryczną treści cyfrowej, Prowadząca zobowiązuje się bez zbędnej zwłoki do zaktualizowania treści cyfrowej, poinformowania użytkownika o dostrzeżonym błędzie i udostępnienia treści cyfrowej o prawidłowej treści.</li>
          <li>Treści cyfrowe zakupione od 31 sierpnia 2021 r. w postaci materiał&oacute;w dostępnych w zakładce &bdquo;Moje kursy&rdquo; wygasają po upływie roku od daty zakupu*. Oznacza to, że z upływem ww. terminu dostęp do tych treści wygasa. *Z zastrzeżeniem sytuacji, w kt&oacute;rych opisie oferowanego kursu wskazana jest inna data dostępu do zakupionego kursu.&nbsp;</li>
        </ol>
        <h5>&sect; 6a Newsletter</h5>
        <ol>
          <li>Użytkownik ma możliwość wyrażenia zgody na otrzymywanie Newslettera poprzez zapisane się na listę mailingową. Prawidłowość świadczenia usługi możliwa jest wyłącznie w przypadku podania rzeczywistego adresu e-mail.</li>
          <li>Użytkownik wyrażając zgodę na otrzymywanie Newslettera potwierdza chęć otrzymywania informacji handlowej od Prowadzącej. Informacja handlowa zawiera informacje w przedmiocie bieżącej działalności Prowadzącej, oferowanych usług, produktów, promocji czy treści cyfrowych.</li>
          <li>Prowadząca uprawniona jest do udostępnienia użytkownikom zapisanym do Newslettera dodatkowych treści cyfrowych, dostępnych na koncie użytkownika. Dostęp do tych treści będzie darmowy dla każdego użytkownika zapisanego do usługi Newslettera.</li>
          <li>Wypisanie się z Newslettera możliwe jest w każdej chwili za pośrednictwem właściwej opcji w panelu w koncie użytkownika oraz po kliknięciu w link znajdujący się w stopce każdego przesyłanego Newslettera.</li>
          <li>Przetwarzanie danych użytkownika do celów Newslettera odbywa się zgodnie z informacjami opisanymi w Polityce Prywatności</li>
        </ol>
        <h5>&sect; 7 Dostarczanie treści cyfrowych przed upływem terminu do odstąpienia od umowy</h5>
        <ol>
          <li>Dokonując zakupu online, użytkownik wyraża zgodę na spełnienie świadczenia przed upływem terminu do odstąpienia od umowy.</li>
          <li>Z chwilą udzielenia zgody, o kt&oacute;rej mowa w ust. 1 powyżej, użytkownik traci prawo odstąpienia od umowy. Prowadząca bez zbędnej zwłoki prześle na adres e-mail ucznia podany do rejestracji w serwisie potwierdzenie zakupu online oraz utratę prawa odstąpienia od umowy.</li>
        </ol>
        <h5>&sect; 8 Prawo odstąpienia</h5>
        <ol>
          <li>Dokonując zakupu online, uczniowi przysługuje prawo odstąpienia od umowy w terminie 14 dni bez podania przyczyny. Termin na odstąpienie od umowy wygasa po upływie 14 dni od daty zawarcia umowy.</li>
          <li>Aby skorzystać z prawa do odstąpienia od umowy użytkownik powinien poinformować Prowadzącą w drodze jednoznacznego oświadczenia, przesłanego drogą elektroniczną lub tradycyjną.</li>
          <li>Oświadczenie, o kt&oacute;rym mowa powyżej, może być złożone w formie wypełnione wzoru formularza, stanowiącego załącznik nr 2 do ustawy o prawach konsumenta.</li>
          <li>Wysłanie informacji dotyczącej wykonania przysługującego prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy skutkuje zachowaniem terminu.</li>
          <li>Skuteczne odstąpienie od umowy skutkować będzie zwrotem wszystkich otrzymanych płatności, nie p&oacute;źniej niż w ciągu 14 dni od dnia otrzymania informacji o wykonaniu prawa odstąpienia. Zwrot płatności nastąpi przy użyciu takiego samego sposobu płatności jak transakcja pierwotna. Z tytułu zwrotu użytkownik nie ponosi dodatkowych koszt&oacute;w.</li>
          <li>Akceptując regulamin, użytkownik potwierdza, że został poinformowany, że zgodnie z art. 38 pkt 13 ustawy o prawach konsumenta, w przypadku umowy o dostarczanie treści cyfrowych, kt&oacute;re nie są zapisane na nośniku materialnym, prawo do odstąpienia nie przysługuje jeśli spełnianie świadczenia nastąpiło na wyraźną zgodę konsumenta przed<br />upływem terminu do odstąpienia umowy i po poinformowaniu o utracie prawa do odstąpienia od umowy.</li>
          <li>W ślad za powyższym, użytkownik, kt&oacute;ry dokonał zakupu online i zaakceptował rozpoczęcie świadczenia przed upływem terminu do odstąpienia od umowy, nie jest uprawniony do odstąpienia od zakupu e-booka lub kursu online. Po wykonaniu świadczenia usługi i uruchomieniu dostępu do kursu nie ma możliwości zamiany zakupionego kursu na inny spośr&oacute;d oferty.</li>
        </ol>
        <h5>&sect; 9 Dane osobowe i prywatność</h5>
        <p>Administratorem danych osobowych ucznia jest Prowadząca, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.&nbsp;Prowadząca przetwarza dane osobowe podane przez ucznia w procesie rejestracji konta w celu świadczenia usług drogą elektroniczną. Więcej informacji w tym zakresie znajduje się w Polityce Prywatności, dostępnej z poziomu serwisu.</p>
        <h5>&sect; 10 Reklamacje</h5>
        <ol>
          <li>Wszelkie reklamacje dotyczące korzystania z serwisu i oferowanych za jego pośrednictwem funkcjonalności należy przesyłać na adres Prowadzącej, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.</li>
          <li>Reklamacja powinna zawierać co najmniej:
            <ol type="a">
              <li>oznaczenie ucznia składającego reklamację, pozwalające na identyfikację;</li>
              <li>wskazanie funkcjonalności, z kt&oacute;rą wiąże się reklamacja;</li>
              <li>wskazanie i uzasadnienie zarzut&oacute;w będących podstawą reklamacji;</li>
              <li>wskazanie zakresu żądania ucznia składającego reklamację;</li>
            </ol>
          </li>
          <li>Jeśli reklamacja nie zawiera danych, o kt&oacute;rych mowa w ust. 2, Prowadząca, w terminie 7 dni od jej doręczenia, może wezwać Ucznia do uzupełnienia danych.</li>
          <li>Rozpatrzenie reklamacji nastąpi w ciągu 14 dni od dnia doręczenia reklamacji. W razie wezwania do uzupełnienia reklamacji, termin ten liczy się od dnia doręczenia reklamacji uzupełnionej.</li>
        </ol>

        <h5>&sect; 11 Usługa subskrypcyjna ChemMystery</h5>
        <ol>
          <li>Prowadząca umożliwia Użytkownikowi udział w usłudze subskrypcyjnej pod nazwą „ChemMystery” (dalej: „ChemMystery”)</li>
          <li>W ramach subskrypcji ChemMystery Użytkownik otrzymywał będzie co miesiąc dostęp do dodatkowych materiałów, treści cyfrowych, za pośrednictwem swojego Konta Użytkownika.</li>
          <li>Dodatkowo, w okresie trwania aktywnej subskrypcji ChemMystery Użytkownik uprawniony będzie do zadania w ciągu każdego miesiąca kalendarzowego – za pośrednictwem przygotowanego formularza – trzech indywidualnych pytań z zakresu nauki Chemii, na które odpowiadać będzie certyfikowany egzaminator dowolnej Okręgowej Komisji Egzaminacyjnej (dalej: „OKE”), wpisany do ewidencji egzaminatorów OKE.</li>
          <li>Do usługi ChemMystery znajdują zastosowanie pozostałe zapisy regulaminu, w tym w szczególności § 2, 3, 8 oraz § 10.</li>
          <li>Przystąpienie do ChemMystery tworzy automatycznie Konto Użytkownika, w razie gdy osoba dokonująca zakupu nie posiadała go uprzednio w serwisie.</li>
        </ol>
        <h5>&sect; 12 Zasady funkcjonowania usługi subskrypcyjnej ChemMystery. Dodatkowe materiały oraz Pytanie do Egzaminatora.</h5>
        <ol>
          <li>Zakupując dostęp do ChemMystery Użytkownik będzie otrzymywał dodatkowe, niezwiązane z pozostałymi treściami cyfrowymi oferowanymi przez Prowadzącą, materiały dostępne na Koncie Użytkownika w odrębnej zakładce ChemMystery. Materiały te mają na celu polepszenie przygotowania Użytkownika do uczestnictwa w egzaminie maturalnym i stanowią uzupełnienie wiedzy przekazywanej Użytkownikowi w ramach pozostałych treści cyfrowych.</li>
          <li>Każdego miesiąca na Koncie Użytkownika w ramach ChemMystery pojawią się co najmniej trzy treści cyfrowe o tematyce Chemii. Treści te mogą stanowić dodatkowe lekcje wideo, quiz, artykuł, test, autorskie opracowania lub materiały innego rodzaju wedle uznania Prowadzącej. Wybór charakteru każdego materiału należy do Prowadzącej i Użytkownik nie posiada roszczenia co do oczekiwania konkretnego rodzaju treści cyfrowej.</li>
          <li>W ramach ChemMystery Użytkownik, za pośrednictwem dedykowanego formularza dostępnego na jego Koncie Użytkownika, ma możliwość zadania w ciągu miesiąca kalendarzowego trzech „Pytań do Egzaminatora”, o których mowa w § 11 ust. 3 regulaminu.</li>
          <li>W ramach formularza Pytań do Egzaminatora, Użytkownik ma możliwość przedstawienia opisowego swojego problemu/pytania, jak również załączenia dodatkowo pliku graficznego lub tekstowego.</li>
          <li>Pytania do Egzaminatora mają na celu przygotowanie Użytkownika do egzaminu maturalnego i powinny odnosić się do tematyki związanej z nauką chemii, w szczególności zaś treści, przebiegu oraz organizacji samego egzaminu. W szczególności sugerowany – ale nie wyłączny – zakres pytań w ramach Pytań do Egzaminatora może przybrać postać pytań:
            <ol>
              <li>„czy moja odpowiedź zostałaby uznana na egzaminie?”</li>
              <li>„czy mój zapis jest prawidłowy?”</li>
              <li>„czy za takie obliczenia dostanę punkt?”</li>
              <li>„czy takie coś uznają jako poprawne?”</li>
              <li>„gdzie mam błąd w tym zadaniu?”</li>
              <li>„dlaczego nie wychodzi mi poprawna odpowiedź?”</li>
              <li>„jak rozwiązać to zadanie?”</li>
            </ol>
          </li>
          <li>Po złożeniu pytania za pośrednictwem formularza egzaminator, o którym mowa w § 11 ust. 3 regulaminu, przygotuje odpowiedź na pytanie w ciągu 72 godzin. Do czasu tego nie wlicza się dni wolnych od pracy oraz świąt. Odpowiedź na pytanie Użytkownik uzyska za pośrednictwem wiadomości na swoim Koncie Użytkownika.</li>
          <li>W przypadku okoliczności losowych uniemożliwiających udzielenie odpowiedzi w określonym powyżej przedziale czasowym, Użytkownik zostanie powiadomiony odrębną wiadomością, w której określony zostanie także przewidywany czas udzielenia odpowiedzi na zadane pytanie. W szczególnie uzasadnionych wypadkach Prowadząca uprawniona jest do poinformowania wszystkich Użytkowników o wydłużeniu czasu odpowiedzi na zadane pytania poprzez informację zamieszczoną na stronie głównej serwisu www.chemmaster.pl</li>
          <li>Maksymalny czas uzyskania odpowiedzi na zadane pytanie wynosi 30 dni, z zastrzeżeniem, że nie dotyczy to sytuacji, w których odpowiedź miałaby zostać udzielona po dacie egzaminu maturalnego z chemii w danym roku szkolnym. Wówczas ostatnim dniem, w którym udzielona zostanie odpowiedź jest dzień poprzedzający dzień egzaminu maturalnego z chemii, chyba że pytanie zostało złożone na mniej niż 72 godziny przed dniem egzaminu.</li>
          <li>Udzielona na zadane pytanie odpowiedź odzwierciedla najlepszą wiedzę egzaminatora. Prowadząca ani egzaminator nie ponoszą odpowiedzialności za szkody lub krzywdę, jakiej doznać może Użytkownik w wyniku powoływania się na uzyskaną odpowiedź względem podmiotów trzecich.</li>
          <li>W przypadku, w którym Użytkownik powziął uzasadnioną wątpliwość co do wiarygodności lub rzetelności uzyskanej odpowiedzi, uprawniony jest do zwrócenia się z reklamacją odpowiednio do zapisów § 10 regulaminu. Reklamacja odpowiedzi uzyskanej od egzaminatora powinna być w formie e-mail na adres kontakt@chemmaster.pl i zawierać opis sytuacji w sposób umożliwiający rzetelne i kompleksowe ustosunkowanie się Prowadzącej. W przypadku uznania reklamacji za zasadną Użytkownik uzyska prawo do dodatkowego Pytania do Egzaminatora, ponad podstawowy limit trzech Pytań do Egzaminatora w miesiącu.</li>
          <li>W przypadku, w którym zadane pytanie wykraczałoby poza tematykę nauki chemii, zawierałoby treści wulgarne, obraźliwe, niestosowne lub z innych przyczyn nie nadawałoby się do udzielenia odpowiedzi, Prowadząca lub egzaminator uprawnieni są do odrzucenia takiego pytania bez udzielania odpowiedzi. Użytkownik zachowuje wówczas prawo do zadania kolejnego pytania, tak jakby odrzuconego pytania nie zadano.</li>
        </ol>
        <h5>&sect; 13 Warunki przystąpienia do usługi subskrypcyjnej ChemMystery</h5>
        <ol>
          <li>Usługa ChemMystery powiązana jest z obowiązującym rokiem szkolnym i może być aktywna na Koncie Użytkownika od września danego roku, do maja roku następnego.</li>
          <li>ChemMystery oferowane jest w dwóch postaciach – subskrypcji 3-miesięcznej oraz subskrypcji 9-miesięcznej</li>
          <li>Subskrypcja 9-miesięczna obejmuje dostęp do ChemMystery na przestrzeni całego roku szkolnego, zgodnie z ust. 1 powyżej</li>
          <li>Subskrypcja 3-miesięczna obejmuje dostęp do ChemMystery w jednym z trzech okresów cząstkowych, tj. wrzesień – listopad, grudzień – luty lub marzec – maj.</li>
          <li>Przystąpienie do ChemMystery w trakcie trwania roku szkolnego powoduje uzyskanie dostępu do treści cyfrowych zamieszczonych w tej usłudze zgodnie z postanowieniami § 12 regulaminu, odpowiednio do okresu trwania zakupionej subskrypcji. Treści te będą dostępne na Koncie Użytkownika do końca trwania zakupionego okresu subskrypcji.</li>
          <li>Przystąpienie do ChemMystery w trakcie trwania roku szkolnego do którejkolwiek z wersji subskrypcji nie uprawnia Użytkownika do skorzystania z „Pytań do Egzaminatora” za przeszłe miesiące w okresie subskrypcji.</li>
          <li>W zgodzie z powyższym zapisem, przystąpienie do subskrypcji dowolnego dnia uprawnia do zadania trzech Pytań do Egzaminatora w odniesieniu do miesiąca, w którym dokonano zakupu subskrypcji.</li>
          <li>Pula trzech pytań dotyczy każdorazowo jednego miesiąca kalendarzowego. Niewykorzystanie możliwości zadania trzech pytań nie powoduje zwiększenia puli pytań w miesiącach następnych.</li>
          <li>Cena za każdą z subskrypcji ogłaszana jest przez Prowadzącą każdorazowo na podstronie ofertowej. Do procedury zakupu zastosowanie znajduje odpowiednio § 5 regulaminu.</li>
        </ol>
        <h5>&sect; 14 Postanowienia końcowe</h5>
        <ol>
          <li>Wszelkie nieuregulowane w niniejszym regulaminie kwestie rządzą się przepisami prawa polskiego, a zwłaszcza kodeksu cywilnego, oraz ustaw &ndash; o prawach konsumenta oraz o prawie autorskim i prawach pokrewnych.</li>
          <li>Regulamin w niniejszej wersji obowiązuje od 31 sierpnia 2021 r.</li>
          <li>Prowadząca zastrzega sobie prawo do zmiany regulaminu z ważnych przyczyn. W szczeg&oacute;lności zmiana może nastąpić w razie konieczności dostosowania regulaminu do wymog&oacute;w znowelizowanego prawa, technicznych względ&oacute;w funkcjonowania serwisu lub modyfikacji zakresu funkcjonowania serwisu.</li>
          <li>Zmiana regulaminu wchodzi w życie w stosunku do użytkownika w terminie wskazanym przez Prowadzącą w wiadomości skierowanej do użytkownika, przesłanej albo za pośrednictwem wiadomości e-mail albo dostępnej po zalogowaniu się do konta użytkownika. Zakupy online dokonane przed wejściem w życie zmiany regulaminu rządzą się zasadami określonymi w regulaminie w brzmieniu uprzednim.</li>
          <li>Prowadząca ma obowiązek dostarczania treści cyfrowych bez wad.</li>
          <li>Zapłata ceny za oferowane treści cyfrowe wyczerpuje zobowiązania użytkownika, dalsze korzystanie z zakupionej treści cyfrowej stanowi jego uprawnienie.</li>
          <li>W przypadku unieważnienia kt&oacute;regokolwiek postanowienia niniejszego regulaminu mocą orzeczenia sądu, pozostałe jego postanowienia pozostają w mocy i są obowiązujące.</li>
          <li>Treść regulaminu jest udostępniona w spos&oacute;b stały i nieodpłatnie w serwisie w taki spos&oacute;b, aby użytkownik w każdym czasie m&oacute;gł regulamin odtwarzać i zachowywać jego treść. Zabrania się kopiowania regulaminu i wykorzystywania go do cel&oacute;w prywatnych lub komercyjnych bez zgody Prowadzącej.</li>
        </ol>
      </b-card-text>
    </b-card>
    <b-card sub-title="Wersja obowiązująca od 05.01.2022r.">
      <b-card-text>
        <h3><strong>Regulamin</strong></h3>
        <h5>&sect; 1 Definicje</h5>
        <ol>
          <li>Ilekroć w niniejszym regulaminie używa się poniższych zwrot&oacute;w, oznaczają one:</li>
        </ol>
        <p>a) Prowadząca &ndash; Sonia Radosz, prowadząca działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl</p>
        <p>b) Użytkownik &ndash; osoba korzystająca z serwisu będąca osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych</p>
        <p>c) serwis &ndash; witryna www.chemmaster.pl</p>
        <p>d) e-book &ndash; przygotowane w formie pliku komputerowego opracowania obejmujące teksty oraz grafiki</p>
        <p>e) kurs online &ndash; prowadzony przez Prowadzącą za pośrednictwem środk&oacute;w komunikacji elektronicznej umożliwiających transmisję obrazu i głosu cykl prezentacji, podczas kt&oacute;rych przekazuje ona wiedzę i umiejętności w zakresie oferowanego produktu</p>
        <p>f) treści cyfrowe &ndash; oferowane przez Prowadzącą do sprzedaży za pośrednictwem serwisu usługi i utwory, nie będące utrwalone na nośniku materialnym, do kt&oacute;rych użytkownik ma dostęp za pomocą konta użytkownika.</p>
        <p>g) zakup online &ndash; procedura zamawiania treści cyfrowych oferowanych przez Prowadzącą za pośrednictwem serwisu</p>
        <p>h) konto, konto użytkownika &ndash; indywidualne konto użytkownika umożliwiające dostęp do zakupionych materiał&oacute;w.</p>
        <h5>&sect; 2 Postanowienia og&oacute;lne</h5>
        <ol>
          <li>Postanowienia niniejszego regulaminu normują zasady oraz warunki korzystania z serwisu oraz funkcjonalności na nim oferowanych, w tym zakupu online.</li>
          <li>Korzystając z serwisu, a w szczeg&oacute;lności zakładając konto, użytkownik zgadza się przestrzegać niniejszego regulaminu i jego postanowień.</li>
          <li>Użytkownik zobowiązuje się do korzystania z konta i zakupionych treści online wyłącznie osobiście, zabronione jest wsp&oacute;łdzielenie konta i zakupionych treści cyfrowych z osobą trzecią.</li>
          <li>Użytkownik jest jednakże uprawniony do przekazania dostępu do konta oraz zakupionej online treści cyfrowej jednemu zstępnemu. Użytkownik zobowiązuje się, pod rygorem odpowiedzialności za działania zstępnego jak za swoje, do zapoznania zstępnego z niniejszym regulaminem oraz nadzorem na zstępnym w zakresie zgodności korzystania z zakupionych online treści cyfrowych z postanowieniami niniejszego regulaminu.</li>
        </ol>
        <h5>&sect; 3 Ochrona własności intelektualnej</h5>
        <ol>
          <li>Wszystkie treści cyfrowe oferowane przez Prowadzącą stanowią jej własność i są przedmiotem ochrony prawa polskiego i międzynarodowego, w szczeg&oacute;lności przepis&oacute;w ustawy z 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</li>
          <li>Z momentem zakupu online treści cyfrowej, na użytkownika nie przechodzą żadne prawa autorskie, tak osobiste jak majątkowe.</li>
          <li>Bez zgody Prowadzącej zabronione jest rozpowszechniane treści cyfrowych uzyskanych za pomocą zakupu online.</li>
          <li>Użytkownik nie jest uprawniony w szczeg&oacute;lności do
            <ol type="a">
              <li>ingerencji w treść treści cyfrowej</li>
              <li>usuwania zabezpieczeń treści cyfrowej, jak np. znaki wodne</li>
              <li>zwielokrotniania treści cyfrowej w formie elektronicznej lub tradycyjnej</li>
              <li>udostępniania treści cyfrowej w internecie, w tym na platformach P2P jak sieci torrent lub inne</li>
              <li>wykorzystywania treści cyfrowej do cel&oacute;w komercyjnych, modyfikacji, zar&oacute;wno w całości jak i w części.</li>
            </ol>
          </li>
          <li>Rozpowszechnianie utworu bez uprawnienia stanowi przestępstwo opisane w art. 116 ustawy o prawie autorskim i prawach pokrewnych i każdy wykryty przypadek takiego działania zostanie zgłoszony do właściwego organu. Dotyczy to także innych przestępstw uregulowanych w powołanej ustawie, jak r&oacute;wnież innych przepisach, jeśli bezprawne rozpowszechnianie treści cyfrowych wyczerpywać będzie znamiona tych przestępstw.</li>
          <li>Użytkownik ma prawo korzystać z treści cyfrowej tylko na własny użytek. Postanowienie to działa bez uszczerbku na uprawnienie opisane w &sect; 2 ust. 4 regulaminu.</li>
          <li>Użytkownik ma prawo do wydruku treści cyfrowej nadającej się do tego, jak np. e-book. Wydrukowana treść cyfrowa jednakże pozostaje pod ochroną prawa własności intelektualnej, w szczeg&oacute;lności w zakresie zakazu kopiowania i rozpowszechniania, przekazywania osobom trzecim do korzystania.</li>
          <li>Użytkownik ma prawo korzystać, w zgodzie z postanowieniami powyższymi, z zakupionych online treści cyfrowych za pośrednictwem r&oacute;żnych urządzeń elektronicznych, o ile nie prowadzi to do zmiany w strukturze treści cyfrowej.</li>
          <li>W przypadku gdy oferowane treści cyfrowe stanowią opracowania tekst&oacute;w zewnętrznych, og&oacute;lnodostępnych (jak np. zbiory zadań Centralnych lub Obwodowych Komisji Egzaminacyjnych), przedmiotem praw autorskich Prowadzącej są dokonane przez nią analizy powyższych tekst&oacute;w w każdej postaci, a w szczeg&oacute;lności komentarzy, grafik, opracowań.</li>
        </ol>
        <h5>&sect; 4 Zakładanie konta</h5>
        <ol>
          <li>Użytkownik chcący korzystać z funkcjonalności serwisu powinien zarejestrować się w serwisie poprzez utworzenie konta.</li>
          <li>Użytkownik zakładający konto powinien być osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych.</li>
        </ol>
        <h5>&sect; 5 Procedura zakupu online treści cyfrowej</h5>
        <ol>
          <li>Prowadząca umożliwia w ramach serwisu zakup online treści cyfrowych, w szczeg&oacute;lności w postaci uczestnictwa w kursie online lub dostarczenia e-booka.</li>
          <li>Informacje o właściwościach oferowanej treści cyfrowej dostępne są każdorazowo z poziomu serwisu.</li>
          <li>Zakup online możliwy jest wyłącznie dla użytkownika, kt&oacute;ry zarejestrował w serwisie swoje konto i kt&oacute;ry podczas procedury zakupu pozostaje zalogowany.</li>
          <li>Treści cyfrowe oferowane w serwisie mogą ulegać zmianie, modyfikacji, ich dostępność może być ograniczona czasowo lub ilościowo, wedle uznania Prowadzącej.</li>
          <li>Płatności za treści cyfrowe dokonywane są za pomocą platformy PayU. Regulaminy funkcjonowania tej platformy dostępne są dla użytkownika pod adresem https://poland.payu.com/dokumenty-prawne-do-pobrania/. Dokonując zakupu online użytkownik potwierdza także zapoznanie się z tymi regulaminami.</li>
          <li>Cena za oferowane treści cyfrowe wyrażona jest w złotych (PLN).</li>
          <li>Prowadząca uprawniona jest do oferowania kod&oacute;w rabatowych, kt&oacute;re pomniejszają ostateczną cenę zakupu treści cyfrowej.</li>
          <li>Spos&oacute;b, terminy i warunki korzystania z kod&oacute;w rabatowych określane są każdorazowo przez Prowadzącą w ramach informacji o tych kodach umieszczonych w serwisie. Pozyskany kod rabatowy przypisany jest na stałe do jednego konta i tylko dane konto może go aktywować.</li>
          <li>Po wyborze żądanej treści cyfrowej użytkownik powinien postępować zgodnie ze wskaz&oacute;wkami widocznymi na stronie internetowej, kt&oacute;ra przeprowadzi go przez proces zakupu.</li>
          <li>Ostateczna cena zakupu, po uwzględnieniu ewentualnych kod&oacute;w rabatowych, stanowi cenę wiążącą, kt&oacute;rą po zaakceptowaniu warunk&oacute;w sprzedaży i przystąpienia do płatności za pośrednictwem platformy PayU użytkownik zobowiązuje się zapłacić.</li>
          <li>Posiadany kod rabatowy należy podać w przeznaczonym do tego polu podczas procedury zakupu, w przeciwnym wypadku nie zostanie on naliczony, nawet jeżeli użytkownik jest uprawniony do jego wykorzystania. W szczeg&oacute;lności posiadane, a nie wykorzystane kody rabatowe nie podlegają zamianie na inne kody rabatowe czy wymianie na środki pieniężne.</li>
          <li>Po złożeniu dyspozycji zakupu i ukończeniu procesu płatności za pośrednictwem platformy PayU (tj. potwierdzenia przez PayU udanego przelewu ceny) użytkownik otrzyma na ujawniony przez siebie adres e-mail automatycznie wygenerowaną wiadomość z potwierdzeniem złożenia zam&oacute;wienia. Z chwilą wysłania tej wiadomości dochodzi do zawarcia umowy, na mocy kt&oacute;rej użytkownik uzyskuje prawo do korzystania z treści cyfrowej, na warunkach niniejszego regulaminu.</li>
          <li>Brak ukończenia procesu płatności za pośrednictwem PayU i zapłaty ceny zwalnia Prowadzącą z obowiązku realizacji zam&oacute;wienia, o czym użytkownik nie musi zostać poinformowany odrębną wiadomością.</li>
          <li>W każdym przypadku, gdy Prowadząca nie jest w stanie zrealizować dokonanego zam&oacute;wienia, użytkownik bez zbędnej zwłoki zostanie o tym poinformowany wiadomością e-mail. W takim przypadku najp&oacute;źniej w ciągu 14 dni od daty zakupu Prowadząca zwr&oacute;ci całą kwotę zapłaconą przez użytkownika, na rachunek z kt&oacute;rego dokonano transakcji pierwotnej.</li>
          <li>Przyczyny, dla kt&oacute;rych Prowadząca nie jest w stanie zrealizować zam&oacute;wienia nie podlegają ujawnieniu użytkownikowi.</li>
          <li>Prowadząca umożliwia wystawienie faktury do każdego zam&oacute;wienia. W celu uzyskania faktury użytkownik powinien w toku dokonywania zam&oacute;wienia zaznaczyć odpowiednią rubrykę i wprowadzić dane umożliwiające wystawienie faktury. Taka faktura w postaci elektronicznej jest następnie &ndash; po pomyślnym dokonaniu zapłaty &ndash; wysyłana e-mailem na wskazany adres użytkownika.</li>
          <li>Prowadząca jest podmiotem korzystającym ze zwolnienia od VAT na podstawie art. 43 ust. 1 ustawy o podatku od towar&oacute;w i usług, wobec czego ceny płacone za treści cyfrowe przez użytkownika ujawnione zostaną na fakturze z VAT zwolnionym.</li>
        </ol>
        <h5>&sect; 6 Spos&oacute;b dostępu do zakupionej treści cyfrowej i warunki techniczne</h5>
        <ol>
          <li>Po dokonaniu zakupu online treści cyfrowej, użytkownik poprzez dostęp do swojego konta może przenieść się do strony internetowej oznaczonej zakładką &bdquo;Moje kursy&rdquo;.</li>
          <li>W sekcji &bdquo;Moje kursy&rdquo; użytkownikowi udostępnione zostaną zakupione treści cyfrowe. Spos&oacute;b korzystania z treści cyfrowej uzależniony jest od charakteru zakupionej treści cyfrowej i nie odbiega od standardowo przyjętego w sieci internet sposobu korzystania z plik&oacute;w i program&oacute;w komputerowych.</li>
          <li>Dostęp do treści cyfrowych oferowanych w postaci plik&oacute;w tekstowych (jak np. e-booki) wymaga zainstalowanego oprogramowania pozwalającego na odczyt plik&oacute;w w postaci *.pdf, takich jak Adobe Acrobat Reader.</li>
          <li>Dostęp do lekcji online na żywo (live) wymaga skorzystania z aplikacji umożliwiających transmisję głosu i obrazu.</li>
          <li>Treści cyfrowe zabezpieczone mogą być znakiem wodnym lub innym powszechnie stosowanym sposobem zabezpieczania treści w sieci internet.</li>
          <li>Szczeg&oacute;łowe warunki korzystania z danej treści cyfrowej, jak np. harmonogram webinari&oacute;w (transmisji) w przypadku kurs&oacute;w online na żywo (live), godziny transmisji, określane są na podstronie ofertowej każdej treści cyfrowej. Informacje dotyczące takich zajęć pojawią się na koncie użytkownika do 24 godzin od rozpoczęcia webinaru.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik w zakładce &bdquo;Moje kursy&rdquo; uzyska link zezwalający na dołączenie do transmisji zgodnie z zaplanowanym harmonogramem.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik po upływie terminu zaplanowanej transmisji uzyska dostęp do nagrania tego materiału w formie offline, z kt&oacute;rego może korzystać jak z każdej innej treści cyfrowej. Okres dostępu do takiej treści cyfrowej może być ograniczony czasowo, o czym użytkownik zostanie poinformowany w zakładce &bdquo;Moje kursy&rdquo; lub &bdquo;Powiadomienia&rdquo;.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca nie ponosi odpowiedzialności za przerwy w transmisji czy spadki w jej jakości spowodowane przepustowością sieci po stronie użytkownika.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, gdy Prowadząca z jakichkolwiek powod&oacute;w, w tym technicznych, zdrowotnych czy prywatnych, nie ma możliwości przeprowadzenia zaplanowanej transmisji, transmisja ta zostanie przeprowadzona w innym dniu, o kt&oacute;rym użytkownik zostanie poinformowany. Decyzja o braku możliwości przeprowadzenia zaplanowanej transmisji jest uznaniowa i użytkownik nie jest uprawniony do jej kwestionowania, ani kreowania z tego tytułu roszczeń wobec Prowadzącej, co pozostaje bez uszczerbku dla poniższych postanowień. Wedle swojego uznania Prowadząca może także w miejsce organizowania zastępczego terminu zaległej transmisji, o kt&oacute;rej mowa powyżej, przygotować materiał filmowy offline i udostępnić go w postaci nagrania w zakładce &bdquo;Moje kursy&rdquo; konta użytkownika. Przeprowadzenie transmisji zastępczej lub udostępnienie materiału offline każdorazowo nastąpi najp&oacute;źniej do 31 kwietnia danego roku, lub roku następnego jeśli zdarzenie ma miejsce po 31 kwietnia danego roku &ndash; za każdym razem tak, aby użytkownik posiadał komplet materiał&oacute;w przygotowujących do nauki jeszcze przed terminem matury.</li>
          <li>Z powodu nieprzeprowadzenia transmisji zgodnie z harmonogramem użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej, chyba że nie wywiąże się ona z przygotowania transmisji zastępczej lub nie udostępni materiału offline, w spos&oacute;b i w terminie o kt&oacute;rym mowa powyżej.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca jest nadto uprawniona do zmiany godziny transmisji w obrębie tego samego dnia. Z tego tytułu użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej.</li>
          <li>W przypadku gdyby kt&oacute;rakolwiek treść cyfrowa zawierała błąd obliczeniowy, tekstowy, skutkujący wadliwością merytoryczną treści cyfrowej, Prowadząca zobowiązuje się bez zbędnej zwłoki do zaktualizowania treści cyfrowej, poinformowania użytkownika o dostrzeżonym błędzie i udostępnienia treści cyfrowej o prawidłowej treści.</li>
          <li>Treści cyfrowe zakupione od 31 sierpnia 2021 r. w postaci materiał&oacute;w dostępnych w zakładce &bdquo;Moje kursy&rdquo; wygasają po upływie roku od daty zakupu*. Oznacza to, że z upływem ww. terminu dostęp do tych treści wygasa. *Z zastrzeżeniem sytuacji, w kt&oacute;rych opisie oferowanego kursu wskazana jest inna data dostępu do zakupionego kursu.&nbsp;</li>
        </ol>
        <h5>&sect; 7 Dostarczanie treści cyfrowych przed upływem terminu do odstąpienia od umowy</h5>
        <ol>
          <li>Dokonując zakupu online, użytkownik wyraża zgodę na spełnienie świadczenia przed upływem terminu do odstąpienia od umowy.</li>
          <li>Z chwilą udzielenia zgody, o kt&oacute;rej mowa w ust. 1 powyżej, użytkownik traci prawo odstąpienia od umowy. Prowadząca bez zbędnej zwłoki prześle na adres e-mail ucznia podany do rejestracji w serwisie potwierdzenie zakupu online oraz utratę prawa odstąpienia od umowy.</li>
        </ol>
        <h5>&sect; 8 Prawo odstąpienia</h5>
        <ol>
          <li>Dokonując zakupu online, uczniowi przysługuje prawo odstąpienia od umowy w terminie 14 dni bez podania przyczyny. Termin na odstąpienie od umowy wygasa po upływie 14 dni od daty zawarcia umowy.</li>
          <li>Aby skorzystać z prawa do odstąpienia od umowy użytkownik powinien poinformować Prowadzącą w drodze jednoznacznego oświadczenia, przesłanego drogą elektroniczną lub tradycyjną.</li>
          <li>Oświadczenie, o kt&oacute;rym mowa powyżej, może być złożone w formie wypełnione wzoru formularza, stanowiącego załącznik nr 2 do ustawy o prawach konsumenta.</li>
          <li>Wysłanie informacji dotyczącej wykonania przysługującego prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy skutkuje zachowaniem terminu.</li>
          <li>Skuteczne odstąpienie od umowy skutkować będzie zwrotem wszystkich otrzymanych płatności, nie p&oacute;źniej niż w ciągu 14 dni od dnia otrzymania informacji o wykonaniu prawa odstąpienia. Zwrot płatności nastąpi przy użyciu takiego samego sposobu płatności jak transakcja pierwotna. Z tytułu zwrotu użytkownik nie ponosi dodatkowych koszt&oacute;w.</li>
          <li>Akceptując regulamin, użytkownik potwierdza, że został poinformowany, że zgodnie z art. 38 pkt 13 ustawy o prawach konsumenta, w przypadku umowy o dostarczanie treści cyfrowych, kt&oacute;re nie są zapisane na nośniku materialnym, prawo do odstąpienia nie przysługuje jeśli spełnianie świadczenia nastąpiło na wyraźną zgodę konsumenta przed<br />upływem terminu do odstąpienia umowy i po poinformowaniu o utracie prawa do odstąpienia od umowy.</li>
          <li>W ślad za powyższym, użytkownik, kt&oacute;ry dokonał zakupu online i zaakceptował rozpoczęcie świadczenia przed upływem terminu do odstąpienia od umowy, nie jest uprawniony do odstąpienia od zakupu e-booka lub kursu online. Po wykonaniu świadczenia usługi i uruchomieniu dostępu do kursu nie ma możliwości zamiany zakupionego kursu na inny spośr&oacute;d oferty.</li>
        </ol>
        <h5>&sect; 9 Dane osobowe i prywatność</h5>
        <p>Administratorem danych osobowych ucznia jest Prowadząca, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.&nbsp;Prowadząca przetwarza dane osobowe podane przez ucznia w procesie rejestracji konta w celu świadczenia usług drogą elektroniczną. Więcej informacji w tym zakresie znajduje się w Polityce Prywatności, dostępnej z poziomu serwisu.</p>
        <h5>&sect; 10 Reklamacje</h5>
        <ol>
          <li>Wszelkie reklamacje dotyczące korzystania z serwisu i oferowanych za jego pośrednictwem funkcjonalności należy przesyłać na adres Prowadzącej, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.</li>
          <li>Reklamacja powinna zawierać co najmniej:
            <ol type="a">
              <li>oznaczenie ucznia składającego reklamację, pozwalające na identyfikację;</li>
              <li>wskazanie funkcjonalności, z kt&oacute;rą wiąże się reklamacja;</li>
              <li>wskazanie i uzasadnienie zarzut&oacute;w będących podstawą reklamacji;</li>
              <li>wskazanie zakresu żądania ucznia składającego reklamację;</li>
            </ol>
          </li>
          <li>Jeśli reklamacja nie zawiera danych, o kt&oacute;rych mowa w ust. 2, Prowadząca, w terminie 7 dni od jej doręczenia, może wezwać Ucznia do uzupełnienia danych.</li>
          <li>Rozpatrzenie reklamacji nastąpi w ciągu 14 dni od dnia doręczenia reklamacji. W razie wezwania do uzupełnienia reklamacji, termin ten liczy się od dnia doręczenia reklamacji uzupełnionej.</li>
        </ol>
        <h5>&sect; 11 Postanowienia końcowe</h5>
        <ol>
          <li>Wszelkie nieuregulowane w niniejszym regulaminie kwestie rządzą się przepisami prawa polskiego, a zwłaszcza kodeksu cywilnego, oraz ustaw &ndash; o prawach konsumenta oraz o prawie autorskim i prawach pokrewnych.</li>
          <li>Regulamin w niniejszej wersji obowiązuje od 31 sierpnia 2021 r.</li>
          <li>Prowadząca zastrzega sobie prawo do zmiany regulaminu z ważnych przyczyn. W szczeg&oacute;lności zmiana może nastąpić w razie konieczności dostosowania regulaminu do wymog&oacute;w znowelizowanego prawa, technicznych względ&oacute;w funkcjonowania serwisu lub modyfikacji zakresu funkcjonowania serwisu.</li>
          <li>Zmiana regulaminu wchodzi w życie w stosunku do użytkownika w terminie wskazanym przez Prowadzącą w wiadomości skierowanej do użytkownika, przesłanej albo za pośrednictwem wiadomości e-mail albo dostępnej po zalogowaniu się do konta użytkownika. Zakupy online dokonane przed wejściem w życie zmiany regulaminu rządzą się zasadami określonymi w regulaminie w brzmieniu uprzednim.</li>
          <li>Prowadząca ma obowiązek dostarczania treści cyfrowych bez wad.</li>
          <li>Zapłata ceny za oferowane treści cyfrowe wyczerpuje zobowiązania użytkownika, dalsze korzystanie z zakupionej treści cyfrowej stanowi jego uprawnienie.</li>
          <li>W przypadku unieważnienia kt&oacute;regokolwiek postanowienia niniejszego regulaminu mocą orzeczenia sądu, pozostałe jego postanowienia pozostają w mocy i są obowiązujące.</li>
          <li>Treść regulaminu jest udostępniona w spos&oacute;b stały i nieodpłatnie w serwisie w taki spos&oacute;b, aby użytkownik w każdym czasie m&oacute;gł regulamin odtwarzać i zachowywać jego treść. Zabrania się kopiowania regulaminu i wykorzystywania go do cel&oacute;w prywatnych lub komercyjnych bez zgody Prowadzącej.</li>
        </ol>
      </b-card-text>
    </b-card>
    <b-card sub-title="Wersja obowiązująca od 19.02.2022r.">
      <b-card-text>
        <h3><strong>Regulamin</strong></h3>
        <h5>&sect; 1 Definicje</h5>
        <ol>
          <li>Ilekroć w niniejszym regulaminie używa się poniższych zwrot&oacute;w, oznaczają one:</li>
        </ol>
        <p>a) Prowadząca &ndash; Sonia Radosz, prowadząca działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl</p>
        <p>b) Użytkownik &ndash; osoba korzystająca z serwisu będąca osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych</p>
        <p>c) serwis &ndash; witryna www.chemmaster.pl</p>
        <p>d) e-book &ndash; przygotowane w formie pliku komputerowego opracowania obejmujące teksty oraz grafiki</p>
        <p>e) kurs online &ndash; prowadzony przez Prowadzącą za pośrednictwem środk&oacute;w komunikacji elektronicznej umożliwiających transmisję obrazu i głosu cykl prezentacji, podczas kt&oacute;rych przekazuje ona wiedzę i umiejętności w zakresie oferowanego produktu</p>
        <p>f) treści cyfrowe &ndash; oferowane przez Prowadzącą do sprzedaży za pośrednictwem serwisu usługi i utwory, nie będące utrwalone na nośniku materialnym, do kt&oacute;rych użytkownik ma dostęp za pomocą konta użytkownika.</p>
        <p>g) zakup online &ndash; procedura zamawiania treści cyfrowych oferowanych przez Prowadzącą za pośrednictwem serwisu</p>
        <p>h) konto, konto użytkownika &ndash; indywidualne konto użytkownika umożliwiające dostęp do zakupionych materiał&oacute;w.</p>
        <p>i) newsletter – wiadomości e-mail stanowiące cykliczne informacje o działalności Prowadzącej jak również mogące zawierać informację handlową, adresowane do użytkownika po uprzednim wyrażeniu przez niego odpowiedniej zgody, stanowiące usługę świadczoną drogą elektroniczną w myśl art. 2 ust. 4 ustawy o świadczeniu usług droga elektroniczną</p>
        <h5>&sect; 2 Postanowienia og&oacute;lne</h5>
        <ol>
          <li>Postanowienia niniejszego regulaminu normują zasady oraz warunki korzystania z serwisu oraz funkcjonalności na nim oferowanych, w tym zakupu online.</li>
          <li>Korzystając z serwisu, a w szczeg&oacute;lności zakładając konto, użytkownik zgadza się przestrzegać niniejszego regulaminu i jego postanowień.</li>
          <li>Użytkownik zobowiązuje się do korzystania z konta i zakupionych treści online wyłącznie osobiście, zabronione jest wsp&oacute;łdzielenie konta i zakupionych treści cyfrowych z osobą trzecią.</li>
          <li>Użytkownik jest jednakże uprawniony do przekazania dostępu do konta oraz zakupionej online treści cyfrowej jednemu zstępnemu. Użytkownik zobowiązuje się, pod rygorem odpowiedzialności za działania zstępnego jak za swoje, do zapoznania zstępnego z niniejszym regulaminem oraz nadzorem na zstępnym w zakresie zgodności korzystania z zakupionych online treści cyfrowych z postanowieniami niniejszego regulaminu.</li>
        </ol>
        <h5>&sect; 3 Ochrona własności intelektualnej</h5>
        <ol>
          <li>Wszystkie treści cyfrowe oferowane przez Prowadzącą stanowią jej własność i są przedmiotem ochrony prawa polskiego i międzynarodowego, w szczeg&oacute;lności przepis&oacute;w ustawy z 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</li>
          <li>Z momentem zakupu online treści cyfrowej, na użytkownika nie przechodzą żadne prawa autorskie, tak osobiste jak majątkowe.</li>
          <li>Bez zgody Prowadzącej zabronione jest rozpowszechniane treści cyfrowych uzyskanych za pomocą zakupu online.</li>
          <li>Użytkownik nie jest uprawniony w szczeg&oacute;lności do
            <ol type="a">
              <li>ingerencji w treść treści cyfrowej</li>
              <li>usuwania zabezpieczeń treści cyfrowej, jak np. znaki wodne</li>
              <li>zwielokrotniania treści cyfrowej w formie elektronicznej lub tradycyjnej</li>
              <li>udostępniania treści cyfrowej w internecie, w tym na platformach P2P jak sieci torrent lub inne</li>
              <li>wykorzystywania treści cyfrowej do cel&oacute;w komercyjnych, modyfikacji, zar&oacute;wno w całości jak i w części.</li>
            </ol>
          </li>
          <li>Rozpowszechnianie utworu bez uprawnienia stanowi przestępstwo opisane w art. 116 ustawy o prawie autorskim i prawach pokrewnych i każdy wykryty przypadek takiego działania zostanie zgłoszony do właściwego organu. Dotyczy to także innych przestępstw uregulowanych w powołanej ustawie, jak r&oacute;wnież innych przepisach, jeśli bezprawne rozpowszechnianie treści cyfrowych wyczerpywać będzie znamiona tych przestępstw.</li>
          <li>Użytkownik ma prawo korzystać z treści cyfrowej tylko na własny użytek. Postanowienie to działa bez uszczerbku na uprawnienie opisane w &sect; 2 ust. 4 regulaminu.</li>
          <li>Użytkownik ma prawo do wydruku treści cyfrowej nadającej się do tego, jak np. e-book. Wydrukowana treść cyfrowa jednakże pozostaje pod ochroną prawa własności intelektualnej, w szczeg&oacute;lności w zakresie zakazu kopiowania i rozpowszechniania, przekazywania osobom trzecim do korzystania.</li>
          <li>Użytkownik ma prawo korzystać, w zgodzie z postanowieniami powyższymi, z zakupionych online treści cyfrowych za pośrednictwem r&oacute;żnych urządzeń elektronicznych, o ile nie prowadzi to do zmiany w strukturze treści cyfrowej.</li>
          <li>W przypadku gdy oferowane treści cyfrowe stanowią opracowania tekst&oacute;w zewnętrznych, og&oacute;lnodostępnych (jak np. zbiory zadań Centralnych lub Obwodowych Komisji Egzaminacyjnych), przedmiotem praw autorskich Prowadzącej są dokonane przez nią analizy powyższych tekst&oacute;w w każdej postaci, a w szczeg&oacute;lności komentarzy, grafik, opracowań.</li>
        </ol>
        <h5>&sect; 4 Zakładanie konta</h5>
        <ol>
          <li>Użytkownik chcący korzystać z funkcjonalności serwisu powinien zarejestrować się w serwisie poprzez utworzenie konta.</li>
          <li>Użytkownik zakładający konto powinien być osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych.</li>
        </ol>
        <h5>&sect; 5 Procedura zakupu online treści cyfrowej</h5>
        <ol>
          <li>Prowadząca umożliwia w ramach serwisu zakup online treści cyfrowych, w szczeg&oacute;lności w postaci uczestnictwa w kursie online lub dostarczenia e-booka.</li>
          <li>Informacje o właściwościach oferowanej treści cyfrowej dostępne są każdorazowo z poziomu serwisu.</li>
          <li>Zakup online możliwy jest wyłącznie dla użytkownika, kt&oacute;ry zarejestrował w serwisie swoje konto i kt&oacute;ry podczas procedury zakupu pozostaje zalogowany.</li>
          <li>Treści cyfrowe oferowane w serwisie mogą ulegać zmianie, modyfikacji, ich dostępność może być ograniczona czasowo lub ilościowo, wedle uznania Prowadzącej.</li>
          <li>Płatności za treści cyfrowe dokonywane są za pomocą platformy PayU. Regulaminy funkcjonowania tej platformy dostępne są dla użytkownika pod adresem https://poland.payu.com/dokumenty-prawne-do-pobrania/. Dokonując zakupu online użytkownik potwierdza także zapoznanie się z tymi regulaminami.</li>
          <li>Cena za oferowane treści cyfrowe wyrażona jest w złotych (PLN).</li>
          <li>Prowadząca uprawniona jest do oferowania kod&oacute;w rabatowych, kt&oacute;re pomniejszają ostateczną cenę zakupu treści cyfrowej.</li>
          <li>Spos&oacute;b, terminy i warunki korzystania z kod&oacute;w rabatowych określane są każdorazowo przez Prowadzącą w ramach informacji o tych kodach umieszczonych w serwisie. Pozyskany kod rabatowy przypisany jest na stałe do jednego konta i tylko dane konto może go aktywować.</li>
          <li>Po wyborze żądanej treści cyfrowej użytkownik powinien postępować zgodnie ze wskaz&oacute;wkami widocznymi na stronie internetowej, kt&oacute;ra przeprowadzi go przez proces zakupu.</li>
          <li>Ostateczna cena zakupu, po uwzględnieniu ewentualnych kod&oacute;w rabatowych, stanowi cenę wiążącą, kt&oacute;rą po zaakceptowaniu warunk&oacute;w sprzedaży i przystąpienia do płatności za pośrednictwem platformy PayU użytkownik zobowiązuje się zapłacić.</li>
          <li>Posiadany kod rabatowy należy podać w przeznaczonym do tego polu podczas procedury zakupu, w przeciwnym wypadku nie zostanie on naliczony, nawet jeżeli użytkownik jest uprawniony do jego wykorzystania. W szczeg&oacute;lności posiadane, a nie wykorzystane kody rabatowe nie podlegają zamianie na inne kody rabatowe czy wymianie na środki pieniężne.</li>
          <li>Po złożeniu dyspozycji zakupu i ukończeniu procesu płatności za pośrednictwem platformy PayU (tj. potwierdzenia przez PayU udanego przelewu ceny) użytkownik otrzyma na ujawniony przez siebie adres e-mail automatycznie wygenerowaną wiadomość z potwierdzeniem złożenia zam&oacute;wienia. Z chwilą wysłania tej wiadomości dochodzi do zawarcia umowy, na mocy kt&oacute;rej użytkownik uzyskuje prawo do korzystania z treści cyfrowej, na warunkach niniejszego regulaminu.</li>
          <li>Brak ukończenia procesu płatności za pośrednictwem PayU i zapłaty ceny zwalnia Prowadzącą z obowiązku realizacji zam&oacute;wienia, o czym użytkownik nie musi zostać poinformowany odrębną wiadomością.</li>
          <li>W każdym przypadku, gdy Prowadząca nie jest w stanie zrealizować dokonanego zam&oacute;wienia, użytkownik bez zbędnej zwłoki zostanie o tym poinformowany wiadomością e-mail. W takim przypadku najp&oacute;źniej w ciągu 14 dni od daty zakupu Prowadząca zwr&oacute;ci całą kwotę zapłaconą przez użytkownika, na rachunek z kt&oacute;rego dokonano transakcji pierwotnej.</li>
          <li>Przyczyny, dla kt&oacute;rych Prowadząca nie jest w stanie zrealizować zam&oacute;wienia nie podlegają ujawnieniu użytkownikowi.</li>
          <li>Prowadząca umożliwia wystawienie faktury do każdego zam&oacute;wienia. W celu uzyskania faktury użytkownik powinien w toku dokonywania zam&oacute;wienia zaznaczyć odpowiednią rubrykę i wprowadzić dane umożliwiające wystawienie faktury. Taka faktura w postaci elektronicznej jest następnie &ndash; po pomyślnym dokonaniu zapłaty &ndash; wysyłana e-mailem na wskazany adres użytkownika.</li>
          <li>Prowadząca jest podmiotem korzystającym ze zwolnienia od VAT na podstawie art. 43 ust. 1 ustawy o podatku od towar&oacute;w i usług, wobec czego ceny płacone za treści cyfrowe przez użytkownika ujawnione zostaną na fakturze z VAT zwolnionym.</li>
        </ol>
        <h5>&sect; 6 Spos&oacute;b dostępu do zakupionej treści cyfrowej i warunki techniczne</h5>
        <ol>
          <li>Po dokonaniu zakupu online treści cyfrowej, użytkownik poprzez dostęp do swojego konta może przenieść się do strony internetowej oznaczonej zakładką &bdquo;Moje kursy&rdquo;.</li>
          <li>W sekcji &bdquo;Moje kursy&rdquo; użytkownikowi udostępnione zostaną zakupione treści cyfrowe. Spos&oacute;b korzystania z treści cyfrowej uzależniony jest od charakteru zakupionej treści cyfrowej i nie odbiega od standardowo przyjętego w sieci internet sposobu korzystania z plik&oacute;w i program&oacute;w komputerowych.</li>
          <li>Dostęp do treści cyfrowych oferowanych w postaci plik&oacute;w tekstowych (jak np. e-booki) wymaga zainstalowanego oprogramowania pozwalającego na odczyt plik&oacute;w w postaci *.pdf, takich jak Adobe Acrobat Reader.</li>
          <li>Dostęp do lekcji online na żywo (live) wymaga skorzystania z aplikacji umożliwiających transmisję głosu i obrazu.</li>
          <li>Treści cyfrowe zabezpieczone mogą być znakiem wodnym lub innym powszechnie stosowanym sposobem zabezpieczania treści w sieci internet.</li>
          <li>Szczeg&oacute;łowe warunki korzystania z danej treści cyfrowej, jak np. harmonogram webinari&oacute;w (transmisji) w przypadku kurs&oacute;w online na żywo (live), godziny transmisji, określane są na podstronie ofertowej każdej treści cyfrowej. Informacje dotyczące takich zajęć pojawią się na koncie użytkownika do 24 godzin od rozpoczęcia webinaru.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik w zakładce &bdquo;Moje kursy&rdquo; uzyska link zezwalający na dołączenie do transmisji zgodnie z zaplanowanym harmonogramem.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik po upływie terminu zaplanowanej transmisji uzyska dostęp do nagrania tego materiału w formie offline, z kt&oacute;rego może korzystać jak z każdej innej treści cyfrowej. Okres dostępu do takiej treści cyfrowej może być ograniczony czasowo, o czym użytkownik zostanie poinformowany w zakładce &bdquo;Moje kursy&rdquo; lub &bdquo;Powiadomienia&rdquo;.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca nie ponosi odpowiedzialności za przerwy w transmisji czy spadki w jej jakości spowodowane przepustowością sieci po stronie użytkownika.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, gdy Prowadząca z jakichkolwiek powod&oacute;w, w tym technicznych, zdrowotnych czy prywatnych, nie ma możliwości przeprowadzenia zaplanowanej transmisji, transmisja ta zostanie przeprowadzona w innym dniu, o kt&oacute;rym użytkownik zostanie poinformowany. Decyzja o braku możliwości przeprowadzenia zaplanowanej transmisji jest uznaniowa i użytkownik nie jest uprawniony do jej kwestionowania, ani kreowania z tego tytułu roszczeń wobec Prowadzącej, co pozostaje bez uszczerbku dla poniższych postanowień. Wedle swojego uznania Prowadząca może także w miejsce organizowania zastępczego terminu zaległej transmisji, o kt&oacute;rej mowa powyżej, przygotować materiał filmowy offline i udostępnić go w postaci nagrania w zakładce &bdquo;Moje kursy&rdquo; konta użytkownika. Przeprowadzenie transmisji zastępczej lub udostępnienie materiału offline każdorazowo nastąpi najp&oacute;źniej do 31 kwietnia danego roku, lub roku następnego jeśli zdarzenie ma miejsce po 31 kwietnia danego roku &ndash; za każdym razem tak, aby użytkownik posiadał komplet materiał&oacute;w przygotowujących do nauki jeszcze przed terminem matury.</li>
          <li>Z powodu nieprzeprowadzenia transmisji zgodnie z harmonogramem użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej, chyba że nie wywiąże się ona z przygotowania transmisji zastępczej lub nie udostępni materiału offline, w spos&oacute;b i w terminie o kt&oacute;rym mowa powyżej.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca jest nadto uprawniona do zmiany godziny transmisji w obrębie tego samego dnia. Z tego tytułu użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej.</li>
          <li>W przypadku gdyby kt&oacute;rakolwiek treść cyfrowa zawierała błąd obliczeniowy, tekstowy, skutkujący wadliwością merytoryczną treści cyfrowej, Prowadząca zobowiązuje się bez zbędnej zwłoki do zaktualizowania treści cyfrowej, poinformowania użytkownika o dostrzeżonym błędzie i udostępnienia treści cyfrowej o prawidłowej treści.</li>
          <li>Treści cyfrowe zakupione od 31 sierpnia 2021 r. w postaci materiał&oacute;w dostępnych w zakładce &bdquo;Moje kursy&rdquo; wygasają po upływie roku od daty zakupu*. Oznacza to, że z upływem ww. terminu dostęp do tych treści wygasa. *Z zastrzeżeniem sytuacji, w kt&oacute;rych opisie oferowanego kursu wskazana jest inna data dostępu do zakupionego kursu.&nbsp;</li>
        </ol>
        <h5>&sect; 6a Newsletter</h5>
        <ol>
          <li>Użytkownik ma możliwość wyrażenia zgody na otrzymywanie Newslettera poprzez zapisane się na listę mailingową. Prawidłowość świadczenia usługi możliwa jest wyłącznie w przypadku podania rzeczywistego adresu e-mail.</li>
          <li>Użytkownik wyrażając zgodę na otrzymywanie Newslettera potwierdza chęć otrzymywania informacji handlowej od Prowadzącej. Informacja handlowa zawiera informacje w przedmiocie bieżącej działalności Prowadzącej, oferowanych usług, produktów, promocji czy treści cyfrowych.</li>
          <li>Prowadząca uprawniona jest do udostępnienia użytkownikom zapisanym do Newslettera dodatkowych treści cyfrowych, dostępnych na koncie użytkownika. Dostęp do tych treści będzie darmowy dla każdego użytkownika zapisanego do usługi Newslettera.</li>
          <li>Wypisanie się z Newslettera możliwe jest w każdej chwili za pośrednictwem właściwej opcji w panelu w koncie użytkownika oraz po kliknięciu w link znajdujący się w stopce każdego przesyłanego Newslettera.</li>
          <li>Przetwarzanie danych użytkownika do celów Newslettera odbywa się zgodnie z informacjami opisanymi w Polityce Prywatności</li>
        </ol>
        <h5>&sect; 7 Dostarczanie treści cyfrowych przed upływem terminu do odstąpienia od umowy</h5>
        <ol>
          <li>Dokonując zakupu online, użytkownik wyraża zgodę na spełnienie świadczenia przed upływem terminu do odstąpienia od umowy.</li>
          <li>Z chwilą udzielenia zgody, o kt&oacute;rej mowa w ust. 1 powyżej, użytkownik traci prawo odstąpienia od umowy. Prowadząca bez zbędnej zwłoki prześle na adres e-mail ucznia podany do rejestracji w serwisie potwierdzenie zakupu online oraz utratę prawa odstąpienia od umowy.</li>
        </ol>
        <h5>&sect; 8 Prawo odstąpienia</h5>
        <ol>
          <li>Dokonując zakupu online, uczniowi przysługuje prawo odstąpienia od umowy w terminie 14 dni bez podania przyczyny. Termin na odstąpienie od umowy wygasa po upływie 14 dni od daty zawarcia umowy.</li>
          <li>Aby skorzystać z prawa do odstąpienia od umowy użytkownik powinien poinformować Prowadzącą w drodze jednoznacznego oświadczenia, przesłanego drogą elektroniczną lub tradycyjną.</li>
          <li>Oświadczenie, o kt&oacute;rym mowa powyżej, może być złożone w formie wypełnione wzoru formularza, stanowiącego załącznik nr 2 do ustawy o prawach konsumenta.</li>
          <li>Wysłanie informacji dotyczącej wykonania przysługującego prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy skutkuje zachowaniem terminu.</li>
          <li>Skuteczne odstąpienie od umowy skutkować będzie zwrotem wszystkich otrzymanych płatności, nie p&oacute;źniej niż w ciągu 14 dni od dnia otrzymania informacji o wykonaniu prawa odstąpienia. Zwrot płatności nastąpi przy użyciu takiego samego sposobu płatności jak transakcja pierwotna. Z tytułu zwrotu użytkownik nie ponosi dodatkowych koszt&oacute;w.</li>
          <li>Akceptując regulamin, użytkownik potwierdza, że został poinformowany, że zgodnie z art. 38 pkt 13 ustawy o prawach konsumenta, w przypadku umowy o dostarczanie treści cyfrowych, kt&oacute;re nie są zapisane na nośniku materialnym, prawo do odstąpienia nie przysługuje jeśli spełnianie świadczenia nastąpiło na wyraźną zgodę konsumenta przed<br />upływem terminu do odstąpienia umowy i po poinformowaniu o utracie prawa do odstąpienia od umowy.</li>
          <li>W ślad za powyższym, użytkownik, kt&oacute;ry dokonał zakupu online i zaakceptował rozpoczęcie świadczenia przed upływem terminu do odstąpienia od umowy, nie jest uprawniony do odstąpienia od zakupu e-booka lub kursu online. Po wykonaniu świadczenia usługi i uruchomieniu dostępu do kursu nie ma możliwości zamiany zakupionego kursu na inny spośr&oacute;d oferty.</li>
        </ol>
        <h5>&sect; 9 Dane osobowe i prywatność</h5>
        <p>Administratorem danych osobowych ucznia jest Prowadząca, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.&nbsp;Prowadząca przetwarza dane osobowe podane przez ucznia w procesie rejestracji konta w celu świadczenia usług drogą elektroniczną. Więcej informacji w tym zakresie znajduje się w Polityce Prywatności, dostępnej z poziomu serwisu.</p>
        <h5>&sect; 10 Reklamacje</h5>
        <ol>
          <li>Wszelkie reklamacje dotyczące korzystania z serwisu i oferowanych za jego pośrednictwem funkcjonalności należy przesyłać na adres Prowadzącej, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.</li>
          <li>Reklamacja powinna zawierać co najmniej:
            <ol type="a">
              <li>oznaczenie ucznia składającego reklamację, pozwalające na identyfikację;</li>
              <li>wskazanie funkcjonalności, z kt&oacute;rą wiąże się reklamacja;</li>
              <li>wskazanie i uzasadnienie zarzut&oacute;w będących podstawą reklamacji;</li>
              <li>wskazanie zakresu żądania ucznia składającego reklamację;</li>
            </ol>
          </li>
          <li>Jeśli reklamacja nie zawiera danych, o kt&oacute;rych mowa w ust. 2, Prowadząca, w terminie 7 dni od jej doręczenia, może wezwać Ucznia do uzupełnienia danych.</li>
          <li>Rozpatrzenie reklamacji nastąpi w ciągu 14 dni od dnia doręczenia reklamacji. W razie wezwania do uzupełnienia reklamacji, termin ten liczy się od dnia doręczenia reklamacji uzupełnionej.</li>
        </ol>
        <h5>&sect; 11 Postanowienia końcowe</h5>
        <ol>
          <li>Wszelkie nieuregulowane w niniejszym regulaminie kwestie rządzą się przepisami prawa polskiego, a zwłaszcza kodeksu cywilnego, oraz ustaw &ndash; o prawach konsumenta oraz o prawie autorskim i prawach pokrewnych.</li>
          <li>Regulamin w niniejszej wersji obowiązuje od 31 sierpnia 2021 r.</li>
          <li>Prowadząca zastrzega sobie prawo do zmiany regulaminu z ważnych przyczyn. W szczeg&oacute;lności zmiana może nastąpić w razie konieczności dostosowania regulaminu do wymog&oacute;w znowelizowanego prawa, technicznych względ&oacute;w funkcjonowania serwisu lub modyfikacji zakresu funkcjonowania serwisu.</li>
          <li>Zmiana regulaminu wchodzi w życie w stosunku do użytkownika w terminie wskazanym przez Prowadzącą w wiadomości skierowanej do użytkownika, przesłanej albo za pośrednictwem wiadomości e-mail albo dostępnej po zalogowaniu się do konta użytkownika. Zakupy online dokonane przed wejściem w życie zmiany regulaminu rządzą się zasadami określonymi w regulaminie w brzmieniu uprzednim.</li>
          <li>Prowadząca ma obowiązek dostarczania treści cyfrowych bez wad.</li>
          <li>Zapłata ceny za oferowane treści cyfrowe wyczerpuje zobowiązania użytkownika, dalsze korzystanie z zakupionej treści cyfrowej stanowi jego uprawnienie.</li>
          <li>W przypadku unieważnienia kt&oacute;regokolwiek postanowienia niniejszego regulaminu mocą orzeczenia sądu, pozostałe jego postanowienia pozostają w mocy i są obowiązujące.</li>
          <li>Treść regulaminu jest udostępniona w spos&oacute;b stały i nieodpłatnie w serwisie w taki spos&oacute;b, aby użytkownik w każdym czasie m&oacute;gł regulamin odtwarzać i zachowywać jego treść. Zabrania się kopiowania regulaminu i wykorzystywania go do cel&oacute;w prywatnych lub komercyjnych bez zgody Prowadzącej.</li>
        </ol>
      </b-card-text>
    </b-card>
    <b-card sub-title="Wersja obowiązująca od 05.01.2022r.">
      <b-card-text>
        <h3><strong>Regulamin</strong></h3>
        <h5>&sect; 1 Definicje</h5>
        <ol>
          <li>Ilekroć w niniejszym regulaminie używa się poniższych zwrot&oacute;w, oznaczają one:</li>
        </ol>
        <p>a) Prowadząca &ndash; Sonia Radosz, prowadząca działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl</p>
        <p>b) Użytkownik &ndash; osoba korzystająca z serwisu będąca osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych</p>
        <p>c) serwis &ndash; witryna www.chemmaster.pl</p>
        <p>d) e-book &ndash; przygotowane w formie pliku komputerowego opracowania obejmujące teksty oraz grafiki</p>
        <p>e) kurs online &ndash; prowadzony przez Prowadzącą za pośrednictwem środk&oacute;w komunikacji elektronicznej umożliwiających transmisję obrazu i głosu cykl prezentacji, podczas kt&oacute;rych przekazuje ona wiedzę i umiejętności w zakresie oferowanego produktu</p>
        <p>f) treści cyfrowe &ndash; oferowane przez Prowadzącą do sprzedaży za pośrednictwem serwisu usługi i utwory, nie będące utrwalone na nośniku materialnym, do kt&oacute;rych użytkownik ma dostęp za pomocą konta użytkownika.</p>
        <p>g) zakup online &ndash; procedura zamawiania treści cyfrowych oferowanych przez Prowadzącą za pośrednictwem serwisu</p>
        <p>h) konto, konto użytkownika &ndash; indywidualne konto użytkownika umożliwiające dostęp do zakupionych materiał&oacute;w.</p>
        <h5>&sect; 2 Postanowienia og&oacute;lne</h5>
        <ol>
          <li>Postanowienia niniejszego regulaminu normują zasady oraz warunki korzystania z serwisu oraz funkcjonalności na nim oferowanych, w tym zakupu online.</li>
          <li>Korzystając z serwisu, a w szczeg&oacute;lności zakładając konto, użytkownik zgadza się przestrzegać niniejszego regulaminu i jego postanowień.</li>
          <li>Użytkownik zobowiązuje się do korzystania z konta i zakupionych treści online wyłącznie osobiście, zabronione jest wsp&oacute;łdzielenie konta i zakupionych treści cyfrowych z osobą trzecią.</li>
          <li>Użytkownik jest jednakże uprawniony do przekazania dostępu do konta oraz zakupionej online treści cyfrowej jednemu zstępnemu. Użytkownik zobowiązuje się, pod rygorem odpowiedzialności za działania zstępnego jak za swoje, do zapoznania zstępnego z niniejszym regulaminem oraz nadzorem na zstępnym w zakresie zgodności korzystania z zakupionych online treści cyfrowych z postanowieniami niniejszego regulaminu.</li>
        </ol>
        <h5>&sect; 3 Ochrona własności intelektualnej</h5>
        <ol>
          <li>Wszystkie treści cyfrowe oferowane przez Prowadzącą stanowią jej własność i są przedmiotem ochrony prawa polskiego i międzynarodowego, w szczeg&oacute;lności przepis&oacute;w ustawy z 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</li>
          <li>Z momentem zakupu online treści cyfrowej, na użytkownika nie przechodzą żadne prawa autorskie, tak osobiste jak majątkowe.</li>
          <li>Bez zgody Prowadzącej zabronione jest rozpowszechniane treści cyfrowych uzyskanych za pomocą zakupu online.</li>
          <li>Użytkownik nie jest uprawniony w szczeg&oacute;lności do
            <ol type="a">
              <li>ingerencji w treść treści cyfrowej</li>
              <li>usuwania zabezpieczeń treści cyfrowej, jak np. znaki wodne</li>
              <li>zwielokrotniania treści cyfrowej w formie elektronicznej lub tradycyjnej</li>
              <li>udostępniania treści cyfrowej w internecie, w tym na platformach P2P jak sieci torrent lub inne</li>
              <li>wykorzystywania treści cyfrowej do cel&oacute;w komercyjnych, modyfikacji, zar&oacute;wno w całości jak i w części.</li>
            </ol>
          </li>
          <li>Rozpowszechnianie utworu bez uprawnienia stanowi przestępstwo opisane w art. 116 ustawy o prawie autorskim i prawach pokrewnych i każdy wykryty przypadek takiego działania zostanie zgłoszony do właściwego organu. Dotyczy to także innych przestępstw uregulowanych w powołanej ustawie, jak r&oacute;wnież innych przepisach, jeśli bezprawne rozpowszechnianie treści cyfrowych wyczerpywać będzie znamiona tych przestępstw.</li>
          <li>Użytkownik ma prawo korzystać z treści cyfrowej tylko na własny użytek. Postanowienie to działa bez uszczerbku na uprawnienie opisane w &sect; 2 ust. 4 regulaminu.</li>
          <li>Użytkownik ma prawo do wydruku treści cyfrowej nadającej się do tego, jak np. e-book. Wydrukowana treść cyfrowa jednakże pozostaje pod ochroną prawa własności intelektualnej, w szczeg&oacute;lności w zakresie zakazu kopiowania i rozpowszechniania, przekazywania osobom trzecim do korzystania.</li>
          <li>Użytkownik ma prawo korzystać, w zgodzie z postanowieniami powyższymi, z zakupionych online treści cyfrowych za pośrednictwem r&oacute;żnych urządzeń elektronicznych, o ile nie prowadzi to do zmiany w strukturze treści cyfrowej.</li>
          <li>W przypadku gdy oferowane treści cyfrowe stanowią opracowania tekst&oacute;w zewnętrznych, og&oacute;lnodostępnych (jak np. zbiory zadań Centralnych lub Obwodowych Komisji Egzaminacyjnych), przedmiotem praw autorskich Prowadzącej są dokonane przez nią analizy powyższych tekst&oacute;w w każdej postaci, a w szczeg&oacute;lności komentarzy, grafik, opracowań.</li>
        </ol>
        <h5>&sect; 4 Zakładanie konta</h5>
        <ol>
          <li>Użytkownik chcący korzystać z funkcjonalności serwisu powinien zarejestrować się w serwisie poprzez utworzenie konta.</li>
          <li>Użytkownik zakładający konto powinien być osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych.</li>
        </ol>
        <h5>&sect; 5 Procedura zakupu online treści cyfrowej</h5>
        <ol>
          <li>Prowadząca umożliwia w ramach serwisu zakup online treści cyfrowych, w szczeg&oacute;lności w postaci uczestnictwa w kursie online lub dostarczenia e-booka.</li>
          <li>Informacje o właściwościach oferowanej treści cyfrowej dostępne są każdorazowo z poziomu serwisu.</li>
          <li>Zakup online możliwy jest wyłącznie dla użytkownika, kt&oacute;ry zarejestrował w serwisie swoje konto i kt&oacute;ry podczas procedury zakupu pozostaje zalogowany.</li>
          <li>Treści cyfrowe oferowane w serwisie mogą ulegać zmianie, modyfikacji, ich dostępność może być ograniczona czasowo lub ilościowo, wedle uznania Prowadzącej.</li>
          <li>Płatności za treści cyfrowe dokonywane są za pomocą platformy PayU. Regulaminy funkcjonowania tej platformy dostępne są dla użytkownika pod adresem https://poland.payu.com/dokumenty-prawne-do-pobrania/. Dokonując zakupu online użytkownik potwierdza także zapoznanie się z tymi regulaminami.</li>
          <li>Cena za oferowane treści cyfrowe wyrażona jest w złotych (PLN).</li>
          <li>Prowadząca uprawniona jest do oferowania kod&oacute;w rabatowych, kt&oacute;re pomniejszają ostateczną cenę zakupu treści cyfrowej.</li>
          <li>Spos&oacute;b, terminy i warunki korzystania z kod&oacute;w rabatowych określane są każdorazowo przez Prowadzącą w ramach informacji o tych kodach umieszczonych w serwisie. Pozyskany kod rabatowy przypisany jest na stałe do jednego konta i tylko dane konto może go aktywować.</li>
          <li>Po wyborze żądanej treści cyfrowej użytkownik powinien postępować zgodnie ze wskaz&oacute;wkami widocznymi na stronie internetowej, kt&oacute;ra przeprowadzi go przez proces zakupu.</li>
          <li>Ostateczna cena zakupu, po uwzględnieniu ewentualnych kod&oacute;w rabatowych, stanowi cenę wiążącą, kt&oacute;rą po zaakceptowaniu warunk&oacute;w sprzedaży i przystąpienia do płatności za pośrednictwem platformy PayU użytkownik zobowiązuje się zapłacić.</li>
          <li>Posiadany kod rabatowy należy podać w przeznaczonym do tego polu podczas procedury zakupu, w przeciwnym wypadku nie zostanie on naliczony, nawet jeżeli użytkownik jest uprawniony do jego wykorzystania. W szczeg&oacute;lności posiadane, a nie wykorzystane kody rabatowe nie podlegają zamianie na inne kody rabatowe czy wymianie na środki pieniężne.</li>
          <li>Po złożeniu dyspozycji zakupu i ukończeniu procesu płatności za pośrednictwem platformy PayU (tj. potwierdzenia przez PayU udanego przelewu ceny) użytkownik otrzyma na ujawniony przez siebie adres e-mail automatycznie wygenerowaną wiadomość z potwierdzeniem złożenia zam&oacute;wienia. Z chwilą wysłania tej wiadomości dochodzi do zawarcia umowy, na mocy kt&oacute;rej użytkownik uzyskuje prawo do korzystania z treści cyfrowej, na warunkach niniejszego regulaminu.</li>
          <li>Brak ukończenia procesu płatności za pośrednictwem PayU i zapłaty ceny zwalnia Prowadzącą z obowiązku realizacji zam&oacute;wienia, o czym użytkownik nie musi zostać poinformowany odrębną wiadomością.</li>
          <li>W każdym przypadku, gdy Prowadząca nie jest w stanie zrealizować dokonanego zam&oacute;wienia, użytkownik bez zbędnej zwłoki zostanie o tym poinformowany wiadomością e-mail. W takim przypadku najp&oacute;źniej w ciągu 14 dni od daty zakupu Prowadząca zwr&oacute;ci całą kwotę zapłaconą przez użytkownika, na rachunek z kt&oacute;rego dokonano transakcji pierwotnej.</li>
          <li>Przyczyny, dla kt&oacute;rych Prowadząca nie jest w stanie zrealizować zam&oacute;wienia nie podlegają ujawnieniu użytkownikowi.</li>
          <li>Prowadząca umożliwia wystawienie faktury do każdego zam&oacute;wienia. W celu uzyskania faktury użytkownik powinien w toku dokonywania zam&oacute;wienia zaznaczyć odpowiednią rubrykę i wprowadzić dane umożliwiające wystawienie faktury. Taka faktura w postaci elektronicznej jest następnie &ndash; po pomyślnym dokonaniu zapłaty &ndash; wysyłana e-mailem na wskazany adres użytkownika.</li>
          <li>Prowadząca jest podmiotem korzystającym ze zwolnienia od VAT na podstawie art. 43 ust. 1 ustawy o podatku od towar&oacute;w i usług, wobec czego ceny płacone za treści cyfrowe przez użytkownika ujawnione zostaną na fakturze z VAT zwolnionym.</li>
        </ol>
        <h5>&sect; 6 Spos&oacute;b dostępu do zakupionej treści cyfrowej i warunki techniczne</h5>
        <ol>
          <li>Po dokonaniu zakupu online treści cyfrowej, użytkownik poprzez dostęp do swojego konta może przenieść się do strony internetowej oznaczonej zakładką &bdquo;Moje kursy&rdquo;.</li>
          <li>W sekcji &bdquo;Moje kursy&rdquo; użytkownikowi udostępnione zostaną zakupione treści cyfrowe. Spos&oacute;b korzystania z treści cyfrowej uzależniony jest od charakteru zakupionej treści cyfrowej i nie odbiega od standardowo przyjętego w sieci internet sposobu korzystania z plik&oacute;w i program&oacute;w komputerowych.</li>
          <li>Dostęp do treści cyfrowych oferowanych w postaci plik&oacute;w tekstowych (jak np. e-booki) wymaga zainstalowanego oprogramowania pozwalającego na odczyt plik&oacute;w w postaci *.pdf, takich jak Adobe Acrobat Reader.</li>
          <li>Dostęp do lekcji online na żywo (live) wymaga skorzystania z aplikacji umożliwiających transmisję głosu i obrazu.</li>
          <li>Treści cyfrowe zabezpieczone mogą być znakiem wodnym lub innym powszechnie stosowanym sposobem zabezpieczania treści w sieci internet.</li>
          <li>Szczeg&oacute;łowe warunki korzystania z danej treści cyfrowej, jak np. harmonogram webinari&oacute;w (transmisji) w przypadku kurs&oacute;w online na żywo (live), godziny transmisji, określane są na podstronie ofertowej każdej treści cyfrowej. Informacje dotyczące takich zajęć pojawią się na koncie użytkownika do 24 godzin od rozpoczęcia webinaru.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik w zakładce &bdquo;Moje kursy&rdquo; uzyska link zezwalający na dołączenie do transmisji zgodnie z zaplanowanym harmonogramem.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik po upływie terminu zaplanowanej transmisji uzyska dostęp do nagrania tego materiału w formie offline, z kt&oacute;rego może korzystać jak z każdej innej treści cyfrowej. Okres dostępu do takiej treści cyfrowej może być ograniczony czasowo, o czym użytkownik zostanie poinformowany w zakładce &bdquo;Moje kursy&rdquo; lub &bdquo;Powiadomienia&rdquo;.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca nie ponosi odpowiedzialności za przerwy w transmisji czy spadki w jej jakości spowodowane przepustowością sieci po stronie użytkownika.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, gdy Prowadząca z jakichkolwiek powod&oacute;w, w tym technicznych, zdrowotnych czy prywatnych, nie ma możliwości przeprowadzenia zaplanowanej transmisji, transmisja ta zostanie przeprowadzona w innym dniu, o kt&oacute;rym użytkownik zostanie poinformowany. Decyzja o braku możliwości przeprowadzenia zaplanowanej transmisji jest uznaniowa i użytkownik nie jest uprawniony do jej kwestionowania, ani kreowania z tego tytułu roszczeń wobec Prowadzącej, co pozostaje bez uszczerbku dla poniższych postanowień. Wedle swojego uznania Prowadząca może także w miejsce organizowania zastępczego terminu zaległej transmisji, o kt&oacute;rej mowa powyżej, przygotować materiał filmowy offline i udostępnić go w postaci nagrania w zakładce &bdquo;Moje kursy&rdquo; konta użytkownika. Przeprowadzenie transmisji zastępczej lub udostępnienie materiału offline każdorazowo nastąpi najp&oacute;źniej do 31 kwietnia danego roku, lub roku następnego jeśli zdarzenie ma miejsce po 31 kwietnia danego roku &ndash; za każdym razem tak, aby użytkownik posiadał komplet materiał&oacute;w przygotowujących do nauki jeszcze przed terminem matury.</li>
          <li>Z powodu nieprzeprowadzenia transmisji zgodnie z harmonogramem użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej, chyba że nie wywiąże się ona z przygotowania transmisji zastępczej lub nie udostępni materiału offline, w spos&oacute;b i w terminie o kt&oacute;rym mowa powyżej.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca jest nadto uprawniona do zmiany godziny transmisji w obrębie tego samego dnia. Z tego tytułu użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej.</li>
          <li>W przypadku gdyby kt&oacute;rakolwiek treść cyfrowa zawierała błąd obliczeniowy, tekstowy, skutkujący wadliwością merytoryczną treści cyfrowej, Prowadząca zobowiązuje się bez zbędnej zwłoki do zaktualizowania treści cyfrowej, poinformowania użytkownika o dostrzeżonym błędzie i udostępnienia treści cyfrowej o prawidłowej treści.</li>
          <li>Treści cyfrowe zakupione od 31 sierpnia 2021 r. w postaci materiał&oacute;w dostępnych w zakładce &bdquo;Moje kursy&rdquo; wygasają po upływie roku od daty zakupu*. Oznacza to, że z upływem ww. terminu dostęp do tych treści wygasa. *Z zastrzeżeniem sytuacji, w kt&oacute;rych opisie oferowanego kursu wskazana jest inna data dostępu do zakupionego kursu.&nbsp;</li>
        </ol>
        <h5>&sect; 7 Dostarczanie treści cyfrowych przed upływem terminu do odstąpienia od umowy</h5>
        <ol>
          <li>Dokonując zakupu online, użytkownik wyraża zgodę na spełnienie świadczenia przed upływem terminu do odstąpienia od umowy.</li>
          <li>Z chwilą udzielenia zgody, o kt&oacute;rej mowa w ust. 1 powyżej, użytkownik traci prawo odstąpienia od umowy. Prowadząca bez zbędnej zwłoki prześle na adres e-mail ucznia podany do rejestracji w serwisie potwierdzenie zakupu online oraz utratę prawa odstąpienia od umowy.</li>
        </ol>
        <h5>&sect; 8 Prawo odstąpienia</h5>
        <ol>
          <li>Dokonując zakupu online, uczniowi przysługuje prawo odstąpienia od umowy w terminie 14 dni bez podania przyczyny. Termin na odstąpienie od umowy wygasa po upływie 14 dni od daty zawarcia umowy.</li>
          <li>Aby skorzystać z prawa do odstąpienia od umowy użytkownik powinien poinformować Prowadzącą w drodze jednoznacznego oświadczenia, przesłanego drogą elektroniczną lub tradycyjną.</li>
          <li>Oświadczenie, o kt&oacute;rym mowa powyżej, może być złożone w formie wypełnione wzoru formularza, stanowiącego załącznik nr 2 do ustawy o prawach konsumenta.</li>
          <li>Wysłanie informacji dotyczącej wykonania przysługującego prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy skutkuje zachowaniem terminu.</li>
          <li>Skuteczne odstąpienie od umowy skutkować będzie zwrotem wszystkich otrzymanych płatności, nie p&oacute;źniej niż w ciągu 14 dni od dnia otrzymania informacji o wykonaniu prawa odstąpienia. Zwrot płatności nastąpi przy użyciu takiego samego sposobu płatności jak transakcja pierwotna. Z tytułu zwrotu użytkownik nie ponosi dodatkowych koszt&oacute;w.</li>
          <li>Akceptując regulamin, użytkownik potwierdza, że został poinformowany, że zgodnie z art. 38 pkt 13 ustawy o prawach konsumenta, w przypadku umowy o dostarczanie treści cyfrowych, kt&oacute;re nie są zapisane na nośniku materialnym, prawo do odstąpienia nie przysługuje jeśli spełnianie świadczenia nastąpiło na wyraźną zgodę konsumenta przed<br />upływem terminu do odstąpienia umowy i po poinformowaniu o utracie prawa do odstąpienia od umowy.</li>
          <li>W ślad za powyższym, użytkownik, kt&oacute;ry dokonał zakupu online i zaakceptował rozpoczęcie świadczenia przed upływem terminu do odstąpienia od umowy, nie jest uprawniony do odstąpienia od zakupu e-booka lub kursu online. Po wykonaniu świadczenia usługi i uruchomieniu dostępu do kursu nie ma możliwości zamiany zakupionego kursu na inny spośr&oacute;d oferty.</li>
        </ol>
        <h5>&sect; 9 Dane osobowe i prywatność</h5>
        <p>Administratorem danych osobowych ucznia jest Prowadząca, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.&nbsp;Prowadząca przetwarza dane osobowe podane przez ucznia w procesie rejestracji konta w celu świadczenia usług drogą elektroniczną. Więcej informacji w tym zakresie znajduje się w Polityce Prywatności, dostępnej z poziomu serwisu.</p>
        <h5>&sect; 10 Reklamacje</h5>
        <ol>
          <li>Wszelkie reklamacje dotyczące korzystania z serwisu i oferowanych za jego pośrednictwem funkcjonalności należy przesyłać na adres Prowadzącej, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.</li>
          <li>Reklamacja powinna zawierać co najmniej:
            <ol type="a">
              <li>oznaczenie ucznia składającego reklamację, pozwalające na identyfikację;</li>
              <li>wskazanie funkcjonalności, z kt&oacute;rą wiąże się reklamacja;</li>
              <li>wskazanie i uzasadnienie zarzut&oacute;w będących podstawą reklamacji;</li>
              <li>wskazanie zakresu żądania ucznia składającego reklamację;</li>
            </ol>
          </li>
          <li>Jeśli reklamacja nie zawiera danych, o kt&oacute;rych mowa w ust. 2, Prowadząca, w terminie 7 dni od jej doręczenia, może wezwać Ucznia do uzupełnienia danych.</li>
          <li>Rozpatrzenie reklamacji nastąpi w ciągu 14 dni od dnia doręczenia reklamacji. W razie wezwania do uzupełnienia reklamacji, termin ten liczy się od dnia doręczenia reklamacji uzupełnionej.</li>
        </ol>
        <h5>&sect; 11 Postanowienia końcowe</h5>
        <ol>
          <li>Wszelkie nieuregulowane w niniejszym regulaminie kwestie rządzą się przepisami prawa polskiego, a zwłaszcza kodeksu cywilnego, oraz ustaw &ndash; o prawach konsumenta oraz o prawie autorskim i prawach pokrewnych.</li>
          <li>Regulamin w niniejszej wersji obowiązuje od 31 sierpnia 2021 r.</li>
          <li>Prowadząca zastrzega sobie prawo do zmiany regulaminu z ważnych przyczyn. W szczeg&oacute;lności zmiana może nastąpić w razie konieczności dostosowania regulaminu do wymog&oacute;w znowelizowanego prawa, technicznych względ&oacute;w funkcjonowania serwisu lub modyfikacji zakresu funkcjonowania serwisu.</li>
          <li>Zmiana regulaminu wchodzi w życie w stosunku do użytkownika w terminie wskazanym przez Prowadzącą w wiadomości skierowanej do użytkownika, przesłanej albo za pośrednictwem wiadomości e-mail albo dostępnej po zalogowaniu się do konta użytkownika. Zakupy online dokonane przed wejściem w życie zmiany regulaminu rządzą się zasadami określonymi w regulaminie w brzmieniu uprzednim.</li>
          <li>Prowadząca ma obowiązek dostarczania treści cyfrowych bez wad.</li>
          <li>Zapłata ceny za oferowane treści cyfrowe wyczerpuje zobowiązania użytkownika, dalsze korzystanie z zakupionej treści cyfrowej stanowi jego uprawnienie.</li>
          <li>W przypadku unieważnienia kt&oacute;regokolwiek postanowienia niniejszego regulaminu mocą orzeczenia sądu, pozostałe jego postanowienia pozostają w mocy i są obowiązujące.</li>
          <li>Treść regulaminu jest udostępniona w spos&oacute;b stały i nieodpłatnie w serwisie w taki spos&oacute;b, aby użytkownik w każdym czasie m&oacute;gł regulamin odtwarzać i zachowywać jego treść. Zabrania się kopiowania regulaminu i wykorzystywania go do cel&oacute;w prywatnych lub komercyjnych bez zgody Prowadzącej.</li>
        </ol>
      </b-card-text>
    </b-card>
    <b-card sub-title="wersja obowiązująca od 4 stycznia 2022 r.">
      <b-card-text>
        <p><strong>Regulamin serwisu chemmaster.pl</strong></p>
        <h5>&sect; 1 Definicje</h5>
        <ol>
          <li>Ilekroć w niniejszym regulaminie używa się poniższych zwrot&oacute;w, oznaczają one:</li>
        </ol>
        <p>a) Prowadząca &ndash; Sonia Radosz, prowadząca działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl</p>
        <p>b) Użytkownik &ndash; osoba korzystająca z serwisu będąca osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych</p>
        <p>c) serwis &ndash; witryna www.chemmaster.pl</p>
        <p>d) e-book &ndash; przygotowane w formie pliku komputerowego opracowania obejmujące teksty oraz grafiki</p>
        <p>e) kurs online &ndash; prowadzony przez Prowadzącą za pośrednictwem środk&oacute;w komunikacji elektronicznej umożliwiających transmisję obrazu i głosu cykl prezentacji, podczas kt&oacute;rych przekazuje ona wiedzę i umiejętności w zakresie oferowanego produktu</p>
        <p>f) treści cyfrowe &ndash; oferowane przez Prowadzącą do sprzedaży za pośrednictwem serwisu usługi i utwory, nie będące utrwalone na nośniku materialnym, do kt&oacute;rych użytkownik ma dostęp za pomocą konta użytkownika.</p>
        <p>g) zakup online &ndash; procedura zamawiania treści cyfrowych oferowanych przez Prowadzącą za pośrednictwem serwisu</p>
        <p>h) konto, konto użytkownika &ndash; indywidualne konto użytkownika umożliwiające dostęp do zakupionych materiał&oacute;w.</p>
        <h5>&sect; 2 Postanowienia og&oacute;lne</h5>
        <ol>
          <li>Postanowienia niniejszego regulaminu normują zasady oraz warunki korzystania z serwisu oraz funkcjonalności na nim oferowanych, w tym zakupu online.</li>
          <li>Korzystając z serwisu, a w szczeg&oacute;lności zakładając konto, użytkownik zgadza się przestrzegać niniejszego regulaminu i jego postanowień.</li>
          <li>Użytkownik zobowiązuje się do korzystania z konta i zakupionych treści online wyłącznie osobiście, zabronione jest wsp&oacute;łdzielenie konta i zakupionych treści cyfrowych z osobą trzecią.</li>
          <li>Użytkownik jest jednakże uprawniony do przekazania dostępu do konta oraz zakupionej online treści cyfrowej jednemu zstępnemu. Użytkownik zobowiązuje się, pod rygorem odpowiedzialności za działania zstępnego jak za swoje, do zapoznania zstępnego z niniejszym regulaminem oraz nadzorem na zstępnym w zakresie zgodności korzystania z zakupionych online treści cyfrowych z postanowieniami niniejszego regulaminu.</li>
        </ol>
        <h5>&sect; 3 Ochrona własności intelektualnej</h5>
        <ol>
          <li>Wszystkie treści cyfrowe oferowane przez Prowadzącą stanowią jej własność i są przedmiotem ochrony prawa polskiego i międzynarodowego, w szczeg&oacute;lności przepis&oacute;w ustawy z 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</li>
          <li>Z momentem zakupu online treści cyfrowej, na użytkownika nie przechodzą żadne prawa autorskie, tak osobiste jak majątkowe.</li>
          <li>Bez zgody Prowadzącej zabronione jest rozpowszechniane treści cyfrowych uzyskanych za pomocą zakupu online.</li>
          <li>Użytkownik nie jest uprawniony w szczeg&oacute;lności do
            <ol type="a">
              <li>ingerencji w treść treści cyfrowej</li>
              <li>usuwania zabezpieczeń treści cyfrowej, jak np. znaki wodne</li>
              <li>zwielokrotniania treści cyfrowej w formie elektronicznej lub tradycyjnej</li>
              <li>udostępniania treści cyfrowej w internecie, w tym na platformach P2P jak sieci torrent lub inne</li>
              <li>wykorzystywania treści cyfrowej do cel&oacute;w komercyjnych, modyfikacji, zar&oacute;wno w całości jak i w części.</li>
            </ol>
          </li>
          <li>Rozpowszechnianie utworu bez uprawnienia stanowi przestępstwo opisane w art. 116 ustawy o prawie autorskim i prawach pokrewnych i każdy wykryty przypadek takiego działania zostanie zgłoszony do właściwego organu. Dotyczy to także innych przestępstw uregulowanych w powołanej ustawie, jak r&oacute;wnież innych przepisach, jeśli bezprawne rozpowszechnianie treści cyfrowych wyczerpywać będzie znamiona tych przestępstw.</li>
          <li>Użytkownik ma prawo korzystać z treści cyfrowej tylko na własny użytek. Postanowienie to działa bez uszczerbku na uprawnienie opisane w &sect; 2 ust. 4 regulaminu.</li>
          <li>Użytkownik ma prawo do wydruku treści cyfrowej nadającej się do tego, jak np. e-book. Wydrukowana treść cyfrowa jednakże pozostaje pod ochroną prawa własności intelektualnej, w szczeg&oacute;lności w zakresie zakazu kopiowania i rozpowszechniania, przekazywania osobom trzecim do korzystania.</li>
          <li>Użytkownik ma prawo korzystać, w zgodzie z postanowieniami powyższymi, z zakupionych online treści cyfrowych za pośrednictwem r&oacute;żnych urządzeń elektronicznych, o ile nie prowadzi to do zmiany w strukturze treści cyfrowej.</li>
          <li>W przypadku gdy oferowane treści cyfrowe stanowią opracowania tekst&oacute;w zewnętrznych, og&oacute;lnodostępnych (jak np. zbiory zadań Centralnych lub Obwodowych Komisji Egzaminacyjnych), przedmiotem praw autorskich Prowadzącej są dokonane przez nią analizy powyższych tekst&oacute;w w każdej postaci, a w szczeg&oacute;lności komentarzy, grafik, opracowań.</li>
        </ol>
        <h5>&sect; 4 Zakładanie konta</h5>
        <ol>
          <li>Użytkownik chcący korzystać z funkcjonalności serwisu powinien zarejestrować się w serwisie poprzez utworzenie konta.</li>
          <li>Użytkownik zakładający konto powinien być osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych.</li>
        </ol>
        <h5>&sect; 5 Procedura zakupu online treści cyfrowej</h5>
        <ol>
          <li>Prowadząca umożliwia w ramach serwisu zakup online treści cyfrowych, w szczeg&oacute;lności w postaci uczestnictwa w kursie online lub dostarczenia e-booka.</li>
          <li>Informacje o właściwościach oferowanej treści cyfrowej dostępne są każdorazowo z poziomu serwisu.</li>
          <li>Zakup online możliwy jest wyłącznie dla użytkownika, kt&oacute;ry zarejestrował w serwisie swoje konto i kt&oacute;ry podczas procedury zakupu pozostaje zalogowany.</li>
          <li>Treści cyfrowe oferowane w serwisie mogą ulegać zmianie, modyfikacji, ich dostępność może być ograniczona czasowo lub ilościowo, wedle uznania Prowadzącej.</li>
          <li>Płatności za treści cyfrowe dokonywane są za pomocą platformy PayU. Regulaminy funkcjonowania tej platformy dostępne są dla użytkownika pod adresem https://poland.payu.com/dokumenty-prawne-do-pobrania/. Dokonując zakupu online użytkownik potwierdza także zapoznanie się z tymi regulaminami.</li>
          <li>Cena za oferowane treści cyfrowe wyrażona jest w złotych (PLN).</li>
          <li>Prowadząca uprawniona jest do oferowania kod&oacute;w rabatowych, kt&oacute;re pomniejszają ostateczną cenę zakupu treści cyfrowej.</li>
          <li>Spos&oacute;b, terminy i warunki korzystania z kod&oacute;w rabatowych określane są każdorazowo przez Prowadzącą w ramach informacji o tych kodach umieszczonych w serwisie. Pozyskany kod rabatowy przypisany jest na stałe do jednego konta i tylko dane konto może go aktywować.</li>
          <li>Po wyborze żądanej treści cyfrowej użytkownik powinien postępować zgodnie ze wskaz&oacute;wkami widocznymi na stronie internetowej, kt&oacute;ra przeprowadzi go przez proces zakupu.</li>
          <li>Ostateczna cena zakupu, po uwzględnieniu ewentualnych kod&oacute;w rabatowych, stanowi cenę wiążącą, kt&oacute;rą po zaakceptowaniu warunk&oacute;w sprzedaży i przystąpienia do płatności za pośrednictwem platformy PayU użytkownik zobowiązuje się zapłacić.</li>
          <li>Posiadany kod rabatowy należy podać w przeznaczonym do tego polu podczas procedury zakupu, w przeciwnym wypadku nie zostanie on naliczony, nawet jeżeli użytkownik jest uprawniony do jego wykorzystania. W szczeg&oacute;lności posiadane, a nie wykorzystane kody rabatowe nie podlegają zamianie na inne kody rabatowe czy wymianie na środki pieniężne.</li>
          <li>Po złożeniu dyspozycji zakupu i ukończeniu procesu płatności za pośrednictwem platformy PayU (tj. potwierdzenia przez PayU udanego przelewu ceny) użytkownik otrzyma na ujawniony przez siebie adres e-mail automatycznie wygenerowaną wiadomość z potwierdzeniem złożenia zam&oacute;wienia. Z chwilą wysłania tej wiadomości dochodzi do zawarcia umowy, na mocy kt&oacute;rej użytkownik uzyskuje prawo do korzystania z treści cyfrowej, na warunkach niniejszego regulaminu.</li>
          <li>Brak ukończenia procesu płatności za pośrednictwem PayU i zapłaty ceny zwalnia Prowadzącą z obowiązku realizacji zam&oacute;wienia, o czym użytkownik nie musi zostać poinformowany odrębną wiadomością.</li>
          <li>W każdym przypadku, gdy Prowadząca nie jest w stanie zrealizować dokonanego zam&oacute;wienia, użytkownik bez zbędnej zwłoki zostanie o tym poinformowany wiadomością e-mail. W takim przypadku najp&oacute;źniej w ciągu 14 dni od daty zakupu Prowadząca zwr&oacute;ci całą kwotę zapłaconą przez użytkownika, na rachunek z kt&oacute;rego dokonano transakcji pierwotnej.</li>
          <li>Przyczyny, dla kt&oacute;rych Prowadząca nie jest w stanie zrealizować zam&oacute;wienia nie podlegają ujawnieniu użytkownikowi.</li>
          <li>Prowadząca umożliwia wystawienie faktury do każdego zam&oacute;wienia. W celu uzyskania faktury użytkownik powinien w toku dokonywania zam&oacute;wienia zaznaczyć odpowiednią rubrykę i wprowadzić dane umożliwiające wystawienie faktury. Taka faktura w postaci elektronicznej jest następnie &ndash; po pomyślnym dokonaniu zapłaty &ndash; wysyłana e-mailem na wskazany adres użytkownika.</li>
          <li>Prowadząca jest podmiotem korzystającym ze zwolnienia od VAT na podstawie art. 43 ust. 1 ustawy o podatku od towar&oacute;w i usług, wobec czego ceny płacone za treści cyfrowe przez użytkownika ujawnione zostaną na fakturze z VAT zwolnionym.</li>
        </ol>
        <h5>&sect; 6 Spos&oacute;b dostępu do zakupionej treści cyfrowej i warunki techniczne</h5>
        <ol>
          <li>Po dokonaniu zakupu online treści cyfrowej, użytkownik poprzez dostęp do swojego konta może przenieść się do strony internetowej oznaczonej zakładką &bdquo;Moje kursy&rdquo;.</li>
          <li>W sekcji &bdquo;Moje kursy&rdquo; użytkownikowi udostępnione zostaną zakupione treści cyfrowe. Spos&oacute;b korzystania z treści cyfrowej uzależniony jest od charakteru zakupionej treści cyfrowej i nie odbiega od standardowo przyjętego w sieci internet sposobu korzystania z plik&oacute;w i program&oacute;w komputerowych.</li>
          <li>Dostęp do treści cyfrowych oferowanych w postaci plik&oacute;w tekstowych (jak np. e-booki) wymaga zainstalowanego oprogramowania pozwalającego na odczyt plik&oacute;w w postaci *.pdf, takich jak Adobe Acrobat Reader.</li>
          <li>Dostęp do lekcji online na żywo (live) wymaga skorzystania z aplikacji umożliwiających transmisję głosu i obrazu.</li>
          <li>Treści cyfrowe zabezpieczone mogą być znakiem wodnym lub innym powszechnie stosowanym sposobem zabezpieczania treści w sieci internet.</li>
          <li>Szczeg&oacute;łowe warunki korzystania z danej treści cyfrowej, jak np. harmonogram webinari&oacute;w (transmisji) w przypadku kurs&oacute;w online na żywo (live), godziny transmisji, określane są na podstronie ofertowej każdej treści cyfrowej. Informacje dotyczące takich zajęć pojawią się na koncie użytkownika do 24 godzin od rozpoczęcia webinaru.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik w zakładce &bdquo;Moje kursy&rdquo; uzyska link zezwalający na dołączenie do transmisji zgodnie z zaplanowanym harmonogramem.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik po upływie terminu zaplanowanej transmisji uzyska dostęp do nagrania tego materiału w formie offline, z kt&oacute;rego może korzystać jak z każdej innej treści cyfrowej. Okres dostępu do takiej treści cyfrowej może być ograniczony czasowo, o czym użytkownik zostanie poinformowany w zakładce &bdquo;Moje kursy&rdquo; lub &bdquo;Powiadomienia&rdquo;.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca nie ponosi odpowiedzialności za przerwy w transmisji czy spadki w jej jakości spowodowane przepustowością sieci po stronie użytkownika.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, gdy Prowadząca z jakichkolwiek powod&oacute;w, w tym technicznych, zdrowotnych czy prywatnych, nie ma możliwości przeprowadzenia zaplanowanej transmisji, transmisja ta zostanie przeprowadzona w innym dniu, o kt&oacute;rym użytkownik zostanie poinformowany. Decyzja o braku możliwości przeprowadzenia zaplanowanej transmisji jest uznaniowa i użytkownik nie jest uprawniony do jej kwestionowania, ani kreowania z tego tytułu roszczeń wobec Prowadzącej, co pozostaje bez uszczerbku dla poniższych postanowień. Wedle swojego uznania Prowadząca może także w miejsce organizowania zastępczego terminu zaległej transmisji, o kt&oacute;rej mowa powyżej, przygotować materiał filmowy offline i udostępnić go w postaci nagrania w zakładce &bdquo;Moje kursy&rdquo; konta użytkownika. Przeprowadzenie transmisji zastępczej lub udostępnienie materiału offline każdorazowo nastąpi najp&oacute;źniej do 31 kwietnia danego roku, lub roku następnego jeśli zdarzenie ma miejsce po 31 kwietnia danego roku &ndash; za każdym razem tak, aby użytkownik posiadał komplet materiał&oacute;w przygotowujących do nauki jeszcze przed terminem matury.</li>
          <li>Z powodu nieprzeprowadzenia transmisji zgodnie z harmonogramem użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej, chyba że nie wywiąże się ona z przygotowania transmisji zastępczej lub nie udostępni materiału offline, w spos&oacute;b i w terminie o kt&oacute;rym mowa powyżej.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca jest nadto uprawniona do zmiany godziny transmisji w obrębie tego samego dnia. Z tego tytułu użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej.</li>
          <li>W przypadku gdyby kt&oacute;rakolwiek treść cyfrowa zawierała błąd obliczeniowy, tekstowy, skutkujący wadliwością merytoryczną treści cyfrowej, Prowadząca zobowiązuje się bez zbędnej zwłoki do zaktualizowania treści cyfrowej, poinformowania użytkownika o dostrzeżonym błędzie i udostępnienia treści cyfrowej o prawidłowej treści.</li>
          <li>Treści cyfrowe zakupione od 31 sierpnia 2021 r. w postaci materiał&oacute;w dostępnych w zakładce &bdquo;Moje kursy&rdquo; wygasają po upływie roku od daty zakupu. Oznacza to, że z upływem ww. terminu dostęp do tych treści wygasa.</li>
        </ol>
        <h5>&sect; 7 Dostarczanie treści cyfrowych przed upływem terminu do odstąpienia od umowy</h5>
        <ol>
          <li>Dokonując zakupu online, użytkownik wyraża zgodę na spełnienie świadczenia przed upływem terminu do odstąpienia od umowy.</li>
          <li>Z chwilą udzielenia zgody, o kt&oacute;rej mowa w ust. 1 powyżej, użytkownik traci prawo odstąpienia od umowy. Prowadząca bez zbędnej zwłoki prześle na adres e-mail ucznia podany do rejestracji w serwisie potwierdzenie zakupu online oraz utratę prawa odstąpienia od umowy.</li>
        </ol>
        <h5>&sect; 8 Prawo odstąpienia</h5>
        <ol>
          <li>Dokonując zakupu online, uczniowi przysługuje prawo odstąpienia od umowy w terminie 14 dni bez podania przyczyny. Termin na odstąpienie od umowy wygasa po upływie 14 dni od daty zawarcia umowy.</li>
          <li>Aby skorzystać z prawa do odstąpienia od umowy użytkownik powinien poinformować Prowadzącą w drodze jednoznacznego oświadczenia, przesłanego drogą elektroniczną lub tradycyjną.</li>
          <li>Oświadczenie, o kt&oacute;rym mowa powyżej, może być złożone w formie wypełnione wzoru formularza, stanowiącego załącznik nr 2 do ustawy o prawach konsumenta.</li>
          <li>Wysłanie informacji dotyczącej wykonania przysługującego prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy skutkuje zachowaniem terminu.</li>
          <li>Skuteczne odstąpienie od umowy skutkować będzie zwrotem wszystkich otrzymanych płatności, nie p&oacute;źniej niż w ciągu 14 dni od dnia otrzymania informacji o wykonaniu prawa odstąpienia. Zwrot płatności nastąpi przy użyciu takiego samego sposobu płatności jak transakcja pierwotna. Z tytułu zwrotu użytkownik nie ponosi dodatkowych koszt&oacute;w.</li>
          <li>Akceptując regulamin, użytkownik potwierdza, że został poinformowany, że zgodnie z art. 38 pkt 13 ustawy o prawach konsumenta, w przypadku umowy o dostarczanie treści cyfrowych, kt&oacute;re nie są zapisane na nośniku materialnym, prawo do odstąpienia nie przysługuje jeśli spełnianie świadczenia nastąpiło na wyraźną zgodę konsumenta przed<br />upływem terminu do odstąpienia umowy i po poinformowaniu o utracie prawa do odstąpienia od umowy.</li>
          <li>W ślad za powyższym, użytkownik, kt&oacute;ry dokonał zakupu online i zaakceptował rozpoczęcie świadczenia przed upływem terminu do odstąpienia od umowy, nie jest uprawniony do odstąpienia od zakupu e-booka lub kursu online. Po wykonaniu świadczenia usługi i uruchomieniu dostępu do kursu nie ma możliwości zamiany zakupionego kursu na inny spośr&oacute;d oferty.</li>
        </ol>
        <h5>&sect; 9 Dane osobowe i prywatność</h5>
        <p>Administratorem danych osobowych ucznia jest Prowadząca, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.&nbsp;Prowadząca przetwarza dane osobowe podane przez ucznia w procesie rejestracji konta w celu świadczenia usług drogą elektroniczną. Więcej informacji w tym zakresie znajduje się w Polityce Prywatności, dostępnej z poziomu serwisu.</p>
        <h5>&sect; 10 Reklamacje</h5>
        <ol>
          <li>Wszelkie reklamacje dotyczące korzystania z serwisu i oferowanych za jego pośrednictwem funkcjonalności należy przesyłać na adres Prowadzącej, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.</li>
          <li>Reklamacja powinna zawierać co najmniej:
            <ol type="a">
              <li>oznaczenie ucznia składającego reklamację, pozwalające na identyfikację;</li>
              <li>wskazanie funkcjonalności, z kt&oacute;rą wiąże się reklamacja;</li>
              <li>wskazanie i uzasadnienie zarzut&oacute;w będących podstawą reklamacji;</li>
              <li>wskazanie zakresu żądania ucznia składającego reklamację;</li>
            </ol>
          </li>
          <li>Jeśli reklamacja nie zawiera danych, o kt&oacute;rych mowa w ust. 2, Prowadząca, w terminie 7 dni od jej doręczenia, może wezwać Ucznia do uzupełnienia danych.</li>
          <li>Rozpatrzenie reklamacji nastąpi w ciągu 14 dni od dnia doręczenia reklamacji. W razie wezwania do uzupełnienia reklamacji, termin ten liczy się od dnia doręczenia reklamacji uzupełnionej.</li>
        </ol>
        <h5>&sect; 11 Postanowienia końcowe</h5>
        <ol>
          <li>Wszelkie nieuregulowane w niniejszym regulaminie kwestie rządzą się przepisami prawa polskiego, a zwłaszcza kodeksu cywilnego, oraz ustaw &ndash; o prawach konsumenta oraz o prawie autorskim i prawach pokrewnych.</li>
          <li>Regulamin w niniejszej wersji obowiązuje od 31 sierpnia 2021 r.</li>
          <li>Prowadząca zastrzega sobie prawo do zmiany regulaminu z ważnych przyczyn. W szczeg&oacute;lności zmiana może nastąpić w razie konieczności dostosowania regulaminu do wymog&oacute;w znowelizowanego prawa, technicznych względ&oacute;w funkcjonowania serwisu lub modyfikacji zakresu funkcjonowania serwisu.</li>
          <li>Zmiana regulaminu wchodzi w życie w stosunku do użytkownika w terminie wskazanym przez Prowadzącą w wiadomości skierowanej do użytkownika, przesłanej albo za pośrednictwem wiadomości e-mail albo dostępnej po zalogowaniu się do konta użytkownika. Zakupy online dokonane przed wejściem w życie zmiany regulaminu rządzą się zasadami określonymi w regulaminie w brzmieniu uprzednim.</li>
          <li>Prowadząca ma obowiązek dostarczania treści cyfrowych bez wad.</li>
          <li>Zapłata ceny za oferowane treści cyfrowe wyczerpuje zobowiązania użytkownika, dalsze korzystanie z zakupionej treści cyfrowej stanowi jego uprawnienie.</li>
          <li>W przypadku unieważnienia kt&oacute;regokolwiek postanowienia niniejszego regulaminu mocą orzeczenia sądu, pozostałe jego postanowienia pozostają w mocy i są obowiązujące.</li>
          <li>Treść regulaminu jest udostępniona w spos&oacute;b stały i nieodpłatnie w serwisie w taki spos&oacute;b, aby użytkownik w każdym czasie m&oacute;gł regulamin odtwarzać i zachowywać jego treść. Zabrania się kopiowania regulaminu i wykorzystywania go do cel&oacute;w prywatnych lub komercyjnych bez zgody Prowadzącej.</li>
        </ol>
      </b-card-text>
    </b-card>
    <b-card sub-title="wersja obowiązująca od 30 sierpnia 2021 r.">
      <b-card-text>
        <p><strong>Regulamin serwisu chemmaster.pl</strong></p>
        <h5>&sect; 1 Definicje</h5>
        <ol>
          <li>Ilekroć w niniejszym regulaminie używa się poniższych zwrot&oacute;w, oznaczają one:</li>
        </ol>
        <ol>
          <li>Prowadząca &ndash; Sonia Radosz, prowadząca działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl</li>
          <li>Użytkownik &ndash; osoba korzystająca z serwisu będąca osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych</li>
          <li>serwis &ndash; witryna www.chemmaster.pl</li>
          <li>e-book &ndash; przygotowane w formie pliku komputerowego opracowania obejmujące teksty oraz grafiki</li>
          <li>kurs online &ndash; prowadzony przez Prowadzącą za pośrednictwem środk&oacute;w komunikacji elektronicznej umożliwiających transmisję obrazu i głosu cykl prezentacji, podczas kt&oacute;rych przekazuje ona wiedzę i umiejętności w zakresie oferowanego produktu</li>
          <li>treści cyfrowe &ndash; oferowane przez Prowadzącą do sprzedaży za pośrednictwem serwisu usługi i utwory, nie będące utrwalone na nośniku materialnym, do kt&oacute;rych użytkownik ma dostęp za pomocą konta użytkownika.</li>
          <li>zakup online &ndash; procedura zamawiania treści cyfrowych oferowanych przez Prowadzącą za pośrednictwem serwisu</li>
          <li>konto, konto użytkownika &ndash; indywidualne konto użytkownika umożliwiające dostęp do zakupionych materiał&oacute;w.</li>
        </ol>
        <h5>&sect; 2 Postanowienia og&oacute;lne</h5>
        <ol>
          <li>Postanowienia niniejszego regulaminu normują zasady oraz warunki korzystania z serwisu oraz funkcjonalności na nim oferowanych, w tym zakupu online.</li>
          <li>Korzystając z serwisu, a w szczeg&oacute;lności zakładając konto, użytkownik zgadza się przestrzegać niniejszego regulaminu i jego postanowień.</li>
          <li>Użytkownik zobowiązuje się do korzystania z konta i zakupionych treści online wyłącznie osobiście, zabronione jest wsp&oacute;łdzielenie konta i zakupionych treści cyfrowych z osobą trzecią.</li>
          <li>Użytkownik jest jednakże uprawniony jest do przekazania dostępu do konta oraz zakupionej online treści cyfrowej jednemu zstępnemu. Użytkownik zobowiązuje się, pod rygorem odpowiedzialności za działania zstępnego jak za swoje, do zapoznania zstępnego z niniejszym regulaminem oraz nadzorem na zstępnym w zakresie zgodności korzystania z zakupionych online treści cyfrowych z postanowieniami niniejszego regulaminu.</li>
        </ol>
        <h5>&sect; 3 Ochrona własności intelektualnej</h5>
        <ol>
          <li>Wszystkie treści cyfrowe oferowane przez Prowadzącą stanowią jej własność i są przedmiotem ochrony prawa polskiego i międzynarodowego, w szczeg&oacute;lności przepis&oacute;w ustawy z 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</li>
          <li>Z momentem zakupu online treści cyfrowej, na użytkownika nie przechodzą żadne prawa autorskie, tak osobiste jak majątkowe.</li>
          <li>Bez zgody Prowadzącej zabronione jest rozpowszechniane treści cyfrowych uzyskanych za pomocą zakupu online.</li>
          <li>Użytkownik nie jest uprawniony w szczeg&oacute;lności do
            <ol>
              <li>ingerencji w treść treści cyfrowej</li>
              <li>usuwania zabezpieczeń treści cyfrowej, jak np. znaki wodne</li>
              <li>zwielokrotniania treści cyfrowej w formie elektronicznej lub tradycyjnej</li>
              <li>udostępniania treści cyfrowej w internecie, w tym na platformach P2P jak sieci torrent lub inne</li>
              <li>wykorzystywania treści cyfrowej do cel&oacute;w komercyjnych, modyfikacji, zar&oacute;wno w całości jak i w części.</li>
            </ol>
          </li>
          <li>Rozpowszechnianie utworu bez uprawnienia stanowi przestępstwo opisane w art. 116 ustawy o prawie autorskim i prawach pokrewnych i każdy wykryty przypadek takiego działania zostanie zgłoszony do właściwego organu. Dotyczy to także innych przestępstw uregulowanych w powołanej ustawie, jak r&oacute;wnież innych przepisach, jeśli bezprawne rozpowszechnianie treści cyfrowych wyczerpywać będzie znamiona tych przestępstw.</li>
          <li>Użytkownik ma prawo korzystać z treści cyfrowej tylko na własny użytek. Postanowienie to działa bez uszczerbku na uprawnienie opisane w &sect; 2 ust. 4 regulaminu.</li>
          <li>Użytkownik ma prawo do wydruku treści cyfrowej nadającej się do tego, jak np. e-book. Wydrukowana treść cyfrowa jednakże pozostaje pod ochroną prawa własności intelektualnej, w szczeg&oacute;lności w zakresie zakazu kopiowania i rozpowszechniania, przekazywania osobom trzecim do korzystania.</li>
          <li>Użytkownik ma prawo korzystać, w zgodzie z postanowieniami powyższymi, z zakupionych online treści cyfrowych za pośrednictwem r&oacute;żnych urządzeń elektronicznych, o ile nie prowadzi to do zmiany w strukturze treści cyfrowej.</li>
          <li>W przypadku gdy oferowane treści cyfrowe stanowią opracowania tekst&oacute;w zewnętrznych, og&oacute;lnodostępnych (jak np. zbiory zadań Centralnych lub Obwodowych Komisji Egzaminacyjnych), przedmiotem praw autorskich Prowadzącej są dokonane przez nią analizy powyższych tekst&oacute;w w każdej postaci, a w szczeg&oacute;lności komentarzy, grafik, opracowań.</li>
        </ol>
        <h5>&sect; 4 Zakładanie konta</h5>
        <ol>
          <li>Użytkownik chcący korzystać z funkcjonalności serwisu powinien zarejestrować się w serwisie poprzez utworzenie konta.</li>
          <li>Użytkownik zakładający konto powinien być osobą fizyczną posiadającą pełną zdolność do czynności prawnych lub osobą fizyczną z ograniczoną zdolnością do czynności prawnych pod warunkiem posiadania zgody swojego przedstawiciela ustawowego na dokonanie zakupu online i korzystania z treści cyfrowych.</li>
        </ol>
        <h5>&sect; 5 Procedura zakupu online treści cyfrowej</h5>
        <ol>
          <li>Prowadząca umożliwia w ramach serwisu zakup online treści cyfrowych, w szczeg&oacute;lności w postaci uczestnictwa w kursie online lub dostarczenia e-booka.</li>
          <li>Informacje o właściwościach oferowanej treści cyfrowej dostępne są każdorazowo z poziomu serwisu.</li>
          <li>Zakup online możliwy jest wyłącznie dla użytkownika, kt&oacute;ry zarejestrował w serwisie swoje konto i kt&oacute;ry podczas procedury zakupu pozostaje zalogowany.</li>
          <li>Treści cyfrowe oferowane w serwisie mogą ulegać zmianie, modyfikacji, ich dostępność może być ograniczona czasowo lub ilościowo, wedle uznania Prowadzącej.</li>
          <li>Płatności za treści cyfrowe dokonywane są za pomocą platformy PayU. Regulaminy funkcjonowania tej platformy dostępne są dla użytkownika pod adresem https://poland.payu.com/dokumenty-prawne-do-pobrania/. Dokonując zakupu online użytkownik potwierdza także zapoznanie się z tymi regulaminami.</li>
          <li>Cena za oferowane treści cyfrowe wyrażona jest w złotych (PLN).</li>
          <li>Prowadząca uprawniona jest do oferowania kupon&oacute;w rabatowych, kt&oacute;re pomniejszają ostateczną cenę zakupu treści cyfrowej.</li>
          <li>Spos&oacute;b, terminy i warunki korzystania z kupon&oacute;w rabatowych określane są każdorazowo przez Prowadzącą w ramach informacji o tych kuponach umieszczonych w serwisie.</li>
          <li>Po wyborze żądanej treści cyfrowej użytkownik powinien postępować zgodnie ze wskaz&oacute;wkami widocznymi na stronie internetowej, kt&oacute;ra przeprowadzi go przez proces zakupu.</li>
          <li>Ostateczna cena zakupu, po uwzględnieniu ewentualnych kupon&oacute;w rabatowych, stanowi cenę więżącą, kt&oacute;rą po zaakceptowaniu warunk&oacute;w sprzedaży i przystąpienia do płatności za pośrednictwem platformy PayU użytkownik zobowiązuje się zapłacić.</li>
          <li>Po złożeniu dyspozycji zakupu i ukończeniu procesu płatności za pośrednictwem platformy PayU (tj. potwierdzenia przez PayU udanego przelewu ceny) użytkownik otrzyma na ujawniony przez siebie adres e-mail automatycznie wygenerowaną wiadomość z potwierdzeniem złożenia zam&oacute;wienia. Z chwilą wysłania tej wiadomości dochodzi do zawarcia umowy, na mocy kt&oacute;rej użytkownik uzyskuje prawo do korzystania z treści cyfrowej, na warunkach niniejszego regulaminu.</li>
          <li>Brak ukończenia procesu płatności za pośrednictwem PayU i zapłaty ceny zwalnia Prowadzącą z obowiązku realizacji zam&oacute;wienia, o czym użytkownik nie musi zostać poinformowany odrębną wiadomością.</li>
          <li>W każdym przypadku, gdy Prowadząca nie jest w stanie zrealizować dokonanego zam&oacute;wienia, użytkownik bez zbędnej zwłoki zostanie o tym poinformowany wiadomością e-mail. W takim przypadku najp&oacute;źniej w ciągu 14 dni od daty zakupu Prowadząca zwr&oacute;ci całą kwotę zapłaconą przez użytkownika, na rachunek z kt&oacute;rego dokonano transakcji pierwotnej.</li>
          <li>Przyczyny, dla kt&oacute;rych Prowadząca nie jest w stanie zrealizować zam&oacute;wienia nie podlegają ujawnieniu użytkownikowi.</li>
          <li>Prowadząca umożliwia wystawienie faktury do każdego zam&oacute;wienia. W celu uzyskania faktury użytkownik powinien w toku dokonywania zam&oacute;wienia zaznaczyć odpowiednią rubrykę i wprowadzić dane umożliwiające wystawienie faktury. Taka faktura w postaci elektronicznej jest następnie &ndash; po pomyślnym dokonaniu zapłaty &ndash; wysyłana e-mailem na wskazany adres użytkownika.</li>
          <li>Prowadząca jest podmiotem korzystającym ze zwolnienia od VAT na podstawie art. 43 ust. 1 ustawy o podatku od towar&oacute;w i usług, wobec czego ceny płacone za treści cyfrowe przez użytkownika ujawnione zostaną na fakturze z VAT zwolnionym.</li>
        </ol>
        <h5>&sect; 6 Spos&oacute;b dostępu do zakupionej treści cyfrowej i warunki techniczne</h5>
        <ol>
          <li>Po dokonaniu zakupu online treści cyfrowej, użytkownik poprzez dostęp do swojego konta może przenieść się do strony internetowej oznaczonej zakładką &bdquo;Twoje materiały&rdquo;.</li>
          <li>W sekcji &bdquo;Twoje materiały&rdquo; użytkownikowi udostępnione zostaną zakupione treści cyfrowe. Spos&oacute;b korzystania z treści cyfrowej uzależniony jest od charakteru zakupionej treści cyfrowej i nie odbiega od standardowo przyjętego w sieci internet sposobu korzystania z plik&oacute;w i program&oacute;w komputerowych.</li>
          <li>Dostęp do treści cyfrowych oferowanych w postaci plik&oacute;w tekstowych (jak np. e-booki) wymaga zainstalowanego oprogramowania pozwalającego na odczyt plik&oacute;w w postaci *.pdf, takich jak Adobe Acrobat Reader.</li>
          <li>Dostęp do kurs&oacute;w online wymaga skorzystania z aplikacji umożliwiających transmisję głosu i obrazu, takich jak Google Meet lub Zoom.</li>
          <li>Treści cyfrowe zabezpieczone mogą być znakiem wodnym lub innym powszechnie stosowanym sposobem zabezpieczania treści w sieci internet.</li>
          <li>Szczeg&oacute;łowe warunki korzystania z danej treści cyfrowej, jak np. harmonogram webinari&oacute;w (transmisji) w przypadku kurs&oacute;w online, godziny transmisji, określane są na podstronie ofertowej każdej treści cyfrowej.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik w zakładce &bdquo;Twoje materiały&rdquo; uzyska link zezwalający na dołączenie do transmisji zgodnie z zaplanowanym harmonogramem.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, użytkownik po upływie terminu zaplanowanej transmisji uzyska dostęp do nagrania tego materiału w formie offline, z kt&oacute;rego może korzystać jak z każdej innej treści cyfrowej. Okres dostępu do takiej treści cyfrowej może być ograniczony czasowo, o czym użytkownik zostanie poinformowany w zakładce &bdquo;Twoje materiały&rdquo;.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca nie ponosi odpowiedzialności za przerwy w transmisji czy spadki w jej jakości spowodowane przepustowością sieci po stronie użytkownika.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, gdy Prowadząca z jakichkolwiek powod&oacute;w, w tym technicznych, zdrowotnych czy prywatnych, nie ma możliwości przeprowadzenia zaplanowanej transmisji, transmisja ta zostanie przeprowadzona w innym dniu, o kt&oacute;rym użytkownik zostanie poinformowany. Decyzja o braku możliwości przeprowadzenia zaplanowanej transmisji jest uznaniowa i użytkownik nie jest uprawniony do jej kwestionowania, ani kreowania z tego tytułu roszczeń wobec Prowadzącej, co pozostaje bez uszczerbku dla poniższych postanowień. Wedle swojego uznania Prowadząca może także w miejsce organizowania zastępczego terminu zaległej transmisji, o kt&oacute;rej mowa powyżej, przygotować materiał filmowy offline i udostępnić go w postaci nagrania w zakładce &bdquo;Twoje materiały&rdquo; konta użytkownika. Przeprowadzenie transmisji zastępczej lub udostępni materiału offline każdorazowo nastąpi najp&oacute;źniej do 31 kwietnia danego roku, lub roku następnego jeśli zdarzenie ma miejsce po 31 kwietnia danego roku &ndash; za każdym razem tak, aby<br />użytkownik posiadał komplet materiał&oacute;w przygotowujących do nauki jeszcze przed terminem matury.</li>
          <li>Z powodu nieprzeprowadzenia transmisji zgodnie z harmonogramem użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej, chyba że nie wywiąże się ona z przygotowania transmisji zastępczej lub nie udostępni materiału offline, w spos&oacute;b i w terminie o kt&oacute;rym mowa powyżej.</li>
          <li>W przypadku kurs&oacute;w online organizowanych na żywo, Prowadząca jest nadto uprawniona do zmiany godziny transmisji w obrębie tego samego dnia. Z tego tytułu użytkownikowi nie przysługują żadne roszczenia wobec Prowadzącej.</li>
          <li>W przypadku gdyby kt&oacute;rakolwiek treść cyfrowa zawierała błąd obliczeniowy, tekstowy, skutkujący wadliwością merytoryczną treści cyfrowej, Prowadząca zobowiązuje się bez zbędnej zwłoki do zaktualizowania treści cyfrowej, poinformowania użytkownika o dostrzeżonym błędzie i udostępnienia treści cyfrowej o prawidłowej treści.</li>
        </ol>
        <h5>&sect; 7 Dostarczanie treści cyfrowych przed upływem terminu do odstąpienia od umowy</h5>
        <ol>
          <li>Dokonując zakupu online, użytkownik wyraża zgodę na spełnienie świadczenia przed upływem terminu do odstąpienia od umowy.</li>
          <li>Z chwilą udzielenia zgody, o kt&oacute;rej mowa w ust. 1 powyżej, użytkownik traci prawo odstąpienia od umowy. Prowadząca bez zbędnej zwłoki prześle na adres e-mail ucznia podany do rejestracji w serwisie potwierdzenie zakupu online oraz utratę prawa odstąpienia od umowy.</li>
        </ol>
        <h5>&sect; 8 Prawo odstąpienia</h5>
        <ol>
          <li>Dokonując zakupu online, uczniowi przysługuje prawo odstąpienia od umowy w terminie 14 dni bez podania przyczyny. Termin na odstąpienie od umowy wygasa po upływie 14 dni od daty zawarcia umowy.</li>
          <li>Aby skorzystać z prawa do odstąpienia od umowy użytkownik powinien poinformować Prowadzącą w drodze jednoznacznego oświadczenia, przesłanego drogą elektroniczną lub tradycyjną.</li>
          <li>Oświadczenie, o kt&oacute;rym mowa powyżej, może być złożone w formie wypełnione wzoru formularza, stanowiącego załącznik nr 2 do ustawy o prawach konsumenta.</li>
          <li>Wysłanie informacji dotyczącej wykonania przysługującego prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy skutkuje zachowaniem terminu.</li>
          <li>Skuteczne odstąpienie od umowy skutkować będzie zwrotem wszystkich otrzymanych płatności, nie p&oacute;źniej niż w ciągu 14 dni od dnia otrzymania informacji o wykonaniu prawa odstąpienia. Zwrot płatności nastąpi przy użyciu takiego samego sposobu płatności jak transakcja pierwotna. Z tytułu zwrotu użytkownik nie ponosi dodatkowych koszt&oacute;w.</li>
          <li>Akceptując regulamin, użytkownik potwierdza, że został poinformowany, że zgodnie z art. 38 pkt 13) ustawy o prawach konsumenta, w przypadku umowy o dostarczanie treści cyfrowych, kt&oacute;re nie są zapisane na nośniku materialnym, prawo do odstąpienia nie przysługuje jeśli spełnianie świadczenia nastąpiło na wyraźną zgodę konsumenta przed<br />upływem terminu do odstąpienia umowy i po poinformowaniu o utracie prawa do odstąpienia od umowy.</li>
          <li>W ślad za powyższym, użytkownik, kt&oacute;ry dokonał zakupu online i zaakceptował rozpoczęcie świadczenia przed upływem terminu do odstąpienia od umowy, nie jest uprawniony do odstąpienia od zakupu e-booka lub kursu online. Po wykonaniu świadczenia usługi i uruchomieniu dostępu do kursu nie ma możliwości zamiany zakupionego kursu na inny spośr&oacute;d oferty.</li>
        </ol>
        <h5>&sect; 9 Dane osobowe i prywatność</h5>
        <p>Administratorem danych osobowych ucznia jest Prowadząca, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.&nbsp;Prowadząca przetwarza dane osobowe podane przez ucznia w procesie rejestracji konta w celu świadczenia usług drogą elektroniczną. Więcej informacji w tym zakresie znajduje się w Polityce Prywatności, dostępnej z poziomu serwisu.</p>
        <h5>&sect; 10 Reklamacje</h5>
        <ol>
          <li>Wszelkie reklamacje dotyczące korzystania z serwisu i oferowanych za jego pośrednictwem funkcjonalności należy przesyłać na adres Prowadzącej, tj. Sonia Radosz, prowadzącą działalność gospodarczą pod firmą Korepetycje z chemii Sonia Radosz, NIP 6381739715, ul. Wodzisławska 4, 44-240 Żory, e-mail:&nbsp;kontakt@chemmaster.pl.</li>
          <li>Reklamacja powinna zawierać co najmniej:
            <ol>
              <li>oznaczenie ucznia składającego reklamację, pozwalające na identyfikację;</li>
              <li>wskazanie funkcjonalności, z kt&oacute;rą wiąże się reklamacja;</li>
              <li>wskazanie i uzasadnienie zarzut&oacute;w będących podstawą reklamacji;</li>
              <li>wskazanie zakresu żądania ucznia składającego reklamację;</li>
            </ol>
          </li>
          <li>Jeśli reklamacja nie zawiera danych, o kt&oacute;rych mowa w ust. 2, Prowadząca, w terminie 7 dni od jej doręczenia, może wezwać Ucznia do uzupełnienia danych.</li>
          <li>Rozpatrzenie reklamacji nastąpi w ciągu 14 dni od dnia doręczenia reklamacji. W razie wezwania do uzupełnienia reklamacji, termin ten liczy się od dnia doręczenia reklamacji uzupełnionej.</li>
        </ol>
        <h5>&sect; 11 Postanowienia końcowe</h5>
        <ol>
          <li>Wszelkie nieuregulowane w niniejszym regulaminie kwestie rządzą się przepisami prawa polskiego, a zwłaszcza kodeksu cywilnego, oraz ustaw &ndash; o prawach konsumenta oraz o prawie autorskim i prawach pokrewnych.</li>
          <li>Regulamin w niniejszej wersji obowiązuje od 23 lipca 2020 r.</li>
          <li>Prowadząca zastrzega sobie prawo do zmiany regulaminu z ważnych przyczyn. W szczeg&oacute;lności zmiana może nastąpić w razie konieczności dostosowania regulaminu do wymog&oacute;w znowelizowanego prawa, technicznych względ&oacute;w funkcjonowania serwisu lub modyfikacji zakresu funkcjonowania serwisu.</li>
          <li>Zmiana regulaminu wchodzi w życie w stosunku do użytkownika w terminie wskazanym przez Prowadzącą w wiadomości skierowanej do użytkownika, przesłanej albo za pośrednictwem wiadomości e-mail albo dostępnej po zalogowaniu się do konta użytkownika. Zakupy online dokonane przed wejściem w życie zmiany regulaminu rządzą się zasadami określonymi w regulaminie w brzmieniu uprzednim.</li>
          <li>Prowadząca ma obowiązek dostarczania treści cyfrowych bez wad.</li>
          <li>Zapłata ceny za oferowane treści cyfrowe wyczerpuje zobowiązania użytkownika, dalsze korzystanie z zakupionej treści cyfrowej stanowi jego uprawnienie.</li>
          <li>W przypadku unieważnienia kt&oacute;regokolwiek postanowienia niniejszego regulaminu mocą orzeczenia sądu, pozostałe jego postanowienia pozostają w mocy i są obowiązujące.</li>
          <li>Treść regulaminu jest udostępniona w spos&oacute;b stały i nieodpłatnie w serwisie w taki spos&oacute;b, aby użytkownik w każdym czasie m&oacute;gł regulamin odtwarzać i zachowywać jego treść. Zabrania się kopiowania regulaminu i wykorzystywania go do cel&oacute;w prywatnych lub komercyjnych bez zgody Prowadzącej.</li>
        </ol>
        <h3>Regulamin uczestnictwa w kursie wakacyjnym z chemii z ChemMaster Maturalny</h3>
        <ol>
          <li>Prowadzący (Sonia Radosz) deklaruje przeprowadzenie 8 webinari&oacute;w w ramach kursu wakacyjnego z chemii.</li>
          <li>Prowadzący zastrzega sobie prawo do możliwości dokonania bieżących zmian w planie kursu.</li>
          <li>W przypadku zaistnienia braku możliwości prowadzenia zajęć na żywo przez prowadzącego, spowodowanego chorobą, wyjazdem (w tym także podr&oacute;ż prywatna) lub innymi zdarzeniami, prowadzący zobowiązuje się do: a) wyznaczenia nowego terminu webinarium, kt&oacute;re odbędzie się najp&oacute;źniej do 30.08.2020r lub b) nagrania materiału i udostępnienia go kursantom.</li>
          <li>Koszt uczestnictwa w kursie: 340zł.</li>
          <li>Webinarium trwa około 2h.</li>
          <li>Opłacenie webinari&oacute;w upoważnia do korzystania z usługi przez jedną osobę, z jednego konta. Zabronione jest pobieranie, kopiowanie i rozprzestrzenianie wszelkich materiał&oacute;w i nagrań.</li>
          <li>W przypadku gdy uczeń nie może uczestniczyć w zajęciach &bdquo;na żywo&rdquo;, prowadzący daje możliwość odtworzenia nagrania.</li>
          <li>Dostęp do nagrań kursanci otrzymują do 31.05.2021.</li>
          <li>Prowadzący nie odpowiada za przerwy w nadawaniu webinari&oacute;w spowodowane m.in. problemami z siecią internetową zainstalowaną u ucznia. W przypadku problem&oacute;w związanych z siecią internetową u prowadzącego lub problem&oacute;w technicznych po stronie platformy (brak możliwości przeprowadzenia zajęć zgodnie z grafikiem), prowadzący przeprowadzi zajęcia w innym dniu &ndash; najp&oacute;źniej do 30.08.2020r &ndash; lub nagra materiał w postaci filmu i udostępni go uczniom.</li>
          <li>Opłacanie pakietu kursu jest kwotą bezzwrotną (także w przypadku chęci zakończenia przez ucznia udziału w kursie).</li>
          <li>Prowadzący wystawia fakturę na życzenie.</li>
          <li>Harmonogram kursu wysłany jest jako załącznik razem z Regulaminem.</li>
        </ol>
        <p>Prowadzący:</p>
        <p>Korepetycje z chemii Sonia Radosz<br />Wodzisławska 4<br />44-240 Żory<br />NIP: 6381739715</p>
        <p>Nr konta bankowego: 42 1050 1676 1000 0091 3967 1839</p>
      </b-card-text>
    </b-card>
  </div>

</template>

<script>
import {BCard, BCardText} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {}
  },
}
</script>

<style type="text/css">
p.p1 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  text-align: center;
  line-height: 37.5px;
  font: 27.0px Helvetica;
  color: #000000;
  background-color: #ffffff
}

p.p2 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  text-align: center;
  font: 12.0px Helvetica;
  color: #000000;
  background-color: #ffffff
}

p.p3 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  line-height: 28.5px;
  font: 18.0px Helvetica;
  color: #000000;
  background-color: #ffffff;
  min-height: 22.0px
}

p.p4 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  line-height: 28.5px;
  font: 18.0px Helvetica;
  color: #000000;
  background-color: #ffffff
}

p.p6 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  font: 12.0px Helvetica;
  color: #000000;
  background-color: #ffffff
}

li.li5 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  font: 12.0px Helvetica;
  color: #000000
}

span.s1 {
  background-color: #ffffff
}

ol.ol1 {
  list-style-type: decimal
}

ol.ol2 {
  list-style-type: lower-alpha
}
</style>
